import React from 'react'
import {
  Avatar,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material'
import styles from './index.module.css'
import { i18n } from '~/translations/i18n'

export interface Representation {
  id: number
  description: string
  advertiserId: number | null
  targetCode: string
  brands: string[]
  sellersIds: string[]
  publicId: string
}

type Props = {
  onSelect: (r: Representation) => void
  onClose: () => void
  userRepresentations: Representation[]
}

const UserSelection: React.FunctionComponent<Props> = ({
  onSelect,
  onClose,
  userRepresentations,
}: Props) => {
  const handleSelection = (r: Representation) => {
    onSelect(r)
  }

  const handleClose = () => {
    onClose()
  }

  return (
    <Dialog
      fullWidth
      className={styles.container}
      aria-labelledby="simple-dialog-title"
      open
      onClose={handleClose}
    >
      <DialogTitle id="simple-dialog-title" className={styles.title}>
        {i18n.t('storeSelection.popup.title')}
      </DialogTitle>
      <List>
        {userRepresentations &&
          userRepresentations.map((r) => (
            <ListItem
              disableGutters
              className={styles.listItem}
              button
              onClick={() => handleSelection(r)}
              key={r.id}
            >
              <ListItemAvatar>
                <Avatar className={styles.avatar}> </Avatar>
              </ListItemAvatar>
              <ListItemText primary={`${r.description} (${r.targetCode})`} />
            </ListItem>
          ))}
      </List>
    </Dialog>
  )
}

export default UserSelection
