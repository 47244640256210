import { styled, Drawer as MuiDrawer, Box, IconButton } from '@mui/material'

const drawerWidth = '200px'

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    border: 'none',
    background: 'white',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: '56px',
      [theme.breakpoints.up('sm')]: {
        width: '56px',
      },
    }),
  },
}))

interface ToggleContainerProps {
  open: boolean
}

export const ToggleContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'open',
})<ToggleContainerProps>(({ open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: open ? 'space-between' : 'center',
  padding: '16px',
  gap: '10px',
}))

export const LogoContainer = styled(Box)(() => ({
  maxWidth: '120px',
  width: '100%',
  height: '100%',
}))

export const ToggleIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.dark,
  maxWidth: '16px',
  maxHeight: '16px',
  width: '100%',
  height: '100%',
}))
