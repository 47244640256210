// TODO add generics

export const getObjectKeyValue =
  (key: string) =>
  (obj: Record<string, any>): any =>
    obj[key]

export const removeUndefinedPropertiesFromObject = (
  obj: Record<string, any>
): Record<string, any> => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'object') {
      const clearSubObject = removeUndefinedPropertiesFromObject(obj[key])

      if (Object.keys(clearSubObject).length === 0) {
        delete obj[key]
      }
    }

    if (getObjectKeyValue(key)(obj) === undefined) {
      delete obj[key]
    }
  })

  return obj
}
