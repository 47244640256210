import { Fragment, MouseEvent, useMemo, useEffect, useState } from 'react'
import { Menu, MenuItem, Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import DesktopSidebar from './DesktopSideBar'
import { MobileSideBar } from './MobileSidebar'
import useSections from './useSections'
import useSidebar from './useSidebar'
import { Representation } from '~/components/UserSelection'
import {
  clearPaginations,
  setDefaultRouteOnAccountChange,
} from '~/store/app/slice'
import { setSelectedPlacements } from '~/store/display-campaigns/slice'
import { i18n } from '~/translations/i18n'

interface SidebarProps {
  onChangeUser: () => void
  widthChanged: (value: boolean) => void
  hasMoreThanOneRepresentation?: boolean
  representation?: Representation
}

const Sidebar = ({
  onChangeUser,
  widthChanged,
  hasMoreThanOneRepresentation,
  representation,
}: SidebarProps) => {
  const {
    isSmallScreen,
    isMediumDown,
    isMediumUp,
    user,
    setOpen,
    menuAnchorEl,
    setMenuAnchorEl,
    open,
  } = useSidebar()
  const { sections } = useSections()

  const accountTitle = useMemo(
    () => (
      <>
        <Box pr={0.5}>{user.name}</Box>
        {representation && (
          <Box>
            ({representation?.description} - {representation?.targetCode})
          </Box>
        )}
      </>
    ),
    [user, representation]
  )

  useEffect(() => {
    if (isMediumDown) {
      setOpen(false)
      widthChanged(false)
    }
    if (isMediumUp) {
      setOpen(true)
      widthChanged(true)
    }
  }, [isMediumDown, isMediumUp])

  const dispatch = useDispatch()
  const isMenuOpen = Boolean(menuAnchorEl)
  const [openSubMenu, setOpenSubMenu] = useState(-1)

  const resetState = (link: string) => {
    dispatch(setDefaultRouteOnAccountChange(link))
    dispatch(clearPaginations())
    dispatch(setSelectedPlacements([]))
  }

  const links = sections.map((section: any) => section.links)
  const flattenLinks = links.flat()

  const findIndex = flattenLinks.findIndex(
    (link: any) =>
      link.href === '' &&
      link.subLinks
        ?.map((subLink: any) => subLink.href)
        .includes(window.location.pathname)
  )

  useEffect(() => {
    setOpenSubMenu(findIndex)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [findIndex, window.location.pathname])

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setMenuAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setMenuAnchorEl(null)
  }

  const handleChangeUser = () => {
    onChangeUser()
    setMenuAnchorEl(null)
  }

  const toggleDrawer = () => {
    setOpen(!open)
    widthChanged(!open)
    setOpenSubMenu(-1)
  }

  const handleClick = (index: number) => {
    setOpenSubMenu(index === openSubMenu ? -1 : index)
  }

  const getMobileSidebar = () => (
    <MobileSideBar
      sections={sections}
      title={accountTitle}
      representation={representation}
      picture={String(user.picture)}
      handleMenuOpen={handleMenuOpen}
      openSubMenu={openSubMenu}
      handleClick={handleClick}
      open={open}
      toggleDrawer={toggleDrawer}
      resetState={resetState}
    />
  )

  const getDesktopSidebar = () => (
    <DesktopSidebar
      open={open}
      toggleDrawer={toggleDrawer}
      accountTitle={accountTitle}
      representation={representation}
      handleMenuOpen={handleMenuOpen}
      sections={sections}
      user={user}
      resetState={resetState}
      openSubMenu={openSubMenu}
      handleClick={handleClick}
    />
  )

  return (
    <>
      {isSmallScreen ? getMobileSidebar() : getDesktopSidebar()}
      {hasMoreThanOneRepresentation && (
        <Menu
          anchorEl={menuAnchorEl}
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={handleChangeUser}>
            {i18n.t('storeSelection.changeAccount')}
          </MenuItem>
        </Menu>
      )}
    </>
  )
}

export default Sidebar
