import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SponsoredBrandsState } from './types'

const INITIAL_STATE: SponsoredBrandsState = {
  selectedData: [],
}

const slice = createSlice({
  name: 'sponsoredBrands',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedData(state: SponsoredBrandsState, action: PayloadAction<any[]>) {
      state.selectedData = action.payload
    },
  },
})

export const { setSelectedData } = slice.actions

export default slice.reducer
