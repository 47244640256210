export const sponsoredProduct = {
  welcome: {
    banner: {
      title:
        'Get <strong>Visibility and Sales</strong> for your Products on Jumia with <strong>Sponsored Products</strong>',
      description:
        'Sponsored Products help you promote your products to most suitable shoppers, at the most attractive locations (Search Page, Category Page and Product Page) on Jumia.',
      callToAction: 'Activate your campaign Now!',
      couponTitle:
        'You also have a {{couponValue}} welcome coupon to create your first campaign! 🎉 <br><i>(The welcome coupon is only eligible for sellers who have <u>never</u> tried the Sponsored Products program.)</i></br>',
    },
    features: {
      title: 'Advantages of Using Sponsored Products',
      list: [
        {
          title: 'Increase your Visibility',
          description:
            'Get an opportunity to showcase your products on the top of the search and the category page along with dedicated widget on Product Page',
          icon: 'IncreaseVisibility',
        },
        {
          title: 'Showcase your products',
          description:
            'With the option to promote multiple products, you can boost visibility for new products or stock on special in your catalog',
          icon: 'ShowcaseProduct',
        },
        {
          title: 'Boost Sales',
          description:
            'With Sponsored Products, you are able to boost your sales and track your efficiency by measuring your Return on Advertising Spend (ROAS)',
          icon: 'BoostSales',
        },
        {
          title: 'Self Service campaigns',
          description:
            'Have complete control over your campaign with options to select your own bids and budgets',
          icon: 'SelfService',
        },
      ],
    },
  },
  formFields: {
    campaignName: {
      label: 'Campaign Name',
      info: 'Add a name for your campaign',
    },
    campaignDuration: {
      label: 'Campaign Duration',
      info: `Select the duration for your campaign’s delivery by adding a start date and an end date.<span style="margin-top:5px; display:block;">If you don't choose an end date, your ads will run until you stop it.</span>`,
    },
    campaignBudget: {
      label: 'Campaign Budget',
      info: `Select the budget for your campaign.<span style="margin-top:5px; display:block;"><strong>Total Budget:</strong> This budget is set for the whole campaign duration.</span><span style="margin-top:5px; display:block;"><strong>Daily Budget:</strong> This budget is set as a daily limit for the campaign.</span> <span style="margin-top:5px; display:block;">Once your budget is reached, the ads will stop showing.</span>`,
    },
    biddingStrategy: {
      label: 'Bidding Strategy',
      info: `Select the Bidding strategy for your campaign.<span style="margin-top:5px; display:block;">Automatic Bid with Desired ACOS (Recommended) : Automatic bidding enables you to choose the ACoS that you want to target in the campaign and the bidding will be done automatically based on the competition and your desired ACoS.</span><span style="margin-top:5px; display:block;">Manual Bidding (Products): When choosing manual bidding on products you choose the max bid (how much you are willing to pay when someone views your ads) and you can select different bids for each product in the campaign.</span><span style="margin-top:5px; display:block;">Manual Bidding (Keywords): When choosing manual bidding on keywords once again you choose the max bid (how much you are willing to pay when someone views your ads) and you can select different bids for each keyword in the campaign..</span>`,
    },
    keywordBiddingStrategy: {
      label: 'Keyword Bidding Strategy',
      info: `Select the Keyword strategy for your campaign <span style="margin-top:5px; display:block;"> <strong> Automatic Keywords (Recommended):</strong> We will choose the most relevant keywords automatically based on the products you select to promote.<span/> <span style="margin-top:5px; display:block;"><strong>Manual Keywords:</strong>You can manually choose the keywords for which you want to show your products as sponsored products. You can either add an exact match keyword or a phrase match.</span><span style="margin-top:5px; display:block;"><strong>"keyword"</strong> = exact match </span><strong>keyword </strong> = phrase match<span style="margin-top:5px; display:block;">For example if you choose <strong>"red shirts"</strong> we will present your ad only when someone looks for red shirts but NOT to people looking for red women's shirts</span>`,
    },
    productSelection: {
      label: 'Product Selection',
      info: 'Please select the products you want to promote as ads',
    },
    skuSelector: {
      label: 'Select your products',
      placeholder: 'Search by SKU, or title',
    },
    sellers: {
      label: 'Seller',
      placeholder: 'Select sellers',
    },
  },
  creation: {
    navButtons: {
      cancel: 'Cancel',
      createCampaign: 'CREATE CAMPAIGN',
      updateCampaign: 'SAVE',
      previous: 'PREVIOUS',
    },
    campaignID: 'Campaign Id',
    campaignStatus: 'Status',
    target: 'Country',
    selectDates: 'Select the sponsored products duration',
    startDate: 'Start date',
    endDate: 'End date',
    endDateOptions: {
      noEndDate: 'No end date',
      specificDate: 'Specific date',
    },
    budget: 'Budget',
    budgetTypes: 'Budget Types',
    dailyLimit: 'Daily limit (optional)',
    bidding: 'Bidding',
    bidType: 'Bid Type',
    biddingStrategy: 'Bid strategy',
    keywordStrategy: 'Keywords strategy',
    manualKeyword: 'Manual keyword',
    keywordInfo: {
      desktop:
        'You can manually choose relevant keywords for your campaign or let us choose the most relevant keywords automatically based on the products that you chose.<span style="margin-top:5px; display:block;"><strong>"keyword"</strong> = exact match </span><strong>keyword </strong> = phrase match<span style="margin-top:5px; display:block;">For example if you choose <strong>"blue boots"</strong> we will present your ad only when someone looks for blue boots but not to people who are looking for blue women boots.</span>',
      mobile:
        'Please note while selecting keyword. eg."keyword"= exact match and keyword = phrase match',
    },
    maxCPM: 'Max CPM',
    selectProducts: 'Select sponsored products',
    selectedKeywords: 'Selected Keywords',
    productsAvailable: 'products available',
    desiredAcos: 'Desired Acos',
    errors: {
      required: 'Field is required',
      atLeastOne: 'At least one is required',
      onlyNumbers: 'You can only use Numbers',
      name: {
        maxChars: 'Name contains more than 50 characters',
        invalidChars:
          'Name contains invalid characters, only letters, numbers and "_" are allowed',
      },
      startDate: {
        pastDate: 'Start date cannot occur in the past',
      },
      endDate: {
        beforeStartDate: 'End date cannot occurs before Start date',
        oneYearStartDate: 'End date cannot occur 1 year after Start date',
      },
      budget: {
        invalid: 'Invalid value',
        min: 'Minimum value is {{targetCurrency}} {{minBudget}}',
        max: 'Maximum value is {{targetCurrency}} {{maxBudget}}',
        dailyLimit: "Daily limit can't exceed budget",
      },
      bid: {
        invalid: 'Invalid value',
        min: 'Minimum value is {{targetCurrency}} {{minBid}}',
        max: 'Maximum value is {{targetCurrency}} {{maxBid}}',
        budgetExceeded: "Max CPM can't exceed current budget",
        dailyLimit: "Max CPM can't exceed daily limit",
      },
      products: {
        invalid: 'Select at least one product',
      },
      manualKeyword: {
        specialCharacters:
          '\'Only - or _ or "word in double quotes" are allowed as special characters\'',
        oneLetter: 'Must contain at least one letter',
      },
      keywords: {
        minLength: 'At least 1 keyword should be added',
        maxLength: 'Max {{maxCount}} keywords are allowed',
      },
      noDefaultPackage: 'No default package for the selected country',
      skus: {
        invalidChars:
          'SKUs contains invalid characters, only letters and numbers are allowed',
      },
    },
    notifications: {
      success: 'Congratulations! Your campaign has been created successfully.',
      noInvalidSkus: 'SKUs Added Successfully',
      invalidSkus: `Sku's were added but some were invalid`,
      noSkusValid: `None of the Skus were added`,
    },
  },
  list: {
    searchHintFO: 'Search campaigns by name or ID',
    searchHintBO: 'Search campaigns by name, ID, or Advertiser',
    searchField: 'Type',
    startDate: 'Campaign start date',
    searchFields: {
      id: 'ID',
      name: 'Name',
      advertiserName: 'Advertiser',
    },
    columnLabels: {
      status: 'Off/On',
      id: 'ID',
      name: 'Name',
      startDate: 'Start date',
      endDate: 'End date',
      bidding: 'Bidding',
      keywords: 'Keywords',
      advertiser: 'Advertiser',
      impressions: 'Impressions',
      clicks: 'Clicks',
      sales: 'Sales',
      spent: 'Spent',
      roas: 'ROAS',
      acos: 'ACoS',
      actions: {
        title: 'Actions',
        view: 'View',
        edit: 'Edit',
        auditInfo: 'Audit Info',
      },
    },
    actions: {
      activate: {
        title: 'Activate',
        tooltip: 'Activate Campaign',
        confirmationTitle: 'Activate Campaign',
        confirmationDescription:
          'Are you sure you want to activate this campaign?',
        successMessage: 'Campaign successfully activated',
        errorMessage: 'It was not possible to activate the campaign',
      },
      disable: {
        title: 'Disable',
        tooltip: 'Disable Campaign',
        confirmationTitle: 'Disable Campaign',
        confirmationDescription:
          'Are you sure you want to disable this campaign?',
        successMessage: 'Campaign successfully disabled',
        errorMessage: 'It was not possible to disable the campaign',
      },
      confirmationButton: 'Yes',
      declineButton: 'No',
      labels: {
        OUT_OF_BUDGET:
          'Please top-up your account with more budget to restart your campaign',
        ENDED:
          'Your campaigns has reached its end date, please create a new campaign',
      },
    },
    filters: {
      status: {
        allFilters: 'All Filters',
        delivering: 'Delivering',
        paused: 'Paused',
        accountOutOfBudget: 'Account Out of Budget',
        campaignOutOfBudget: 'Campaign Out of Budget',
        completed: 'Completed',
      },
    },
    quickFilters: {
      title: 'Filters',
      label: 'Brands: ',
    },
  },
  view: {
    title: 'Sponsored products',
    campaignID: '(Campaign ID: {{campaignID}})',
    editCampaign: 'Edit campaign',
    timeFrameOptions: {
      today: 'Today',
      yesterday: 'Yesterday',
      last7Days: 'Last 7 days',
      last30Days: 'Last 30 days',
      thisMonth: 'This month',
      lastMonth: 'Last month',
      specificDate: 'Specific date range',
    },
    statusToggle: {
      activate: {
        tooltip: 'Activate Campaign',
        confirmationTitle: 'Activate Campaign',
        confirmationDescription:
          'Are you sure you want to activate this campaign?',
        successMessage: 'Campaign successfully activated',
        errorMessage: 'It was not possible to activate the campaign',
      },
      disable: {
        tooltip: 'Disable Campaign',
        confirmationTitle: 'Disable Campaign',
        confirmationDescription:
          'Are you sure you want to disable this campaign?',
        successMessage: 'Campaign successfully disabled',
        errorMessage: 'It was not possible to disable the campaign',
      },
      label: 'Status',
      confirmationButton: 'Yes',
      declineButton: 'No',
    },
    chart: {
      impressions: 'Impressions',
      clicks: 'Clicks',
      ctr: 'CTR',
      conversions: 'Conversions',
      convRate: 'Conv. Rate',
      avgCpc: 'Avg. CPC',
      avgCpm: 'Avg. CPM',
      sales: 'Sales',
      spent: 'Spent',
      acos: 'ACoS',
      roas: 'ROAS',
    },
    tabButtons: {
      products: 'Products',
      keywords: 'Keywords',
      negativeKeywords: 'Negative Keywords',
    },
    filters: {
      keywords: { searchPlaceholder: 'Search for Keywords' },
    },
    list: {
      keywords: {
        status: 'Status',
        keyword: 'Keyword',
        type: 'Type',
        image: 'Image',
        price: 'Price',
        salePrice: 'Sale Price',
        title: 'Title',
        brand: 'Brand',
        category: 'Category',
        sku: 'SKU',
        competition: 'Competition',
        impressions: 'Impressions',
        clicks: 'Clicks',
        conversions: 'Conversions',
        convRate: 'Conv. Rate',
        spent: 'Spent',
        ctr: 'CTR',
        acos: 'ACoS',
        sales: 'Sales',
        volume: 'Volume',
        roas: 'ROAS',
        cpm: 'CPM',
      },
      notifications: {
        keywords: {
          success: 'Keyword was deleted',
          error: 'Failed to delete keyword',
        },
        negativeKeywords: {
          success: 'Negative keyword was deleted',
          error: 'Failed to delete negative keyword',
        },
      },
      actions: {
        keywords: {
          confirmationTitle: 'Delete Keyword',
          confirmationDescription:
            'By submitting, this keyword will be removed from your campaign, and its metrics will no longer be available. ',
        },
        negativeKeywords: {
          confirmationTitle: 'Delete Negative Keyword',
          delete: 'Delete',
          confirmationDescription:
            'By submitting, this negative keyword will be removed from your campaign, and its metrics will no longer be available. ',
        },
        confirm: 'Yes',
        decline: 'No',
      },
    },
    addKeyword: {
      title: 'Add keyword',
      form: {
        text: 'Manual keyword',
        submit: 'Submit',
        errors: {
          required: 'Keyword is required',
          unique: 'Keyword is unique',
        },
      },
      notifications: {
        addSuccess: 'keyword added successfully',
        addError: 'Failed to add a keyword',
      },
    },
    addNegativeKeyword: {
      title: 'Add a negative keyword',
    },
    addProduct: {
      title: 'Add a Product',
      notifications: {
        addSuccess: 'Product added successfully',
        addError: 'Failed to add Product',
      },
      fileUpload: {
        title: 'File Upload',
        mainText: {
          firstSentence: 'Please chose the file you would like to upload',
          secondSentence: ' The supported extension is: CSV;',
          dinamicSecondSentence: ' The supported extensions are: ',
          thirdSentence: ` The file should have at least one column, named "SKU", containing the SKUs;`,
          forthSentence: ` Separate one SKU per row;`,
          downloadSentence: 'To download an example, click ',
          here: 'here',
        },
        fileUploader: {
          label: 'Upload File',
          validation: {
            fileSize: `The max size you can upload is`,
            required: 'File is required for upload',
          },
          notifications: {
            downloadSample: {
              error: 'The file was not downloaded',
              success: 'Your download will start soon',
              title: 'SKUListSample.csv',
            },
            upload: {
              success: 'SKUs Added Successfully',
              skusInfoEmpty: 'Some of the uploaded SKUs in the file are empty',
              skusInfoInvalid:
                "Some of the SKU's that you uploaded will not be included in the campaign, as they are not available:",
              skusInfoRetry:
                'You can verify if you typed the right SKU and try again.',
              error: 'This time it was not possible to upload your file',
            },
          },
        },
      },
    },
    buttons: {
      addKeyword: 'Add a Keyword',
      addNegativeKeyword: 'Add a Negative Keyword',
      bulkUpload: 'Bulk Upload',
      addProduct: 'Add Product',
      fileUpload: 'File Upload',
      upload: 'Upload',
    },
    errors: {
      noUser: 'There is no user logged',
      noService: 'Keywords temporarily unavailable',
      noNegativeKeywords1: 'No negative Keywords yet',
      noNegativeKeywords2:
        'You can add new keywords to your negative list at anytime',
      dailyPerformanceError:
        'Failed to load daily performance, try again later',
    },
    bulkUpload: {
      title: 'Bulk Upload',
      description: 'Please enter the SKUs you want to add in separate rows',
      placeholder: 'Example \n5599663344EA \nAWKJ9856SD \nQWKJ6565LKL',
      submit: 'Submit',
      notifications: {
        addSuccess: 'Products added successfully',
      },
      errors: {
        notValidSkus:
          'Some of the SKUs you uploaded will not be included in your campaign as they are not available:',
        tryAgainSkus:
          'you can verify if you typed the right SKU and try again.',
      },
    },
  },
  productList: {
    exportProducts: 'Export',
    noResults: {
      title: 'No Results',
      description: 'Your search has not returned any results',
    },
    searchBySku: 'Search a product by Sku',
    searchByTitle: 'Search a product by Title',
    searchByBrand: 'Search a product by Brand',
    selectedItems: 'Selected Items: ',
    columnLabels: {
      product: '',
      title: 'Name',
      image: 'Image',
      sku: 'SKU',
      price: 'Price',
      salePrice: 'Sale Price',
      category: 'Category',
    },
    details: {
      selectedItems: ' SELECTED PRODUCTS',
    },
    notifications: {
      error: 'The status was not possible to be changed',
      sucess: 'The status was changed',
    },
    actions: {
      status: {
        confirmationTitle: 'Change the product status',
        confirmationBody: 'Change this product to: ',
        active: 'Active',
        paused: 'Pause',
        edit: 'Edit',
        cancel: 'Cancel',
        bulkEditCpm: 'Bulk Edit CPM',
        outOfStock: 'Out of stock',
      },
    },
    keywords: {
      cpm: {
        title: 'Edit your CPM',
        fields: {
          product: 'Product',
          keyword: 'Keywords',
          currentCPM: 'Current CPM',
          newCPM: 'New CPM',
        },
        notifications: {
          success: 'CPM successfully updated.',
          error: 'CPM change was not able to be saved.',
        },
        errors: {
          invalid: 'Invalid value',
          required: 'Field is required',
          minBid: 'Minimum value is {{minBid}}',
          maxBid: 'Maximum value is {{maxBid}}',
        },
      },
    },
    exportNotifications: {
      success: 'Products downloaded successfully',
      error:
        'Can not able to download. Something went wrong. Please try again later',
    },
  },
  keywords: {
    exact: 'exact',
    phrase: 'phrase',
    cpm: {
      title: 'Edit your CPM',
      fields: {
        product: 'Product',
        keyword: 'Keywords',
        currentCPM: 'Current CPM',
        newCPM: 'New CPM',
      },
      notifications: {
        success: 'CPM successfully updated.',
        error: 'CPM change was not able to be saved.',
      },
      errors: {
        invalid: 'Invalid value',
        required: 'Field is required',
        minBid: 'Minimum value is {{minBid}}',
        maxBid: 'Maximum value is {{maxBid}}',
      },
    },
  },
  settings: {
    title: 'Campaign Settings',
    subTitle: 'Please select country to see the settings',
    currValues: 'Current Values',
    newValues: 'New Values',
    currMinBid: 'Current Min. Bid',
    currMaxBid: 'Current Max Bid',
    newMinBid: 'New Min Bid',
    newMaxBid: 'New Max Bid',
    currMinBudget: 'Current Min Budget',
    currMaxBudget: 'Current Max Budget',
    newMinBudget: 'New Min Budget',
    newMaxBudget: 'New Max Budget',
    currCouponValue: 'Current Coupon Value',
    currDefaultAcos: 'Current Default Acos',
    labels: {
      minBidLabel: 'Change min bid',
      maxBidLabel: 'Change max bid',
      minBudgetLabel: 'Change min budget',
      maxBudgetLabel: 'Change max budget',
      couponValueLabel: 'Coupon value',
      defaultAcosLabel: 'Defaul Acos',
    },
    errors: {
      required: 'Field is required',
      bid: {
        min: 'Minimum bid is {{minBid}}',
        max: 'Maximum bid is {{maxBid}}',
        invalid: 'Invalid value',
        less: 'The min bid should be less than the max bid',
        more: 'The max bid should be higher than the min bid',
      },
      budget: {
        min: 'Minimum budget is {{minBudget}}',
        max: 'Maximum budget is {{maxBudget}}',
        invalid: 'Invalid value',
      },
      couponValue: {
        invalid: 'Invalid value',
      },
      acos: {
        invalid: 'Invalid value',
        min: 'Mininum default Acos is {{minAcos}}',
        max: 'Maximum default Acos is {{maxAcos}}',
      },
      failUpdate: 'Can not able to update. Try again later.',
      successUpdate: 'Campaign settings updated successfully.',
    },
  },
  performanceReport: {
    chart: {
      tabs: {
        impressions: 'Impressions',
        clicks: 'Clicks',
        ctr: 'CTR',
        spend: 'Spent',
        conversions: 'Conversions',
        roas: 'ROAS',
        conversionRate: 'Conv. Rate',
        acos: 'ACoS',
        avgCpc: 'Avg. CPC',
        avgCpm: 'Avg. CPM',
        sales: 'Sales',
      },
    },
    notifications: {
      error: 'Can not able to get the report. Try again later.',
      success: 'Report downloaded successfully.',
      reportError: 'Please select a report type.',
    },
    downloadReport: {
      btnLabel: 'Download Report',
      toLabel: 'To',
      fromLabel: 'From',
      reportType: 'Report Types',
      downloadTypes: {
        campaignPerformance: 'Campaign performance',
        brandPerformance: 'Brand performance',
        categoryPerformance: 'Category performance',
      },
    },
    customDates: {
      title: 'Select custom dates',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
  },
}
