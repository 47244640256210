import {
  Platform,
  PlatformList,
  PlatformListResponse,
  PlatformResponse,
} from '~/services/selfservice/platforms/types'

function mapPlatform(response: PlatformResponse): Platform {
  return {
    id: response.id,
    name: response.name,
    createdAt: response.created_at,
    updatedAt: response.updated_at,
    createdBy: response.created_by,
    updatedBy: response.updated_by,
  }
}
function mapPlatformListResponse(response: PlatformListResponse): PlatformList {
  return {
    list: response.data.map((p: PlatformResponse) => mapPlatform(p)),
  }
}

export { mapPlatformListResponse, mapPlatform }
