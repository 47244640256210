const pad = (v: number) => (v < 10 ? `0${v}` : v)

export function generateMediaName(name: string): string {
  const d = new Date()
  const year = d.getFullYear().toString()
  const month = pad(d.getMonth() + 1)
  const day = pad(d.getDate())
  const hour = pad(d.getHours())
  const min = pad(d.getMinutes())
  const sec = pad(d.getSeconds())

  const filename = name.split('.')[0]
  return `${filename}_${year + month + day + hour + min + sec}`
}
