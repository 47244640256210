import React from 'react'
import Breadcrumbs from '@JumiaMDS/ads-components/Breadcrumbs'
import Sidebar from '../Sidebar'
import Toastification from '../Toastification'
import UserSelection from '../UserSelection'
import { breadcrumbNameMap } from './Constants'
import useContainer from './Hooks/useContainer'
import { ContainerBox, ContentBox } from './styles'

type ContainerProps = {
  children: React.ReactNode
}

const Container: React.FunctionComponent<ContainerProps> = ({
  children,
}: ContainerProps) => {
  const {
    user,
    representations,
    selectedRepresentation,
    isLoading,
    displaysUserSelectionPopUp,
    isSmallScreen,
    onChangeUser,
    setFullSidebar,
    hasPermission,
    handleBreadcrumb,
    pathnames,
    appWidth,
    onSelectUser,
    onCloseSelectUser,
  } = useContainer()

  return (
    <ContentBox flexDirection={isSmallScreen ? 'column' : 'row'}>
      <Sidebar
        onChangeUser={onChangeUser}
        representation={selectedRepresentation}
        widthChanged={(value: boolean) => setFullSidebar(value)}
        hasMoreThanOneRepresentation={
          representations != null && representations.length > 1 && hasPermission
        }
      />

      {user && user.userRepresentation && hasPermission && (
        <ContainerBox
          px={{ xs: 1.5, sm: 1.5, md: 3 }}
          py={{ xs: 1.5, sm: 1.5, md: 3 }}
          appWidth={appWidth()}
        >
          <Breadcrumbs
            aria-label="breadcrumbs"
            nameMap={breadcrumbNameMap}
            handleClick={handleBreadcrumb}
            pathnames={pathnames}
          />
          {children}
          <Toastification />
        </ContainerBox>
      )}

      {!hasPermission && (
        <ContainerBox
          px={{ xs: 1.5, sm: 1.5, md: 3 }}
          py={{ xs: 1.5, sm: 1.5, md: 3 }}
          appWidth={appWidth()}
        >
          <Breadcrumbs
            nameMap={breadcrumbNameMap}
            handleClick={handleBreadcrumb}
            pathnames={pathnames}
          />
          {children}
          <Toastification />
        </ContainerBox>
      )}

      {!isLoading &&
        displaysUserSelectionPopUp &&
        representations &&
        hasPermission && (
          <UserSelection
            onSelect={onSelectUser}
            onClose={onCloseSelectUser}
            userRepresentations={representations}
          />
        )}
    </ContentBox>
  )
}

export default Container
