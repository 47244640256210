/* eslint-disable @typescript-eslint/no-explicit-any */
import { AppRoute } from './types'
import { OFFLINE_ADVERTISING_TYPES } from '~/constants/campaigns'
import { PERMISSIONS } from '~/constants/permissions'
import { ROUTE_PATHS } from '~/constants/routes'
import { lazyWithRetryAndLoader } from '~/utils/lazyWithRetry'

const PackagesList = lazyWithRetryAndLoader(() => import('~/modules/packages'))
const PackageCreate = lazyWithRetryAndLoader(
  () => import('~/modules/packages/Create')
)
const InsightsList = lazyWithRetryAndLoader(() => import('~/modules/insights'))
const FinanceList = lazyWithRetryAndLoader(() => import('~/modules/finance'))
const ReportsDetails = lazyWithRetryAndLoader(
  () => import('~/modules/documents/Details')
)
const Reports = lazyWithRetryAndLoader(() => import('~/modules/documents/List'))
const SponsoredProductsView = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-products/View')
)

const SponsoredProducts = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-products/List')
)

const SponsoredProductsCreation = lazyWithRetryAndLoader(
  () =>
    import(
      '~/modules/campaigns/sponsored-products/Create/components/Form/index'
    )
)

const SponsoredBrandsView = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-brands/View')
)

const SponsoredBrandsList = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-brands/List')
)

const SponsoredBrandsCreation = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-brands/Create')
)

const TierEvents = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/offline-advertising/tier-events/List')
)

const TierEvent1Creation = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/offline-advertising/tier-events/Create')
)

const OfflineAdvertising = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/offline-advertising/offline-campaigns/List')
)

const OfflineAdvertisingCreation = lazyWithRetryAndLoader(
  () =>
    import('~/modules/campaigns/offline-advertising/offline-campaigns/Create')
)

const ViewCampaign = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/offline-advertising/Components/View')
)

const Campaigns = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/ads/List')
)
const CampaignCreation = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/ads/Create')
)

const AdvertisersList = lazyWithRetryAndLoader(
  () => import('~/modules/advertisers')
)

const Settings = lazyWithRetryAndLoader(() => import('~/modules/settings'))

const DisplayCampaignsList = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/display-campaigns/List')
)

const DisplayCampaignsView = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/display-campaigns/View')
)

const DisplayCampaignsCreation = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/display-campaigns/Create')
)

const DisplayCampaignsSettings = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/display-campaigns/settings')
)

const SponsoredProductsReportsList = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-products/performance-report/List')
)

const SponsoredProductsSettings = lazyWithRetryAndLoader(
  () => import('~/modules/campaigns/sponsored-products/settings')
)

export const routes: AppRoute[] = [
  {
    path: ROUTE_PATHS.CAMPAIGN_CREATE,
    component: <CampaignCreation />,
    permissions: [''],
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_VIEW,
    component: <ViewCampaign />,
    permissions: [''],
  },
  {
    path: ROUTE_PATHS.CAMPAIGN_LIST,
    component: <Campaigns />,
    permissions: [''],
  },
  {
    path: ROUTE_PATHS.SPONSORED_PRODUCTS_LIST,
    component: <SponsoredProducts />,
    permissions: [
      PERMISSIONS.FO_SPONSORED_PRODUCTS_LIST,
      PERMISSIONS.BO_SPONSORED_PRODUCTS_LIST,
    ],
  },
  {
    path: ROUTE_PATHS.SPONSORED_PRODUCTS_CREATE,
    component: <SponsoredProductsCreation />,
    permissions: [
      PERMISSIONS.FO_SPONSORED_PRODUCTS_CREATE,
      PERMISSIONS.BO_SPONSORED_PRODUCTS_EDIT,
    ],
  },
  {
    path: ROUTE_PATHS.SPONSORED_PRODUCTS_EDIT,
    component: <SponsoredProductsCreation />,
    permissions: [
      PERMISSIONS.FO_SPONSORED_PRODUCTS_EDIT,
      PERMISSIONS.BO_SPONSORED_PRODUCTS_EDIT,
    ],
  },
  {
    path: ROUTE_PATHS.SPONSORED_PRODUCTS_VIEW,
    component: <SponsoredProductsView />,
    permissions: [
      PERMISSIONS.FO_SPONSORED_PRODUCTS_DETAILS,
      PERMISSIONS.BO_SPONSORED_PRODUCTS_DETAILS,
    ],
  },
  {
    path: ROUTE_PATHS.SPONSORED_PRODUCTS_REPORTS,
    component: <SponsoredProductsReportsList />,
    permissions: [PERMISSIONS.FO_SPONSORED_PRODUCTS_REPORTS_LIST],
  },
  {
    path: ROUTE_PATHS.SPONSORED_PRODUCTS_SETTINGS,
    component: <SponsoredProductsSettings />,
    permissions: [PERMISSIONS.BO_SPONSORED_PRODUCTS_SETTINGS_MODERATE_VIEW],
  },
  {
    path: ROUTE_PATHS.OFFLINE_ADVERTISING_LIST,
    component: <OfflineAdvertising />,
    permissions: [
      PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS,
      PERMISSIONS.BO_OFFLINE_ADVERTISING_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.OFFLINE_ADVERTISING_CREATE,
    component: <OfflineAdvertisingCreation />,
    permissions: [PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS],
  },
  {
    path: ROUTE_PATHS.OFFLINE_ADVERTISING_VIEW,
    // TEMPORARY SOLUTION TO IDENTIFY TIER1
    component: (
      <ViewCampaign typeCampaign={OFFLINE_ADVERTISING_TYPES.Offline} />
    ),
    permissions: [PERMISSIONS.BO_OFFLINE_ADVERTISING_ACCESS],
  },
  {
    path: ROUTE_PATHS.REPORTS_LIST,
    component: <Reports />,
    permissions: [
      PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS,
      PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.REPORTS_DETAILS,
    component: <ReportsDetails />,
    permissions: [
      PERMISSIONS.FO_OFFLINE_ADVERTISING_ACCESS,
      PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.BO_ROUTES.PACKAGES_LIST,
    component: <PackagesList />,
    permissions: [PERMISSIONS.BO_PACKAGES_LIST, PERMISSIONS.BO_PACKAGES_ACCESS], // TODO: remove PERMISSIONS.BO_PACKAGES_ACCESS permission after validating that it is not used
  },
  {
    path: ROUTE_PATHS.BO_ROUTES.PACKAGES_CREATE,
    component: <PackageCreate />,
    permissions: [PERMISSIONS.BO_PACKAGES_EDIT, PERMISSIONS.BO_PACKAGES_ACCESS], // TODO: remove PERMISSIONS.BO_PACKAGES_ACCESS permission after validating that it is not used
  },
  {
    path: ROUTE_PATHS.BO_ROUTES.PACKAGES_EDIT,
    component: <PackageCreate />,
    permissions: [PERMISSIONS.BO_PACKAGES_EDIT, PERMISSIONS.BO_PACKAGES_ACCESS], // TODO: remove PERMISSIONS.BO_PACKAGES_ACCESS permission after validating that it is not used
  },
  {
    path: ROUTE_PATHS.BO_ROUTES.PACKAGES_CLONE,
    component: <PackageCreate />,
    permissions: [PERMISSIONS.BO_PACKAGES_EDIT, PERMISSIONS.BO_PACKAGES_ACCESS], // TODO: remove PERMISSIONS.BO_PACKAGES_ACCESS permission after validating that it is not used
  },
  {
    path: ROUTE_PATHS.BO_ROUTES.ADVERTISERS,
    component: <AdvertisersList />,
    permissions: [
      PERMISSIONS.BO_USER_MANAGEMENT_LIST,
      PERMISSIONS.BO_USER_MANAGEMENT_ACCESS,
    ], // TODO: remove PERMISSIONS.BO_USER_MANAGEMENT_ACCESS permission after validating that it is not used
  },
  {
    path: ROUTE_PATHS.INSIGHTS_LIST,
    component: <InsightsList />,
    permissions: [
      PERMISSIONS.FO_INSIGHTS_LIST,
      PERMISSIONS.BO_INSIGHTS_LIST,
      PERMISSIONS.FO_INSIGHTS_ACCESS, // TODO: will be deleted after the new permissions will be added
      PERMISSIONS.BO_INSIGHTS_ACCESS, // TODO: will be deleted after the new permissions will be added
    ],
  },
  {
    path: ROUTE_PATHS.FINANCE_LIST,
    component: <FinanceList />,
    permissions: [PERMISSIONS.FO_SPONSORED_PRODUCTS_FINANCE],
  },
  {
    path: ROUTE_PATHS.SPONSORED_BRANDS_LIST,
    component: <SponsoredBrandsList />,
    permissions: [
      PERMISSIONS.FO_SPONSORED_BRANDS_LIST,
      PERMISSIONS.BO_SPONSORED_BRANDS_LIST,
    ],
  },
  {
    path: ROUTE_PATHS.SPONSORED_BRANDS_CREATE,
    component: <SponsoredBrandsCreation />,
    permissions: [PERMISSIONS.FO_SPONSORED_BRANDS_CREATE],
  },
  {
    path: ROUTE_PATHS.SPONSORED_BRANDS_EDIT,
    component: <SponsoredBrandsCreation />,
    permissions: [PERMISSIONS.FO_SPONSORED_BRANDS_EDIT],
  },
  {
    path: ROUTE_PATHS.SPONSORED_BRANDS_CLONE,
    component: <SponsoredBrandsCreation />,
    permissions: [PERMISSIONS.FO_SPONSORED_BRANDS_CREATE],
  },
  {
    path: ROUTE_PATHS.TIER_EVENTS_LIST,
    component: <TierEvents />,
    permissions: [
      PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
      PERMISSIONS.BO_TIER1_EVENTS_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.SPONSORED_BRANDS_VIEW,
    component: <SponsoredBrandsView />,
    permissions: [PERMISSIONS.BO_SPONSORED_BRANDS_DETAILS],
  },
  {
    path: ROUTE_PATHS.TIER_EVENTS_CREATE,
    component: <TierEvent1Creation />,
    permissions: [
      PERMISSIONS.FO_TIER1_EVENTS_ACCESS,
      PERMISSIONS.BO_TIER1_EVENTS_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.TIER1_EVENTS_VIEW,
    component: (
      <ViewCampaign typeCampaign={OFFLINE_ADVERTISING_TYPES.TierEvent} />
    ),
    permissions: [PERMISSIONS.BO_TIER1_EVENTS_ACCESS],
  },
  {
    path: ROUTE_PATHS.SETTINGS,
    component: <Settings />,
    permissions: [PERMISSIONS.BO_MODULES_MODERATE],
  },
  {
    path: ROUTE_PATHS.DISPLAY_CAMPAIGNS_LIST,
    component: <DisplayCampaignsList />,
    permissions: [
      PERMISSIONS.FO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
      PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.DISPLAY_CAMPAIGNS_VIEW,
    component: <DisplayCampaignsView />,
    permissions: [
      PERMISSIONS.FO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS,
      PERMISSIONS.BO_DISPLAY_CAMPAIGNS_DETAILS_ACCESS,
    ],
  },
  {
    path: ROUTE_PATHS.DISPLAY_CAMPAIGNS_CREATE,
    component: <DisplayCampaignsCreation />,
    permissions: [PERMISSIONS.FO_DISPLAY_CAMPAIGNS_CREATE_ACCESS],
  },
  {
    path: ROUTE_PATHS.DISPLAY_CAMPAIGNS_EDIT,
    component: <DisplayCampaignsCreation />,
    permissions: [PERMISSIONS.FO_DISPLAY_CAMPAIGNS_CREATE_ACCESS],
  },
  {
    path: ROUTE_PATHS.DISPLAY_CAMPAIGNS_SETTINGS,
    component: <DisplayCampaignsSettings />,
    permissions: [PERMISSIONS.BO_DISPLAY_CAMPAIGNS_LIST_ACCESS],
  },
]
