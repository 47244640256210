import { i18n } from '~/translations/i18n'

export const breadcrumbNameMap: { [key: string]: string } = {
  // ads
  'campaigns/ads': i18n.t('breadcrumbs.campaignsList'),
  'campaigns/ads/:id': i18n.t('breadcrumbs.campaignView'),
  'campaigns/ads/creation': i18n.t('breadcrumbs.campaignsCreation'),
  // sponsored products
  'campaigns/sponsored-products': i18n.t(
    'breadcrumbs.sponsoredProductsCampaignsList'
  ),
  'campaigns/sponsored-products/creation': i18n.t(
    'breadcrumbs.sponsoredProductsCampaignsCreation'
  ),
  'campaigns/sponsored-products/:id/edit': i18n.t(
    'breadcrumbs.sponsoredProductsCampaignsEdit'
  ),
  'campaigns/sponsored-products/:id': i18n.t(
    'breadcrumbs.sponsoredProductsCampaignView'
  ),
  'campaigns/sponsored-products-settings': i18n.t(
    'breadcrumbs.sponsoredProductsCampaignSettings'
  ),
  // offline advertising
  'campaigns/offline-advertising': i18n.t(
    'breadcrumbs.offlineAdvertisingCampaignsBids'
  ),
  'campaigns/tier-events': 'Tier 1 Events',
  'campaigns/offline-advertising/creation': i18n.t(
    'breadcrumbs.offlineAdvertisingCampaignsCreation'
  ),
  // insights
  'campaigns/insights': i18n.t('breadcrumbs.insights'),
  // documents
  'campaigns/documents': i18n.t('breadcrumbs.documentsList'),
  'campaigns/documents/:id': i18n.t('breadcrumbs.documentsDetails'),

  finance: i18n.t('breadcrumbs.finance'),

  packages: i18n.t('breadcrumbs.packages.list'),

  'campaigns/sponsored-brands': i18n.t('breadcrumbs.sponsoredBrands.list'),
  'campaigns/sponsored-brands/creation': i18n.t(
    'breadcrumbs.sponsoredBrands.creation'
  ),
  'campaigns/sponsored-brands/:id': i18n.t(
    'breadcrumbs.sponsoredBrands.details'
  ),
  'campaigns/display-campaigns': i18n.t('breadcrumbs.displayCampaigns.list'),
  'campaigns/display-campaigns/create': i18n.t(
    'breadcrumbs.displayCampaigns.creation'
  ),
  'campaigns/sponsored-products-performance-reports': i18n.t(
    'breadcrumbs.sponsoredProductsCampaignReports'
  ),
}
