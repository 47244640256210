import { ReactNode } from 'react'

export interface Notification {
  text: ReactNode
  type: 'success' | 'error' | 'warning' | 'info'
}

export interface TablePagination {
  campaigns: TableQuery
  sponsoredProducts: TableQuery
  spCampaignAudits: TableQuery
  sbCampaignAudits: TableQuery
  offlineAdvertising: TableQuery
  products: TableQuery
  addProducts: TableQuery
  keywords: TableQuery
  negativeKeywords: TableQuery
  packages: TableQuery
  insights: TableQuery
  finance: TableQuery
  tier1Events: TableQuery
  sponsoredBrands: TableQuery
  advertisers: TableQuery
  advertisersUserManagement: TableQuery
  displayCampaigns: TableQuery
  sponsoredBrandsReports: TableQuery
  displayCampaignsReports: TableQuery
}

export interface TableQuery {
  page: number
  perPage: number
  sortBy: string
  orderBy: 'asc' | 'desc'
  type: PaginationTypes
  searchQuery?: string
  searchBy?: string | undefined
  quickFilters?: any
  startDate?: Date | string | undefined | null
  endDate?: Date | string | undefined | null
  filters?: any
  loaded?: boolean
}

export interface AppState {
  notification: Notification
  loading: boolean
  tablePagination: TablePagination
  defaultRouteOnAccountChange: string
  moduleSettings: any[]
}

export enum PaginationTypes {
  CAMPAIGNS = 'campaigns',
  SPONSORED_PRODUCTS = 'sponsored-products',
  OFFLINE_ADVERTISING = 'offline-advertising',
  PRODUCTS = 'products',
  ADD_PRODUCTS = 'add-products',
  KEYWORDS = 'keywords',
  NEGATIVE_KEYWORDS = 'negativeKeywords',
  PACKAGES = 'packages',
  INSIGHTS = 'insights',
  FINANCE = 'finance',
  TIER1_EVENTS = 'tier1-events',
  SPONSORED_BRANDS = 'sponsored-brands',
  SP_CAMPAIGN_AUDITS = 'sp-campaign-audits',
  SB_CAMPAIGN_AUDITS = 'sb-campaign-audits',
  ADVERTISERS = 'advertisers',
  ADVERTISERS_USER_MANAGEMENT = 'advertisers-user-management',
  DISPLAY_CAMPAIGNS = 'display-campaigns',
  DISPLAY_CAMPAIGNS_REPORTS = 'display-campaigns-reports',
  SPONSORED_BRANDS_REPORTS = 'sponsored-brands-reports',
}

export enum NotificationTypes {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}
