export const ROUTE_PATHS = {
  CAMPAIGN_CREATE: '/campaigns/ads/creation',
  CAMPAIGN_VIEW: '/campaigns/ads/:id',
  CAMPAIGN_LIST: '/campaigns/ads',
  SPONSORED_PRODUCTS_LIST: '/campaigns/sponsored-products',
  SPONSORED_PRODUCTS_REPORTS:
    '/campaigns/sponsored-products-performance-reports',
  SPONSORED_PRODUCTS_SETTINGS: '/campaigns/sponsored-products-settings',
  SPONSORED_PRODUCTS_CREATE: '/campaigns/sponsored-products/creation',
  SPONSORED_PRODUCTS_EDIT: '/campaigns/sponsored-products/:id/edit',
  SPONSORED_PRODUCTS_VIEW: '/campaigns/sponsored-products/:id',
  OFFLINE_ADVERTISING_LIST: '/campaigns/offline-advertising',
  OFFLINE_ADVERTISING_CREATE: '/campaigns/offline-advertising/creation',
  OFFLINE_ADVERTISING_VIEW: '/campaigns/offline-advertising/:id',
  REPORTS_LIST: '/campaigns/documents',
  REPORTS_DETAILS: '/campaigns/documents/:id',
  BO_ROUTES: {
    INSIGHTS: '/campaigns/insights',
    PACKAGES_LIST: '/packages',
    PACKAGES_CREATE: '/packages/creation',
    PACKAGES_EDIT: '/packages/edit/:id',
    PACKAGES_CLONE: '/packages/clone/:id',
    ADVERTISERS: '/advertisers',
  },
  INSIGHTS_LIST: '/insights',
  FINANCE_LIST: '/finance',
  SPONSORED_BRANDS_LIST: '/campaigns/sponsored-brands',
  SPONSORED_BRANDS_CREATE: '/campaigns/sponsored-brands/creation',
  SPONSORED_BRANDS_EDIT: '/campaigns/sponsored-brands/:id/edit',
  SPONSORED_BRANDS_CLONE: '/campaigns/sponsored-brands/:id/clone',
  SPONSORED_BRANDS_VIEW: '/campaigns/sponsored-brands/:id',
  TIER_EVENTS_LIST: '/campaigns/tier1-events',
  TIER_EVENTS_CREATE: '/campaigns/tier1-events/creation',
  TIER1_EVENTS_VIEW: '/campaigns/tier1-events/:id',
  SETTINGS: '/settings',
  DISPLAY_CAMPAIGNS_LIST: '/campaigns/display-campaigns',
  DISPLAY_CAMPAIGNS_VIEW: '/campaigns/display-campaigns/:id',
  DISPLAY_CAMPAIGNS_CREATE: '/campaigns/display-campaigns/create',
  DISPLAY_CAMPAIGNS_EDIT: '/campaigns/display-campaigns/edit/:id',
  DISPLAY_CAMPAIGNS_SETTINGS: '/campaigns/display-campaigns-settings',
}
