import { useSelector } from 'react-redux'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import { routes } from './routes'
import Container from '~/components/Container'
import NoPermission from '~/components/NoPermission'
import NotFound from '~/components/NotFound'
import { Link } from '~/components/Sidebar/types'
import useSections from '~/components/Sidebar/useSections'
import useSession from '~/hooks/useSession'
import Auth from '~/modules/auth'
import { State } from '~/store'
import { UserState } from '~/store/user/types'

const AppRoutes = () => {
  const { isValidSession } = useSession()
  const { data: user } = useSelector<State, UserState>((state) => state.user)
  const { sections } = useSections()

  const getRouteWithPermission = (permissions: string[]) => {
    const allowedLink: Link | undefined = sections[0].links.find((link) => {
      if (link.subLinks && link.subLinks.length > 0) {
        return link.subLinks.find((subLink) =>
          subLink.permissions?.some((permission) =>
            permissions?.includes(permission)
          )
        )
      }
      return link.permissions?.some((permission) =>
        permissions?.includes(permission)
      )
    })
    if (allowedLink?.subLinks && allowedLink.subLinks.length > 0) {
      const allowedSubLink = allowedLink.subLinks.find((subLink) =>
        subLink.permissions?.some((permission) =>
          permissions?.includes(permission)
        )
      )
      return allowedSubLink?.href
    }
    return allowedLink?.href
  }

  const redirectRoute = () => {
    const route = getRouteWithPermission(user.permissions)
    return route || '/'
  }

  return isValidSession ? (
    <BrowserRouter>
      <Container>
        <Routes>
          {routes.map(({ path, component, permissions }) => (
            <Route
              key={path}
              path={path}
              element={<PrivateRoute permissions={permissions} />}
            >
              <Route path={path} element={component} />
            </Route>
          ))}
          <Route path="/" element={<Navigate to={redirectRoute()} />} />
          <Route path="/no-permission" element={<NoPermission />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Container>
    </BrowserRouter>
  ) : (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Auth />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRoutes
