import { Dispatch } from '@reduxjs/toolkit'
import { updateNotification } from '../app/slice'
import { NotificationTypes } from '../app/types'
import {
  clearState,
  isFetching,
  updateUserData,
  disableIsFetching,
} from './slice'
import { api } from '~/services/api'
import { authService } from '~/services/auth'
import { ls } from '~/utils/localStorage'

export const login =
  (authCode: string, isVendorCenter: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(isFetching())
    dispatch(clearState())
    try {
      const {
        email,
        picture,
        name,
        permissions,
        refresh_token: refreshToken,
        auth_token: authToken,
      } = await (isVendorCenter
        ? authService.silentLogin(authCode)
        : authService.loginWithGoogle(authCode))

      ls.set('user', {
        authToken,
        refreshToken,
        email,
        picture,
        name,
        permissions,
        isVendorCenter,
      })

      ls.set('is_auth', true)
      dispatch(
        updateUserData({
          email,
          name,
          picture,
          permissions,
          userRepresentation: null,
          canChangeRepresentation: false,
          advertiserId: null,
          isVendorCenter,
        })
      )

      api.setApiDefaultsHeaders(authToken)
    } catch (error: any) {
      dispatch(
        updateNotification({
          text: error.message,
          type: NotificationTypes.ERROR,
        })
      )
      dispatch(clearState())
    } finally {
      ls.set('is_fetching', false)
      dispatch(disableIsFetching())
    }
  }

export const logout =
  () =>
  (dispatch: Dispatch): void => {
    dispatch(clearState())

    ls.remove('user')
  }
