import React from 'react'
import { styled } from '@mui/material'
import {
  NavLink as RouterLink,
  NavLinkProps as RouterLinkProps,
} from 'react-router-dom'

type LinkProps = RouterLinkProps

const StyledLink = styled(RouterLink)(({ theme }) => ({
  textDecoration: 'none',
  color: '#282828',
  '&.active': {
    color: theme.palette.primary.main,
  },
}))

const Link: React.FunctionComponent<LinkProps> = ({
  to,
  children,
}: LinkProps) => <StyledLink to={to}>{children}</StyledLink>

export default Link
