import {
  CategoriesList,
  Category,
  CategoriesListResponse,
  CategoryResponse,
  CategoryRequestParams,
  CategoryRequestParsedParams,
} from './types'
import { mapPagination } from '~/services/selfservice/types'

function mapCategoryResponse(response: CategoryResponse): Category {
  return {
    id: response.id,
    name: response.name,
  }
}

export const mapCategoriesListResponse = (
  response: CategoriesListResponse
): CategoriesList => ({
  pagination: response.meta && mapPagination(response.meta),
  list: response.data.map((p) => mapCategoryResponse(p)),
})

export const mapCategoryParams = (
  params: CategoryRequestParsedParams
): CategoryRequestParams => {
  const { targetCode, level, parentIds } = params
  return {
    target_code: targetCode,
    level,
    parent_ids: parentIds ?? undefined,
  }
}
