import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { Representation } from '../../UserSelection'
import { onSuccess } from '../utils/onSuccess'
import useGetUserRepresentations from './useGetUserRepresentations'
import { getPermissions } from '~/constants/permissions'
import { USER_REPRESENTATIONS } from '~/constants/queryKeys'
import useModuleSettings from '~/hooks/useModuleSettings'
import usePermission from '~/hooks/usePermission'
import { UserRepresentationsResponse } from '~/services/selfservice/users'
import { State } from '~/store'
import {
  updateRepresentations,
  updateUserRepresentation,
} from '~/store/user/slice'
import { UserRepresentation, UserState } from '~/store/user/types'

export default function useContainer() {
  const { data: user } = useSelector<State, UserState>((state) => state.user)
  const { fetch: fetchModules } = useModuleSettings()
  const isLoading = useSelector<State, boolean>((state) => state.app.loading)
  const [representations, setRepresentations] = useState<
    null | Representation[]
  >(null)
  const [userRepresentations, setUserRepresentations] = useState<
    UserRepresentation[]
  >([])
  const [fullSideBar, setFullSidebar] = useState(true)
  const hasPermission = usePermission({
    permissions: getPermissions.vendor,
  })
  const dispatch = useDispatch()
  const queryClient = useQueryClient()

  useGetUserRepresentations({
    isVendorCenter: user.isVendorCenter,
    hasPermission,
    userRepresentation: user.userRepresentation,
    email: user.email,
    onSuccess: (data: UserRepresentationsResponse) => {
      onSuccess({
        data,
        setUserRepresentations,
        setRepresentations,
        dispatch,
      })
    },
  })

  const [displaysUserSelection, setDisplaysRepSelection] = useState(false)
  const displaysUserSelectionPopUp = !user.userRepresentation
    ? true
    : displaysUserSelection

  const selectedRepresentation = useMemo(
    () => representations?.find((r) => r.id === user.userRepresentation),
    [user, representations]
  )

  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const navigate = useNavigate()
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)
  const defaultRoute = useSelector<State, string>(
    (state) => state.app.defaultRouteOnAccountChange
  )

  const handleBreadcrumb = (href: string) => {
    navigate(href)
  }

  useEffect(() => {
    fetchModules()
  }, [])

  const onSelectUser = (r: Representation) => {
    dispatch(
      updateUserRepresentation({
        repId: r.id,
        canChangeRep: true,
        advertiserId: r.advertiserId,
        targetCode: r.targetCode,
        brands: r.brands,
        sellersIds: r.sellersIds,
        publicId: r.publicId,
      })
    )
    dispatch(
      updateRepresentations({
        userRepresentations,
      })
    )
    setDisplaysRepSelection(false)
    window.location.href = defaultRoute
  }

  const onCloseSelectUser = () => {
    // user selection cannot be dismissed on the first time
    if (user.userRepresentation) {
      setDisplaysRepSelection(false)
    }
  }

  const onChangeUser = useCallback(() => {
    if (!user.email && representations) return
    queryClient.invalidateQueries(USER_REPRESENTATIONS.GET_USER_REPRESENTATIONS)
    setDisplaysRepSelection(true)
  }, [])

  const appWidth = (): string => {
    if (isSmallScreen) return '100%'
    if (fullSideBar) return 'calc(100% - 200px)'
    if (!fullSideBar) return 'calc(100% - 56px)'
    return '100%'
  }

  return {
    appWidth,
    handleBreadcrumb,
    pathnames,
    onChangeUser,
    displaysUserSelectionPopUp,
    onCloseSelectUser,
    onSelectUser,
    selectedRepresentation,
    isLoading,
    setFullSidebar,
    user,
    representations,
    isSmallScreen,
    hasPermission,
  }
}
