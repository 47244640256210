import { User } from '~/store/user/types'

type ValidateUserPermissions = {
  user: User
  permissions?: string[]
}

const validateUserPermissions = ({
  user,
  permissions = [],
}: ValidateUserPermissions) => {
  if (permissions.length > 0) {
    const hasAllPermissions = permissions?.some((permission) =>
      user.permissions?.includes(permission)
    )

    if (!hasAllPermissions) return false
  }

  return true
}

export default validateUserPermissions
