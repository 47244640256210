import { useState } from 'react'
import { useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { State } from '~/store'
import { UserState } from '~/store/user/types'

const useSidebar = () => {
  const { data: user } = useSelector<State, UserState>((state) => state.user)
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [open, setOpen] = useState(true)
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.between('xs', 'sm'))
  const isMediumDown = useMediaQuery(theme.breakpoints.down('md'))
  const isMediumUp = useMediaQuery(theme.breakpoints.between('md', 'xl'))

  return {
    isSmallScreen,
    isMediumDown,
    isMediumUp,
    user,
    setOpen,
    menuAnchorEl,
    setMenuAnchorEl,
    open,
  }
}

export default useSidebar
