import { ListAction } from '../types'
import {
  Campaign,
  CampaignBO,
  CampaignDetail,
  CampaignDetailResponse,
  CampaignList,
  CampaignListBO,
  CampaignListItem,
  CampaignListItemResponse,
  CampaignListRequestParams,
  CampaignListRequestParsedParams,
  CampaignListResponse,
  CampaignListResponseBO,
  CampaignResponse,
  CampaignResponseBO,
  CampaignStatusRequestParams,
  CampaignStatusRequestParsedParams,
} from './types'
import { mapPagination } from '~/services/selfservice/types'
import { i18n } from '~/translations/i18n'
import { camelToSnakeCase } from '~/utils/camelToSnakeCase'
import { formatDate } from '~/utils/formatDate'

// ? this is used in FO mapper to map through the response that comes from
// ? the API and return the the list with specific structure
// TODO: separate to campaign, offline, documents mappers
const mapFoCampaignListItemResponse = (
  {
    id,
    name,
    status,
    target,
    created_at,
    created_by,
    updated_at,
    updated_by,
    start_date,
    end_date,
    package: pkg,
    documents,
    offline_advertising,
  }: CampaignListItemResponse,
  isReports: boolean
): CampaignListItem => {
  const viewActions: ListAction[] =
    !isReports || (isReports && documents?.length > 0)
      ? [
          {
            buttonType: 'icon',
            event: 'view',
            icon: 'visibility',
            label: i18n.t('documents.details.view'),
          },
        ]
      : [
          {
            buttonType: 'icon',
            event: '',
            icon: 'visibility_off',
            label: i18n.t('documents.details.notAvailable'),
          },
        ]
  const editActions: ListAction[] =
    !isReports && offline_advertising
      ? [
          {
            buttonType: 'icon',
            event: 'edit',
            icon: 'edit',
            label: i18n.t('campaigns.list.actions.edit'),
          },
        ]
      : []
  return {
    id,
    billboard_id: offline_advertising?.billboard_id,
    name,
    package: pkg.package_type.name,
    status: status.name,
    target: target.name,
    createdAt: formatDate(created_at),
    updatedAt: formatDate(updated_at),
    createdBy: created_by,
    updatedBy: updated_by,
    startDate: formatDate(start_date),
    endDate: formatDate(end_date),
    oflnBrand: offline_advertising?.brand,
    oflnEmail: offline_advertising?.email,
    oflnOffer: offline_advertising?.offer,
    oflnPhoneNumber: offline_advertising?.phone_number,
    oflnOfferId: offline_advertising?.offer_id,
    actions: [...viewActions, ...editActions],
    oflnEvent: offline_advertising?.event,
  }
}

// ? this is used for main FO mapper to map through the response that comes from the API
export function mapFoCampaignListResponse(
  response: CampaignListResponse,
  isReports: boolean
): CampaignList {
  return {
    pagination: mapPagination(response.meta),
    list: response.data.map((c) => mapFoCampaignListItemResponse(c, isReports)),
  }
}

// & this is used in BO mapper to map through the response that comes from
// & the API and return the list with specific structure
export const mapCampaignResponseBO = ({
  id,
  name,
  entities,
  requester,
  status,
  target,
  created_at,
  created_by,
  updated_at,
  updated_by,
  start_date,
  end_date,
  offline_advertising,
  package: pkg,
}: CampaignResponseBO): CampaignBO => {
  const actions: ListAction[] = [
    {
      event: 'view',
      icon: 'visibility',
      label: i18n.t('campaigns.list.actions.view'),
    },
  ]

  return {
    id,
    billboard_id: offline_advertising?.billboard_id,
    name,
    package: pkg.package_type.name,
    advertiser: entities.name,
    requester: requester.email,
    status: status.name,
    target: target.name,
    oflnBrand: offline_advertising?.brand,
    oflnEmail: offline_advertising?.email,
    oflnOffer: offline_advertising?.offer,
    oflnOfferId: offline_advertising?.offer_id,
    oflnPhoneNumber: offline_advertising?.phone_number,
    createdAt: formatDate(created_at),
    updatedAt: formatDate(updated_at),
    createdBy: created_by,
    updatedBy: updated_by,
    startDate: formatDate(start_date),
    endDate: formatDate(end_date),
    actions,
    oflnEvent: offline_advertising?.event,
  }
}

// & this is used for main FO mapper to map through the response that comes from the API
export const mapCampaignListResponseBO = (
  response: CampaignListResponseBO
): CampaignListBO => ({
  list: response.data.map((i) => mapCampaignResponseBO(i)),
  pagination: mapPagination(response.meta),
})

export const mapCampaignDetailResponse = (
  campaign: CampaignDetailResponse
): CampaignDetail => {
  const categories = campaign.categories || []
  const {
    start_date,
    end_date,
    custom_category,
    creatives,
    platforms,
    sponsored_brands,
  } = campaign

  return {
    id: campaign.id,
    name: campaign.name,
    entities: campaign.entities,
    requester: campaign.requester,
    target: campaign.target,
    status: campaign.status,
    vertical: campaign.vertical,
    package: {
      name: campaign.package.name,
      type: campaign.package.package_type.name,
    },
    documents:
      campaign?.documents?.map((p) => ({
        fileID: p.id,
        fileURL: p.url,
        name: p.name,
      })) || [],
    platforms: platforms?.map((p) => ({ id: p.id, name: p.name })) || [],
    startDate: formatDate(start_date, '/'),
    endDate: formatDate(end_date, '/'),
    categories: categories?.map((c) => ({ id: c.id, name: c.name })) || [],
    customCategory: custom_category,
    creatives:
      creatives?.map((creative) => ({
        header: creative.header,
        type: creative.name,
        advertiserDisplayName: creative.advertiser_display_name,
        landingPage: creative.landing_page,
        callAction: creative.call_action,
        impressionTracker: creative.impression_tracker,
        mainImage: creative.main_image,
        masterVisual: creative.master_visual,
        productImage: creative.product_image,
        logo: creative.logo,
        body: creative.body,
      })) || [],
    offlineAdvertising:
      campaign.offline_advertising !== undefined
        ? {
            id: campaign.offline_advertising.id,
            month: campaign.offline_advertising.month,
            billboardId: campaign.offline_advertising.billboard_id,
            benchmarkPrice: campaign.offline_advertising.benchmark_price,
            location: campaign.offline_advertising.location,
            dimensions: campaign.offline_advertising.dimensions,
            offerImage: campaign.offline_advertising.offer_image,
            offer: campaign.offline_advertising.offer,
            offerId: campaign.offline_advertising.offer_id,
            brand: campaign.offline_advertising.brand,
            email: campaign.offline_advertising.email,
            phoneNumber: campaign.offline_advertising.phone_number,
            inventoryDetails: campaign.offline_advertising.inventory_details,
            target: campaign.offline_advertising.target,
          }
        : undefined,
    sponsoredBrands:
      sponsored_brands !== undefined
        ? {
            targetImpression: sponsored_brands.target_impression,
            targetTypes: sponsored_brands.target_types,
            landingPageType: sponsored_brands.landing_page_type,
            landingPageUrl: sponsored_brands.landing_page_url,
            paused: sponsored_brands.paused,
            categoryIds: sponsored_brands.category_ids,
            products: sponsored_brands.products,
            keywords: sponsored_brands.keywords,
            campaignCreatives: sponsored_brands.campaign_creatives.map(
              (creative) => ({
                logoUrl: creative.logo_url,
                title: creative.title,
                bannerUrlDesktop: creative.banner_url_desktop,
                bannerUrlMobile: creative.banner_url_mobile,
                languageCode: creative.language_code,
                landingPageType: creative.landing_page_type,
                landingPageUrl: creative.landing_page_url,
              })
            ),
          }
        : undefined,
    updatedBy: campaign?.updated_by,
  }
}

export const mapCampaignResponse = (campaign: CampaignResponse): Campaign => {
  const categories = campaign.categories || []
  const { start_date, end_date, custom_category, creatives, platforms } =
    campaign

  return {
    id: campaign.id,
    name: campaign.name,
    entities: campaign.entities,
    requester: campaign.requester,
    target: campaign.target,
    status: campaign.status,
    vertical: campaign.vertical,
    package: {
      name: campaign.package.name,
      type: campaign.package.package_type.name,
    },
    documents:
      campaign?.documents?.map((p) => ({
        fileID: p.id,
        fileURL: p.url,
        name: p.name,
      })) || [],
    platforms: platforms?.map((p) => ({ id: p.id, name: p.name })) || [],
    startDate: formatDate(start_date, '/'),
    endDate: formatDate(end_date, '/'),
    categories: categories?.map((c) => ({ id: c.id, name: c.name })) || [],
    customCategory: custom_category,
    creatives:
      creatives?.map((creative) => ({
        header: creative.header,
        type: creative.name,
        advertiserDisplayName: creative.advertiser_display_name,
        landingPage: creative.landing_page,
        callAction: creative.call_action,
        impressionTracker: creative.impression_tracker,
        mainImage: creative.main_image,
        masterVisual: creative.master_visual,
        productImage: creative.product_image,
        logo: creative.logo,
        body: creative.body,
      })) || [],
  }
}

export const mapCampaignListRequestParams = (
  requestParams: CampaignListRequestParams,
  offlineType: string | undefined
): CampaignListRequestParsedParams => {
  const {
    userRepresentation,
    features,
    page,
    perPage,
    sortBy,
    orderBy,
    query,
    filters,
  } = requestParams

  return {
    representation_id: userRepresentation ?? undefined,
    page: page ?? undefined,
    per_page: perPage ?? undefined,
    sort_by: camelToSnakeCase(sortBy),
    order_by: camelToSnakeCase(orderBy),
    query: query ?? undefined,
    advertiser: filters.advertiser ? filters.advertiser.trim() : undefined,
    target_code: filters?.country,
    offline_event: filters?.offlineEvent,
    status_id: filters.status > 0 ? filters.status : undefined,
    features,
    has_documents: filters.has_documents,
    offline_type: offlineType || undefined,
  }
}

export const mapCampaignStatusRequestParams = (
  requestParams: CampaignStatusRequestParams | undefined
): CampaignStatusRequestParsedParams => ({
  campaign_type: requestParams?.campaignType,
})
