import { useQuery } from 'react-query'
import { USER_REPRESENTATIONS } from '~/constants/queryKeys'
import { selfServiceService } from '~/services/selfservice'

interface UserRepresentationParams {
  isVendorCenter: boolean
  onSuccess: any
  hasPermission: boolean
  userRepresentation: any
  email: string | null
}

export default function useGetUserRepresentations({
  isVendorCenter,
  onSuccess,
  hasPermission,
  userRepresentation,
  email,
}: UserRepresentationParams) {
  return useQuery(
    [
      USER_REPRESENTATIONS.GET_USER_REPRESENTATIONS,
      isVendorCenter,
      userRepresentation,
      email,
    ],
    () => selfServiceService.fetchUserRepresentations(isVendorCenter),
    {
      enabled: hasPermission,
      onSuccess,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  )
}
