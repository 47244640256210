import { configureStore } from '@reduxjs/toolkit'
import { AppState } from './app/types'
import { State as DisplayCampaignsState } from './display-campaigns/types'
import { rootReducer } from './rootReducer'
import { SponsoredBrandsState } from './sponsored-brands/types'
import { SponsoredProductsState } from './sponsored-products/types'
import { UserState } from './user/types'

export interface State {
  user: UserState
  app: AppState
  sponsoredProducts: SponsoredProductsState
  sponsoredBrands: SponsoredBrandsState
  displayCampaigns: DisplayCampaignsState
}

export const store = configureStore({ reducer: rootReducer })
