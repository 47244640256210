import { i18n } from '~/translations/i18n'

export const targets = [
  {
    id: 1,
    code: 'CI',
    value: i18n.t('targets.ci'),
    name: i18n.t('targets.ci'),
    currency: 'FCFA',
    verticals: [1, 2, 3],
  },
  // { id: 2, code: 'CM', value: 'Cameroon', currency: 'BEAC', verticals: [1] },
  {
    id: 3,
    code: 'DZ',
    value: i18n.t('targets.dz'),
    name: i18n.t('targets.dz'),
    currency: 'DA',
    verticals: [1, 2],
  },
  {
    id: 4,
    code: 'EG',
    value: i18n.t('targets.eg'),
    name: i18n.t('targets.eg'),
    currency: 'EGP',
    verticals: [1, 2, 3],
  },
  {
    id: 5,
    code: 'GH',
    value: i18n.t('targets.gh'),
    name: i18n.t('targets.gh'),
    currency: 'GH₵',
    verticals: [1, 2, 3],
  },
  {
    id: 6,
    code: 'KE',
    value: i18n.t('targets.ke'),
    name: i18n.t('targets.ke'),
    currency: 'KSh',
    verticals: [1, 2, 3],
  },
  {
    id: 7,
    code: 'MA',
    value: i18n.t('targets.ma'),
    name: i18n.t('targets.ma'),
    currency: 'Dhs',
    verticals: [1, 2, 3],
  },
  {
    id: 8,
    code: 'NG',
    value: i18n.t('targets.ng'),
    name: i18n.t('targets.ng'),
    currency: '₦',
    verticals: [1, 2, 3],
  },
  {
    id: 9,
    code: 'SN',
    value: i18n.t('targets.sn'),
    name: i18n.t('targets.sn'),
    currency: 'FCFA',
    verticals: [1, 2],
  },
  {
    id: 10,
    code: 'TN',
    value: i18n.t('targets.tn'),
    name: i18n.t('targets.tn'),
    currency: 'TND',
    verticals: [1, 2, 3],
  },
  {
    id: 11,
    code: 'UG',
    value: i18n.t('targets.ug'),
    name: i18n.t('targets.ug'),
    currency: 'UGX',
    verticals: [1, 2, 3],
  },
  {
    id: 12,
    code: 'ZA',
    value: i18n.t('targets.za'),
    name: i18n.t('targets.za'),
    currency: 'R',
    verticals: [1],
  },
  {
    id: 13,
    code: 'ZZ',
    value: i18n.t('targets.zz'),
    name: i18n.t('targets.zz'),
    currency: 'R',
    verticals: [1],
  },
]
