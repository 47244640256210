import { combineReducers } from '@reduxjs/toolkit'
import appReducer from './app/slice'
import displayCampaignsReducer from './display-campaigns/slice'
import sponsoredBrandsReducer from './sponsored-brands/slice'
import sponsoredProductsReducer from './sponsored-products/slice'
import userReducer from './user/slice'

export const rootReducer = combineReducers({
  user: userReducer,
  app: appReducer,
  sponsoredProducts: sponsoredProductsReducer,
  sponsoredBrands: sponsoredBrandsReducer,
  displayCampaigns: displayCampaignsReducer,
})
