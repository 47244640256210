import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  CampaignDetails,
  SponsoredProductsState,
  StoreConfigMap,
} from './types'

const INITIAL_STATE: SponsoredProductsState = {
  storeConfigMap: {},
  selectedCampaignDetails: {
    campaignId: '',
    currency: '',
    biddingStrategy: '',
    budget: -1,
  },
  sellers: [],
}

const slice = createSlice({
  name: 'sponsoredProducts',
  initialState: INITIAL_STATE,
  reducers: {
    updateStoreConfigMap(state, action: PayloadAction<StoreConfigMap>) {
      state.storeConfigMap = action.payload
    },
    setSelectedCampaignDetails(state, action: PayloadAction<CampaignDetails>) {
      state.selectedCampaignDetails = action.payload
    },
    updateSellers(state, action: PayloadAction<string[]>) {
      state.sellers = action.payload
    },
  },
})

export const {
  updateStoreConfigMap,
  setSelectedCampaignDetails,
  updateSellers,
} = slice.actions

export default slice.reducer
