import React from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import { ReactComponent as Logo } from '~/images/logo.svg'
import { i18n } from '~/translations/i18n'

const LoadingWithLogo = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    alignItems="center"
    height="80vh"
  >
    <Box sx={{ width: '800px' }}>
      <Logo />
    </Box>
    <Box m={2}>
      <Typography
        variant="body1"
        align="center"
        sx={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
        }}
      >
        {i18n.t('other.redirectText')}
      </Typography>
    </Box>
    <Box mt={2}>
      <CircularProgress size={60} color="primary" />
    </Box>
  </Box>
)

export default LoadingWithLogo
