import axios from 'axios'
import { errorHandler } from './interceptors'
import { envs } from '~/envs'

const fireworks = axios.create({
  baseURL: `${envs.API_ENDPOINT}/acl/`,
  headers: {
    application: envs.ACL,
  },
})
fireworks.interceptors.response.use((response) => response, errorHandler)

const selfservice = axios.create({
  baseURL: `${envs.API_ENDPOINT}/selfservice/`,
  headers: {
    application: envs.ACL,
  },
})
selfservice.interceptors.response.use((response) => response, errorHandler)

const cosmos = axios.create({
  baseURL: `${envs.API_ENDPOINT}/media-service/`,
  headers: {
    application: envs.ACL,
  },
})
cosmos.interceptors.response.use((response) => response, errorHandler)

const sponsoredProducts = axios.create({
  baseURL: `${envs.API_ENDPOINT}/sponsored-products/`,
  headers: {
    application: envs.ACL,
  },
})
sponsoredProducts.interceptors.response.use(
  (response) => response,
  errorHandler
)
const displayCampaigns = axios.create({
  baseURL: `${envs.API_ENDPOINT}/display-campaigns/`,
  headers: {
    application: envs.ACL,
  },
})

const catalog = axios.create({
  baseURL: `${envs.API_ENDPOINT}/catalog/`,
  headers: {
    application: envs.ACL,
  },
})
displayCampaigns.interceptors.response.use((response) => response, errorHandler)

const setApiDefaultsHeaders = (token: string): void => {
  fireworks.defaults.headers.Authorization = `Bearer ${token}`
  selfservice.defaults.headers.Authorization = `Bearer ${token}`
  cosmos.defaults.headers.Authorization = `Bearer ${token}`
  sponsoredProducts.defaults.headers.Authorization = `Bearer ${token}`
  displayCampaigns.defaults.headers.Authorization = `Bearer ${token}`
  catalog.defaults.headers.Authorization = `Bearer ${token}`
}

export const api = {
  fireworks,
  selfservice,
  cosmos,
  sponsoredProducts,
  setApiDefaultsHeaders,
  displayCampaigns,
  catalog,
}
