import { i18n } from '~/translations/i18n'

export const STATUS_TYPES = {
  All: i18n.t('campaigns.status.all'),
  CampaignCreated: i18n.t('campaigns.status.campaignCreated'),
  PendingReview: i18n.t('campaigns.status.pendingReview'),
  Ready: i18n.t('campaigns.status.ready'),
  Delivering: i18n.t('campaigns.status.delivering'),
  Completed: i18n.t('campaigns.status.completed'),
  ActionRequired: i18n.t('campaigns.status.actionRequired'),
  PendingApproval: i18n.t('campaigns.status.pendingApproval'),
  Active: i18n.t('campaigns.status.active'),
  Inactive: i18n.t('campaigns.status.inactive'),
  TargetAchieved: i18n.t('campaigns.status.targetAchieved'),
  Rejected: i18n.t('campaigns.status.rejected'),
  Closed: i18n.t('campaigns.status.closed'),
}

export const BULK_UPLOAD_PRODUCTS_CODE_STATUS = {
  ProductsuploadstatusAll: 'PU_ALL',
  ProductsuploadstatusSome: 'PU_SOME',
  ProductsuploadstatusNone: 'PU_NONE',
}

export const OFFLINE_ADVERTISING_TYPES = {
  Offline: 'offline',
  TierEvent: 'tier1',
}

export enum CAMPAIGN_TYPE {
  GENERIC = 'generic',
  OFFLINE = 'offline',
  SB = 'sponsored_brand',
  SP = 'sponsored_product',
}
