import React, { useEffect, useState, ReactNode } from 'react'
import type { FC } from 'react'
import { Alert } from '@mui/material'
import { useSelector } from 'react-redux'
import { CustomSnackbar } from '~/assets/generalStyles'
import { State } from '~/store'
import { Notification } from '~/store/app/types'

interface SnackbarState {
  open: boolean
  vertical: 'bottom' | 'top'
  horizontal: 'center' | 'left' | 'right'
  message: ReactNode
}

const Toastification: FC = () => {
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    message: '',
  })

  const notification = useSelector<State, Notification>(
    (state) => state.app.notification
  )

  useEffect(() => {
    if (notification.text) {
      setSnackbar({
        ...snackbar,
        open: true,
        message: notification.text,
      })
    }
  }, [notification])

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') return
    setSnackbar({
      ...snackbar,
      open: false,
      message: '',
    })
  }

  return (
    <CustomSnackbar
      anchorOrigin={{
        vertical: snackbar.vertical,
        horizontal: snackbar.horizontal,
      }}
      autoHideDuration={1000 * 5}
      open={snackbar.open}
      onClose={handleClose}
    >
      <Alert
        severity={notification.type}
        sx={{ width: '100%' }}
        onClose={handleClose}
        variant="filled"
      >
        {snackbar.message}
      </Alert>
    </CustomSnackbar>
  )
}

export default Toastification
