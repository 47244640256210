export interface PaginationResponse {
  page: number
  next_page: number
  prev_page: number
  total_pages: number
  total_records: number
}

export interface Pagination {
  page: number
  nextPage?: number
  previousPage?: number
  totalPages?: number
  totalRecords: number
  perPage?: number
}

export interface ErrorResponse {
  code: string
  message: string
  source: string
}

export function mapPagination(pagination: PaginationResponse): Pagination {
  return {
    page: pagination.page,
    totalRecords: pagination.total_records,
  }
}

export interface ListAction {
  event: string
  label: string
  icon?: string
  confirmationRequired?: boolean
  confirmationTitle?: string
  confirmationDescription?: string
  disabled?: boolean
  buttonType?: 'toggle' | 'icon'
  confirmationButtonLabel?: string
  declineButtonLabel?: string
}

export interface GenericIdName {
  id: number
  name: string
}
