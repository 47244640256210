import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { User, UserState } from './types'
import { ls } from '~/utils/localStorage'

const INITIAL_STATE: UserState = {
  isAuth: ls.get('is_auth') || false,
  isFetching: ls.get('is_fetching') || false,
  data: {
    email: ls.get('user').email || null,
    name: ls.get('user').name || null,
    picture: ls.get('user').picture || null,
    permissions: ls.get('user').permissions || [],
    userRepresentation: ls.get('user_representation') || undefined,
    canChangeRepresentation: ls.get('can_change_representation') || undefined,
    advertiserId: ls.get('advertiser_id') || undefined,
    isVendorCenter: ls.get('user').isVendorCenter || false,
  },
  representationData: ls.get('representation_data') || null,
  representations: ls.get('userRepresentations') || null,
  targetCode: ls.get('target_code') || null,
}

export const slice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    isFetching(state) {
      state.isFetching = true
    },
    disableIsFetching(state) {
      state.isFetching = false
    },
    updateUserData(state, action: PayloadAction<User>) {
      const {
        email,
        name,
        permissions,
        picture,
        userRepresentation,
        canChangeRepresentation,
        advertiserId,
        isVendorCenter,
      } = action.payload
      state.isAuth = true
      state.isFetching = false
      state.data = {
        email,
        name,
        permissions,
        picture,
        userRepresentation,
        canChangeRepresentation,
        advertiserId,
        isVendorCenter,
      }
    },
    updateUserRepresentation(
      state,
      action: PayloadAction<{
        repId: number
        canChangeRep: boolean
        advertiserId: number | null
        targetCode: string
        brands: string[]
        sellersIds: string[]
        publicId: string
      }>
    ) {
      const user = ls.get('user')
      ls.set('user_representation', action.payload.repId)
      user.userRepresentation = action.payload.repId
      user.canChangeRepresentation = action.payload.canChangeRep
      user.advertiserId = action.payload.advertiserId
      ls.set('user', user)
      ls.set('target_code', action.payload.targetCode)
      ls.set('representation_data', action.payload)
      state.targetCode = action.payload.targetCode
      state.representationData = action.payload
      state.data.userRepresentation = action.payload.repId
      ls.set('can_change_representation', action.payload.canChangeRep)
      state.data.canChangeRepresentation = action.payload.canChangeRep
      ls.set('advertiser_id', action.payload.advertiserId)
      state.data.advertiserId = action.payload.advertiserId
    },
    clearState(state) {
      state.isAuth = false
      state.data = {
        email: null,
        name: null,
        picture: null,
        permissions: [],
        userRepresentation: null,
        canChangeRepresentation: false,
        advertiserId: null,
        isVendorCenter: false,
      }
      state.representationData = {
        advertiserId: null,
        canChangeRep: null,
        repId: null,
        targetCode: null,
        brands: [],
        sellersIds: [],
      }
      state.representations = []
      ls.remove('representation_data')
      ls.remove('userRepresentations')
      ls.remove('user_representation')
      ls.remove('can_change_representation')
      ls.remove('advertiser_id')
      ls.remove('target_code')
    },
    updateRepresentations(state: UserState, action: PayloadAction<any>) {
      state.representations = action.payload.userRepresentations
    },
    updateTargetCode(state: UserState, action: PayloadAction<any>) {
      state.targetCode = action.payload.targetCode
    },
  },
})

export const {
  isFetching,
  updateUserData,
  updateUserRepresentation,
  clearState,
  updateRepresentations,
  disableIsFetching,
  updateTargetCode,
} = slice.actions

export default slice.reducer
