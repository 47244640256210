import { createTheme } from '@mui/material'

const smallDeviceFontSize = '0.8rem'
const rootTheme = createTheme()

export const theme = createTheme(rootTheme, {
  // TODO Added UI colors
  palette: {
    primary: {
      main: '#F68B1E',
      light: '#FFFAF5',
      dark: '#333333',
    },
    common: {
      black: '#000000',
      white: '#FFFFFF',
      lightGray: '#E0E0E0',
    },
    secondary: {
      main: '#f8981c',
      light: '#828282',
    },
    background: {
      paper: '#ffffff',
      default: '#FAFAFA',
    },
    text: {
      primary: '#282828',
    },
    success: {
      main: '#07bc0c',
    },
    error: {
      main: '#e74c3c',
    },
    info: {
      main: '#3498db',
    },
    warning: {
      main: '#f1c40f',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 678,
      md: 991,
      lg: 1200,
      xl: 1440,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          'scroll-behavior': 'smooth',
        },
        body: {
          '*::-webkit-scrollbar': {
            width: '0.5em',
            height: '0.5em',
            backgroundColor: 'rgba(0,0,0,.1)',
          },
          '*::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
          },
          '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.3)',
            borderRadius: '0.5em',
          },
          fontSize: 14,
          '& .MuiCalendarPicker-root .Mui-selected': {
            backgroundColor: 'orange !important',
          },
          '& .MuiBreadcrumbs-root': {
            [rootTheme.breakpoints.down('md')]: {
              fontSize: smallDeviceFontSize,
            },
          },
          '& .MuiTypography-root': {
            [rootTheme.breakpoints.down('md')]: {
              fontSize: smallDeviceFontSize,
            },
          },
          '& .MuiGrid-item:empty': {
            padding: 0,
          },
          [rootTheme.breakpoints.down('md')]: {
            fontSize: 13,
            spacing: 1.5,
            '& .MuiAutocomplete-root': {
              padding: rootTheme.spacing(0.8),
            },
            '& label[data-shrink=false]': {
              top: '-4px',
            },
            '& .MuiSelect-iconOutlined': {
              top: 'calc(50% - 0.3em)',
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            fontSize: smallDeviceFontSize,
            lineHeight: smallDeviceFontSize,
            '& .material-icons': {
              fontSize: '0.8rem',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            height: 'auto',
            padding: rootTheme.spacing(0.5, 1),
          },
        },
        input: {
          [rootTheme.breakpoints.down('md')]: {
            padding: rootTheme.spacing(0.5),
            fontSize: smallDeviceFontSize,
          },
        },
        outlined: {
          input: {
            height: '1rem',
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('lg')]: {
            minHeight: '40px',
            padding: rootTheme.spacing(0.8, 1.5),
          },
          [rootTheme.breakpoints.down('md')]: {
            padding: rootTheme.spacing(0.325, 0.5),
            minHeight: '30px',
            fontSize: '0.7rem',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            padding: rootTheme.spacing(0.5),
            minHeight: 'auto',
          },
        },
        icon: {
          [rootTheme.breakpoints.down('md')]: {
            fontSize: smallDeviceFontSize,
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            fontSize: smallDeviceFontSize,
          },
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            padding: 0,
            whiteSpace: 'pre',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          [rootTheme.breakpoints.down('md')]: {
            padding: rootTheme.spacing(0.375),
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          whiteSpace: 'pre',
          padding: rootTheme.spacing(1),
          [rootTheme.breakpoints.down('md')]: {
            padding: rootTheme.spacing(0.5, 0.75),
            whiteSpace: 'nowrap',
          },
          [rootTheme.breakpoints.up('lg')]: {
            padding: rootTheme.spacing(1.5),
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          MuiPagination: {
            toolbar: {
              [rootTheme.breakpoints.down('md')]: {
                minHeight: '42px',
              },
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            padding: rootTheme.spacing(0.5),
          },
        },
        MuiInput: {
          root: {
            padding: rootTheme.spacing(0.5),
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          [rootTheme.breakpoints.down('md')]: {
            paddingRight: 0,
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          [rootTheme.breakpoints.down('md')]: {
            width: smallDeviceFontSize,
            height: smallDeviceFontSize,
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: `${rootTheme.spacing(0.5)} ${rootTheme.spacing(1.5)}`,
        },
      },
    },
  },
})
