import { api } from '~/services/api'
import { mapPlatformListResponse } from '~/services/selfservice/platforms/mapper'
import { PlatformList } from '~/services/selfservice/platforms/types'

const endpoints = {
  getPlatforms: () => `platforms`,
}

const fetchPlatformsList = async (): Promise<PlatformList> => {
  const response = await api.selfservice.get(endpoints.getPlatforms())
  return mapPlatformListResponse(response.data)
}

export { fetchPlatformsList }
