import { Media, MediaResponse } from '~/services/cosmos/types'
import { generateMediaName } from '~/utils/generateMediaName'

function mapMediaResponse(response: MediaResponse): Media {
  return {
    id: response.data.id,
    url: response.data.url,
  }
}

function mapMediaRequest(
  media: File,
  target?: string,
  advertiserId?: string
): FormData {
  const formData = new FormData()
  formData.append('file', media)
  if (target) formData.append('target', target)
  if (advertiserId) formData.append('advertiser_id', advertiserId)
  formData.append('name', generateMediaName(media.name))
  formData.append('file_type', media.type)
  // by default tag images as being used, we have a cron that will delete images that are tagged as is-used=false
  formData.append('tags', 'is-used=true')
  formData.append('in_use', 'true')

  return formData
}

export { mapMediaResponse, mapMediaRequest }
