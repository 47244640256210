import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { api } from '~/services/api'
import { State } from '~/store'
import { logout } from '~/store/user/middlewares'
import { UserState, UserStorage } from '~/store/user/types'
import { ls } from '~/utils/localStorage'

const useSession = () => {
  const { isAuth } = useSelector<State, UserState>((state) => state.user)

  const dispatch = useDispatch()

  const {
    email,
    name,
    picture,
    authToken,
    permissions,
    userRepresentation,
    canChangeRepresentation,
    advertiserId,
    isVendorCenter,
  }: UserStorage = ls.get('user')

  const isValidSession = useMemo(
    () => isAuth !== false && !!email,
    [isAuth, email]
  )

  useEffect(() => {
    if (email) {
      api.setApiDefaultsHeaders(authToken)
    } else {
      dispatch(logout())
    }
  }, [])

  useEffect(() => {
    if (isAuth === false) {
      dispatch(logout())
    }
  }, [isAuth])

  return {
    isValidSession,
    userData: {
      email,
      name,
      picture,
      authToken,
      permissions,
      userRepresentation,
      canChangeRepresentation,
      advertiserId,
      isVendorCenter,
    },
  }
}

export default useSession
