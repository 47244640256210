import axios, { AxiosError, AxiosPromise } from 'axios'
import { authService } from './auth'
import { api } from '~/services/api'
import { store } from '~/store'
import { logout } from '~/store/user/middlewares'
import { UserStorage } from '~/store/user/types'
import { ls } from '~/utils/localStorage'

interface FailedRequests {
  onSuccess: (token: string) => void
  onFailure: (err: AxiosError) => void
}

let isRefreshing = false
let failedRequestsQueue: FailedRequests[] = []

const errorHandler = (
  error: AxiosError
): Promise<AxiosPromise | PromiseRejectedResult> => {
  // TODO add other condition to check if requires refresh
  // TODO expired token returns 403 not 401, should we run the refresh process on the 401 error?
  // TODO catch refresh token error
  if (error.response?.status === 403) {
    const userStorage: UserStorage = ls.get('user')

    const originalConfig = error.config

    if (!isRefreshing) {
      isRefreshing = true

      authService
        .getRefreshToken(userStorage.authToken, userStorage.refreshToken)
        .then((response) => {
          const { auth_token: newToken, refresh_token: newRefreshToken } =
            response

          ls.set('user', {
            ...userStorage,
            authToken: newToken,
            refreshToken: newRefreshToken,
          })

          api.setApiDefaultsHeaders(newToken)

          failedRequestsQueue.forEach((request) => request.onSuccess(newToken))
          failedRequestsQueue = []
        })
        .catch((err) => {
          failedRequestsQueue.forEach((request) => request.onFailure(err))
          failedRequestsQueue = []

          store.dispatch(logout())
        })
        .finally(() => {
          isRefreshing = false
        })
    }

    return new Promise((resolve, reject) => {
      failedRequestsQueue.push({
        onSuccess: (token: string) => {
          originalConfig.headers.Authorization = `Bearer ${token}`

          resolve(axios(originalConfig))
        },
        onFailure: (err: AxiosError) => {
          reject(err)
        },
      })
    })
  }

  // store.dispatch(logout())

  return Promise.reject(error)
}

export { errorHandler }
