import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState, Notification, PaginationTypes, TableQuery } from './types'
import { defaultPagination } from '~/constants/paginations'
import { ROUTE_PATHS } from '~/constants/routes'

const INITIAL_STATE: AppState = {
  notification: {
    text: '',
    type: 'info',
  },
  loading: false,
  tablePagination: defaultPagination,
  defaultRouteOnAccountChange: ROUTE_PATHS.SPONSORED_PRODUCTS_LIST,
  moduleSettings: [],
}

const slice = createSlice({
  name: 'app',
  initialState: INITIAL_STATE,
  reducers: {
    updateNotification(state: AppState, action: PayloadAction<Notification>) {
      const { text, type } = action.payload

      state.notification = { text, type }
    },
    clearNotification(state: AppState) {
      state.notification = { text: '', type: 'info' }
    },
    enableLoading(state: AppState) {
      state.loading = true
    },
    disableLoading(state: AppState) {
      state.loading = false
    },
    updatePagination(state: AppState, action: PayloadAction<TableQuery>) {
      switch (action.payload.type) {
        case PaginationTypes.CAMPAIGNS: {
          state.tablePagination.campaigns = {
            ...state.tablePagination.campaigns,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.SPONSORED_PRODUCTS: {
          state.tablePagination.sponsoredProducts = {
            ...state.tablePagination.sponsoredProducts,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.OFFLINE_ADVERTISING: {
          state.tablePagination.offlineAdvertising = {
            ...state.tablePagination.offlineAdvertising,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.PRODUCTS: {
          state.tablePagination.products = {
            ...state.tablePagination.products,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.KEYWORDS: {
          state.tablePagination.keywords = {
            ...state.tablePagination.keywords,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.NEGATIVE_KEYWORDS: {
          state.tablePagination.negativeKeywords = {
            ...state.tablePagination.negativeKeywords,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.PACKAGES: {
          state.tablePagination.packages = {
            ...state.tablePagination.packages,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.INSIGHTS: {
          state.tablePagination.insights = {
            ...state.tablePagination.insights,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.FINANCE: {
          state.tablePagination.finance = {
            ...state.tablePagination.finance,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.TIER1_EVENTS: {
          state.tablePagination.tier1Events = {
            ...state.tablePagination.tier1Events,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.ADD_PRODUCTS: {
          state.tablePagination.addProducts = {
            ...state.tablePagination.addProducts,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.SPONSORED_BRANDS: {
          state.tablePagination.sponsoredBrands = {
            ...state.tablePagination.sponsoredBrands,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.ADVERTISERS: {
          state.tablePagination.advertisers = {
            ...state.tablePagination.advertisers,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.SP_CAMPAIGN_AUDITS: {
          state.tablePagination.spCampaignAudits = {
            ...state.tablePagination.spCampaignAudits,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.SB_CAMPAIGN_AUDITS: {
          state.tablePagination.sbCampaignAudits = {
            ...state.tablePagination.sbCampaignAudits,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.ADVERTISERS_USER_MANAGEMENT: {
          state.tablePagination.advertisersUserManagement = {
            ...state.tablePagination.advertisersUserManagement,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.DISPLAY_CAMPAIGNS: {
          state.tablePagination.displayCampaigns = {
            ...state.tablePagination.displayCampaigns,
            ...action.payload,
          }
          break
        }
        case PaginationTypes.SPONSORED_BRANDS_REPORTS: {
          state.tablePagination.sponsoredBrandsReports = {
            ...state.tablePagination.sponsoredBrandsReports,
            ...action.payload,
          }
          break
        }
        default: {
          break
        }
      }
    },
    updateModuleSettings(state: AppState, action: PayloadAction<any[]>) {
      state.moduleSettings = action.payload
    },
    clearPaginations(state: AppState) {
      state.tablePagination.campaigns = INITIAL_STATE.tablePagination.campaigns
      state.tablePagination.sponsoredProducts =
        INITIAL_STATE.tablePagination.sponsoredProducts
      state.tablePagination.offlineAdvertising =
        INITIAL_STATE.tablePagination.offlineAdvertising
      state.tablePagination.spCampaignAudits =
        INITIAL_STATE.tablePagination.spCampaignAudits
      state.tablePagination.sbCampaignAudits =
        INITIAL_STATE.tablePagination.sbCampaignAudits
      state.tablePagination.insights = INITIAL_STATE.tablePagination.insights
      state.tablePagination.finance = INITIAL_STATE.tablePagination.finance
      state.tablePagination.packages = INITIAL_STATE.tablePagination.packages
      state.tablePagination.advertisers =
        INITIAL_STATE.tablePagination.advertisers
      state.tablePagination.sponsoredBrands =
        INITIAL_STATE.tablePagination.sponsoredBrands
    },
    setDefaultRouteOnAccountChange(
      state: AppState,
      action: PayloadAction<string>
    ) {
      state.defaultRouteOnAccountChange = action.payload
    },
  },
})

export const {
  updateNotification,
  clearNotification,
  enableLoading,
  disableLoading,
  updatePagination,
  clearPaginations,
  setDefaultRouteOnAccountChange,
  updateModuleSettings,
} = slice.actions

export default slice.reducer
