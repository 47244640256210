import { FC, ReactNode, Fragment, MouseEvent } from 'react'
import { ArrowBack, ArrowForward } from '@mui/icons-material'
import { Divider, Box, List, Collapse } from '@mui/material'
import { Logo } from '../Logo'
import { Logout } from '../Logout'
import { NavLink } from '../NavLink'
import * as S from '../styles'
import { UserInfo } from '../UserInfo'
import { Representation } from '~/components/UserSelection'

type DesktopSidebarProps = {
  open: boolean
  toggleDrawer: () => void
  accountTitle: ReactNode
  user: any
  representation?: Representation
  handleMenuOpen: (event: MouseEvent<HTMLElement>) => void
  sections: any
  resetState: (link: string) => void
  openSubMenu: any
  handleClick: any
}

const DesktopSidebar: FC<DesktopSidebarProps> = ({
  open,
  toggleDrawer,
  accountTitle,
  representation,
  handleMenuOpen,
  sections,
  user,
  resetState,
  openSubMenu,
  handleClick,
}) => (
  <S.Drawer variant="permanent" open={open}>
    <S.ToggleContainer open={open}>
      {open && (
        <S.LogoContainer>
          <Logo />
        </S.LogoContainer>
      )}
      <S.ToggleIcon onClick={toggleDrawer}>
        {open ? <ArrowBack /> : <ArrowForward />}
      </S.ToggleIcon>
    </S.ToggleContainer>
    <UserInfo
      title={accountTitle}
      picture={String(user.picture)}
      isMobile={false}
      isFO={representation !== undefined}
      drawerOpen={open}
      handleMenuOpen={handleMenuOpen}
    />
    <Divider />
    <List
      component="nav"
      sx={{ bgcolor: 'background.paper' }}
      aria-labelledby="nested-list-subheader"
    >
      {sections.map((section: any, index: number) => (
        <Fragment key={index}>
          {section.links.map((link: any, key: number) => (
            <Fragment key={`${key}-link`}>
              {!link.subLinks ? (
                <NavLink
                  drawerOpen={open}
                  icon={link.icon}
                  key={link?.href}
                  href={link?.href || '#'}
                  enabled={link.enabled}
                  permissions={link?.permissions || []}
                  onClick={() => resetState(link.href || '#')}
                  hasSubMenu={false}
                >
                  {link.label}
                </NavLink>
              ) : (
                <>
                  <NavLink
                    key={`${key}-subLink`}
                    hasSubMenu
                    drawerOpen={open}
                    icon={link.icon}
                    enabled={link.enabled}
                    permissions={link?.permissions || []}
                    href="#"
                    openSubMenu={openSubMenu}
                    handleClick={() => handleClick(key)}
                    subMenuKey={key}
                  >
                    {link.label}
                  </NavLink>
                  <Collapse
                    in={openSubMenu === key}
                    timeout="auto"
                    unmountOnExit
                  >
                    <List component="div" disablePadding>
                      {link.subLinks?.map((subLink: any, keyIndex: number) => (
                        <Box
                          sx={{ marginLeft: '1.5rem' }}
                          key={`${keyIndex}-subLinkNav`}
                        >
                          <NavLink
                            drawerOpen={open}
                            icon={subLink.icon}
                            key={subLink.href}
                            href={subLink.href}
                            enabled={subLink.enabled}
                            permissions={subLink.permissions}
                            onClick={() => resetState(subLink.href)}
                            hasSubMenu={false}
                          >
                            {subLink.label}
                          </NavLink>
                        </Box>
                      ))}
                    </List>
                  </Collapse>
                </>
              )}
            </Fragment>
          ))}
        </Fragment>
      ))}
    </List>
    <Logout drawerOpen={open} />
  </S.Drawer>
)

export default DesktopSidebar
