import { Box, Drawer as MuiDrawer, styled } from '@mui/material'

export const Drawer = styled(MuiDrawer)(() => ({
  position: 'relative',
  '& .MuiDrawer-paper': {
    backgroundColor: 'white',
  },
}))

export const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '5px 24px',
  backgroundColor: 'white',
  [theme.breakpoints.down('sm')]: {
    padding: '5px 12px',
  },
}))

export const LogoContainer = styled(Box)(() => ({
  maxWidth: '89px',
  maxHeight: '24px',
  width: '100%',
  height: '100%',
}))
