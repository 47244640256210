import { api } from '../api'
import { LoginResponse, RefreshTokenResponse } from './types'
import { envs } from '~/envs'

const endpoint = {
  login: 'login',
  silentLogin: 'silent-login',
  refreshToken: 'refresh-token',
  logout: 'logout',
}

const loginWithGoogle = async (authCode: string): Promise<LoginResponse> => {
  const response = await api.fireworks.post(endpoint.login, {
    auth_code: authCode,
    redirect_uri: envs.URI,
  })

  return response.data
}

const silentLogin = async (ssoToken: string): Promise<LoginResponse> => {
  const response = await api.fireworks.post(endpoint.silentLogin, {
    temp_token: ssoToken,
  })

  return response.data.data
}

const getRefreshToken = async (
  authToken: string,
  refreshToken: string
): Promise<RefreshTokenResponse> => {
  const response = await api.fireworks.post(endpoint.refreshToken, {
    auth_token: authToken,
    refresh_token: refreshToken,
  })

  return response.data
}

export const authService = {
  loginWithGoogle,
  silentLogin,
  getRefreshToken,
}
