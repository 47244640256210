import { MouseEvent, ReactNode } from 'react'
import { Avatar } from '@mui/material'
import * as S from './styles'

interface UserInfoProps {
  title: ReactNode
  picture: string
  isMobile: boolean
  isFO: boolean
  drawerOpen: boolean
  handleMenuOpen: (event: MouseEvent<HTMLElement>) => void
}

export const UserInfo = ({
  title,
  picture,
  isMobile = false,
  isFO = false,
  handleMenuOpen,
  drawerOpen,
}: UserInfoProps) => (
  <S.Container
    isMobile={!!isMobile}
    isFO={isFO}
    onClick={(e: MouseEvent<HTMLElement>) => handleMenuOpen(e)}
  >
    <Avatar
      src={picture}
      sx={{
        width: '24px',
        height: '24px',
        border: '1px solid white',
      }}
    />
    {drawerOpen && (
      <S.Name isMobile={!!isMobile} isFO={isFO}>
        {title}
      </S.Name>
    )}
  </S.Container>
)
