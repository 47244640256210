import {
  Box as MuiBox,
  styled,
  Typography,
  Button as MuiButton,
  Alert,
  Grid as MuiGrid,
  Snackbar as MaterialSnackbar,
  BoxProps as MuiBoxProps,
} from '@mui/material'
import { keyframes } from '@mui/styled-engine'

export const customColors = {
  green: '#1CC38F',
  red: '#D1503B',
  grey: '#938C8B',
  lightGrey: '#E4E6E7',
}

export const Title = styled('label')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
}))

export const TitleCapitalized = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.primary.main,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '20px',
}))

export const Text = styled('label')({
  color: 'black',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontSize: '16px',
  lineHeight: '20px',
})

interface BoxProps {
  TopBorder?: boolean
  theme?: any
}

export const Box = styled(MuiBox)(({ theme, TopBorder }: BoxProps) => ({
  marginTop: '10px',
  borderTop: TopBorder ? '2px solid' : 'default',
  borderColor: theme.palette.primary.main,
  padding: '20px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  background: 'white',
}))

export const Button = styled(MuiButton)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: 'white',
  boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
  ':hover': {
    opacity: '0.9',
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
  },
}))

export const PopupDescription = styled(Typography)(() => ({
  color: 'darkGrey',
}))

const Shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

const FadeUp = keyframes`
  from {
    transform: translate3d(0,40px,0)
  }

  to {
    transform: translate3d(0,0,0);
    opacity: 1
  }
`

const bounceRight = keyframes`
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-30px);
  }
  60% {
    transform: translateX(-15px);
  }
`

export const FadeAlert = styled(Alert)(() => ({
  margin: '0 1rem',
  animation: `${Shake} 0.82s cubic-bezier(.36,.07,.19,.97) both`,
}))

export const MultipleActionBar = styled(MuiGrid)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.light,
  padding: '1rem',
  animation: `${FadeUp} 1s both`,
}))

export const SelectedItemsBox = styled(MuiBox)(() => ({
  fontSize: '16px',
  fontWeight: 400,
}))

export const HyperLink = styled('a')(({ theme }) => ({
  color: theme.palette.common.black,
  textDecoration: 'none',
  ':hover': {
    textDecoration: 'underline',
  },
}))

export const FilterLabel = styled('label')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: 'inherit',
  lineHeight: '20px',
}))

export const Span = styled('span')(() => ({
  fontWeight: 'bold',
  color: '#B2B2BC',
  fontSize: '85%',
}))
export const CustomSnackbar = styled(MaterialSnackbar)(({ theme }) => ({
  boxShadow: '0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%)',
  borderRadius: theme.spacing(1),
  maxWidth: '480px',
  animation: `${bounceRight} 0.82s both`,
}))

export const CostBox = styled(Box)<MuiBoxProps>(() => ({
  display: 'flex',
  borderRadius: '20px',
  backgroundColor: '#fbeee0',
  margin: '0px 0px',
  padding: '3px 8px',
}))

export const CostLabelBox = styled(Box)<BoxProps>(({ theme }) => ({
  borderRadius: '20px',
  backgroundColor: 'white',
  margin: '0px 0px',
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  padding: '1px 8px',
}))

export const CostValueBox = styled(Box)<MuiBoxProps>(({ theme }) => ({
  margin: '0px 0px',
  textAlign: 'center',
  fontSize: '1rem',
  fontWeight: 'bold',
  color: theme.palette.primary.main,
  padding: '1px 8px',
  background: 'none',
  boxShadow: 'none',
}))

export const InputError = styled(Typography)(({ theme }) => ({
  fontSize: 'inherit',
  fontWeight: 500,
  marginLeft: '5px',
  marginTop: '5px',
  backgroundColor: 'transparent',
  boxShadow: 'none',
  '&:empty': {
    display: 'none',
  },
  color: theme.palette.error.main,
}))

export const DateRangeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(0.5),
  boxShadow: 'none',
  padding: '0px',
  margin: '0px',
}))
