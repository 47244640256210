import { fetchCategoriesList } from '~/services/catalog/categories'
import {
  createCampaign,
  fetchCampaignList,
} from '~/services/selfservice/campaigns'
import {
  fetchPackageList,
  fetchPackages,
} from '~/services/selfservice/packages'
import { fetchPlatformsList } from '~/services/selfservice/platforms'
import {
  fetchUserRepresentations,
  UserRepresentationsResponse,
  UserRepresentation,
  Entity,
} from '~/services/selfservice/users'

export interface GenericIdName {
  id: number
  name: string
}

export const selfServiceService = {
  fetchUserRepresentations,
  fetchCampaignList,
  fetchPackageList,
  fetchPackages,
  createCampaign,
  fetchPlatformsList,
  fetchCategoriesList,
}

export type { UserRepresentationsResponse, UserRepresentation, Entity }
