import { mapCategoriesListResponse, mapCategoryParams } from './mapper'
import { CategoriesList, CategoryRequestParsedParams } from './types'
import { api } from '~/services/api'

export const endpoints = {
  searchCategories: 'categories/search',
}

export const fetchCategoriesList = async (
  params?: CategoryRequestParsedParams
): Promise<CategoriesList> => {
  const parsedParams = mapCategoryParams(params || {})
  const response = await api.catalog.post(
    endpoints.searchCategories,
    parsedParams
  )
  return mapCategoriesListResponse(response.data)
}
