import { Typography } from '@mui/material'
import * as S from './styles'
import { i18n } from '~/translations/i18n'

const NoPermission = () => (
  <S.Container textAlign="center">
    <Typography fontWeight="500" variant="h5">
      {i18n.t('auth.noPermission.title')}
    </Typography>
    <Typography color="#828282">
      {i18n.t('auth.noPermission.description')}
    </Typography>
  </S.Container>
)

export default NoPermission
