import axios, { CancelToken } from 'axios'
import { api } from '../api'
import { mapedResponse } from './mapper'
import { SettingsListResponseMapped } from './types'

export const endpoints = {
  getSettingsList: '/modules',
  updateSettingsStatus: `/modules`,
}

export const fetchSettingsList = async (
  cancelToken: CancelToken
): Promise<SettingsListResponseMapped> => {
  const responseData = await api.selfservice.get(endpoints.getSettingsList, {
    cancelToken,
  })

  return mapedResponse(responseData.data)
}

export const updateSettingsStatus = async (
  id: string,
  status: boolean
): Promise<void> => {
  const r = { name: id, is_enabled: status }
  try {
    await api.selfservice.put(endpoints.updateSettingsStatus, r)
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response?.data?.errors?.[0]
    } else {
      throw err
    }
  }
}
