/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, styled, ListItemButton as MuiListItemButton } from '@mui/material'

interface ContainerProps {
  isActive: boolean
  drawerOpen: boolean
}

export const Container = styled(Box)<ContainerProps>(
  ({ isActive, drawerOpen, theme }) => ({
    display: 'flex',
    backgroundColor: isActive && drawerOpen ? 'transparent' : 'transparent',
    borderLeft: isActive ? '2px solid' : 'none',
    borderColor: theme.palette.primary.main,
    padding: isActive ? '8px 16px 8px 14px' : '8px 16px',
    marginTop: '12px',
    alignItems: 'center',
    transition: '.3s',
    ':hover': {
      backgroundColor: '#FFF4E9',
    },
  })
)

export const IconContainer = styled(Box)(({ theme }) => ({
  '& svg': {
    height: 'inherit',
    width: 'inherit',
  },
  maxWidth: '24px',
  maxHeight: '24px',
  width: '100%',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    minWidth: '24px',
    minHeight: '24px',
  },
}))

interface LinkProps {
  isActive: boolean
}

export const Link = styled(Box)<LinkProps>(({ isActive, theme }) => ({
  fontWeight: isActive ? 'bold' : 'normal',
  marginLeft: '8px',
  fontSize: '12px',
}))

export const ListItemButton = styled(MuiListItemButton)(({ theme }) => ({
  padding: '0px',
  ':hover': {
    backgroundColor: theme.palette.primary.light,
  },
}))
