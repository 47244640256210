import { Box as MuiBox, BoxProps } from '@mui/material'
import { styled } from '@mui/system'

interface MuiBoxProps extends BoxProps {
  appWidth: string
}

export const ContainerBox = styled(MuiBox)<MuiBoxProps>(({ appWidth }) => ({
  maxHeight: '100vh',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  position: 'relative',
  width: appWidth,
}))

export const ContentBox = styled(MuiBox)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
  maxHeight: '100vh',
  bgcolor: theme.palette.background.default,
}))
