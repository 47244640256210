import { Navigate, Outlet } from 'react-router-dom'
import usePermission from '~/hooks/usePermission'

interface Props {
  permissions: string[]
}

const PrivateRoute = ({ permissions }: Props) => {
  const hasPermission = usePermission({ permissions })

  return hasPermission ? <Outlet /> : <Navigate to="/no-permission" />
}

export default PrivateRoute
