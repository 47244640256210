import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchSettingsList } from '~/services/settings'
import { State } from '~/store'
import { updateModuleSettings } from '~/store/app/slice'

const useModuleSettings = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const list =
    useSelector<State, any[]>((state) => state.app.moduleSettings) || []
  const dispatch = useDispatch()

  const fetch = async (signal: any = false) => {
    try {
      setLoading(true)
      const { data } = await fetchSettingsList(signal)
      dispatch(updateModuleSettings(data))
    } catch (error: any) {
      console.error(error)
      dispatch(updateModuleSettings([]))
    } finally {
      setLoading(false)
    }
  }

  return {
    list,
    fetch,
    loading,
  }
}
export default useModuleSettings
