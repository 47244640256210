import { Button, styled } from '@mui/material'

export const LogoutButton = styled(Button)(() => ({
  padding: '8px 16px',
  backgroundColor: 'white',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  fontSize: '14px',
  color: '#333',
  textTransform: 'initial',
  ':hover': {
    backgroundColor: 'white',
    color: 'primary.main',
  },
}))
