import { sponsoredBrands } from './SponsoredBrand'
import { sponsoredProduct } from './SponsoredProduct'

export const content = {
  en: {
    translations: {
      titles: {
        app: 'App name: {{name}}',
        loading: 'Loading...',
        advertising: 'ADVERTISING',
        campaigns: 'Campaigns',
        sponsoredProducts: 'Sponsored Products',
        offlineAdvertising: 'Offline Advertising',
        documents: 'Campaign Reports',
        homepage: 'Homepage',
        packages: 'Packages',
        advertisers: 'User Management',
        insights: 'Insights',
        finance: 'Finance',
        sponsoredBrands: 'Sponsored Brands',
        tierEvents: 'Tier 1 Events',
        settings: 'Settings',
        displayCampaigns: 'Display Campaigns',
        performanceReport: 'Performance Report',
        SponsoredProductsSettings: 'Settings',
      },
      auth: {
        title: 'Log in to your account',
        signIn: {
          google: 'Sign in with Google',
          email: 'Sign in with Email',
        },
        signOut: 'Sign out',
        noPermission: {
          title: 'No permission',
          description:
            'It seems you don’t have permission to access this part of application. Please contact your administrator for further information.',
        },
      },
      storeSelection: {
        popup: {
          title: 'Choose an account',
        },
        changeAccount: 'Change account',
      },
      packages: {
        list: {
          title: 'Packages list',
          search: 'Search packages',
          add: 'Add Package',
          filter: {
            title: 'Filters',
            reset: 'Reset',
            apply: 'Apply',
            items: {
              target: 'Country',
              updatedAt: 'Updated at',
              updatedBy: 'Updated by',
              status: {
                placeholder: 'Status',
                options: {
                  active: 'Active',
                  inactive: 'Inactive',
                },
              },
            },
          },
          actions: {
            edit: 'Edit Package',
            clone: 'Clone Package',
            delete: {
              tooltip: 'Delete Package',
              confirmationTitle: 'Delete Package',
              confirmationDescription:
                'Are you sure you want to delete this package?',
              successMessage: 'Package successfully deleted',
              errorMessage: 'It was not possible to delete the package',
            },
            activate: {
              tooltip: 'Activate Package',
              confirmationTitle: 'Activate Package',
              confirmationDescription:
                'Are you sure you want to activate this package?',
              successMessage: 'Package successfully activated',
              errorMessage: 'It was not possible to activate the package',
            },
            disable: {
              tooltip: 'Disable Package',
              confirmationTitle: 'Disable Package',
              confirmationDescription:
                'Are you sure you want to disable this package?',
              successMessage: 'Package successfully disabled',
              errorMessage: 'It was not possible to disable the package',
            },
            confirmationButton: 'Yes',
            declineButton: 'No',
          },
          columns: {
            name: 'Name',
            displayName: 'Seasonal package name',
            type: 'Package Type',
            target: 'Country',
            price: 'Price',
            createdBy: 'Created by',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
            actions: 'Actions',
            status: 'Off/On',
          },
        },
        view: {
          edit: 'Edit Package',
          clone: 'Clone Package',
          creation: 'Package Creation',
        },
        creation: {
          fields: {
            target: 'Target',
            type: 'Package Type',
            name: 'Name',
            displayName: 'Seasonal package name',
            description: 'Description',
            price: 'Amount',
            minBudget: 'Minimum Budget',
            minBid: 'Minimum Bid',
            features: 'Features',
            status: 'Status',
            save: 'Save',
            saving: 'Saving...',
            cancel: 'Cancel',
            defaultPackage: 'Set as default',
          },
          createSuccess: 'Package created successfully',
          editSuccess: 'Package updated successfully',
          cloneSuccess: 'Package cloned successfully',
          errors: {
            required: 'Field is required',
            name: {
              maxChars: 'Field contains more than 40 characters',
              invalidChars:
                'Field contains invalid characters, only letters, numbers and "_" are allowed',
            },
            displayName: {
              maxChars: 'Field contains more than 30 characters',
              invalidChars:
                'Field contains invalid characters, only letters, numbers and spaces are allowed',
            },
            description: {
              maxChars: 'Field contains more than 180 characters',
            },
            price: {
              invalid: 'Invalid amount',
              maxPrice: 'The max value allowed is: ',
            },
            minBudget: {
              invalid: 'Invalid amount',
            },
            minBid: {
              invalid: 'Invalid amount',
              max: "min bid can't exceed min budget",
            },
            features: {
              required: 'At least one feature is required',
            },
            validation: 'Validation failed',
            failedCreation: 'Error creating package',
            failedEdit: 'Error updating package',
            failedClone: 'Error cloning package',
          },
        },
        noResults: {
          title: 'No packages Yet',
          description:
            'You can create a new package by clicking the button below',
        },
      },
      breadcrumbs: {
        campaignsList: 'Campaign List',
        campaignsCreation: 'Create New Campaign',
        campaignView: 'Campaign Settings',
        sponsoredProductsCampaignsList: 'Sponsored Products Campaign List',
        sponsoredProductsCampaignsCreation: 'Create New Campaign',
        sponsoredProductsCampaignsEdit: 'Edit Campaign',
        sponsoredProductsCampaignView: 'Sponsored Products Campaign Settings',
        sponsoredProductsCampaignSettings: 'Settings',
        sponsoredProductsCampaignReports: 'Reports List',
        offlineAdvertisingCampaignsBids: 'Offline Advertising Bids',
        offlineAdvertisingCampaignsCreation: 'Jumia Offline Advertising Offers',
        offlineAdvertisingCampaignView: 'Offline Advertising Campaign Settings',
        documentsDetails: 'Documents',
        documentsList: 'Documents List',
        insights: 'Insights',
        finance: 'Finance',
        packages: {
          list: 'Packages List',
          creation: 'Packages Creation',
          edit: 'Packages Edit',
          clone: 'Packages Clone',
        },
        sponsoredBrands: {
          list: 'Sponsored Brands',
          creation: 'Create New campaign',
          details: 'Sponsored Brands Campaign Settings',
        },
        displayCampaigns: {
          list: 'Display Campaigns',
          creation: 'Create New campaign',
        },
      },
      navbar: {
        logout: 'Logout',
      },
      inputs: {
        file: {
          selected: '{{count}} file selected ({{name}})',
          selected_plural: '{{count}} files selected ({{name}})',
          dragOrClick: 'Drag files or click {{here}}',
          here: 'here',
          toUpload: 'to upload',
          uploadFinished: 'Upload finished',
          uploadFailed: 'Upload failed',
          retry: 'retry?',
          selectedFile: 'file selected',
          dragFiles: 'Drag files or click',
          upload: 'to upload',
        },
      },
      campaigns: {
        creation: {
          steps: {
            package: 'Package',
            settings: 'Settings',
            displayAds: 'Display Ads',
            price: 'Price',
            target: 'Country',
            vertical: 'Vertical',
            name: 'Name',
            start: 'Start',
            end: 'End',
            deviceType: 'Device Type',
            landingPage: 'Landing Page',
            onSitePlacement: 'Onsite Placement',
            resume: 'Confirmation',
            productImage: 'Product Image',
            brandLogo: 'Brand Logo',
            creative: 'Creative',
            multipleOptions: 'You can choose multiple options',
            newsletterBanner: 'Newsletter Banner',
            masterVisualDimensions: 'Master Visual dimensions',
            ifOther: 'If other, please specify',
            or: 'OR',
            reviewCampaign:
              'Review your campaign before confirming payment. If you need to change anything click edit to the corresponding step.',
          },
          target: {
            title: 'Choose your country options',
            targetSelect: 'Country',
            verticalSelect: 'Jumia Service',
          },
          package: {
            title: 'Select your package type',
            type: {
              gold: 'Gold Package',
              silver: 'Silver Package',
              bronze: 'Bronze Package',
              platinum: 'Platinum Package',
              seasonal: 'Seasonal Package',
            },
            target: {
              title: 'Choose your country options',
            },
            errors: {
              noTarget: 'We do not have available packages for this country.',
            },
          },
          settings: {
            name: 'Campaign Name',
            startDate: 'Start date',
            endDate: 'End date',
            deviceType: 'Choose device type',
            categories: 'Categories',
            chooseCategories: 'Choose categories',
          },
          displayAds: {
            header: 'Header',
            body: 'Body',
            logo: 'Logo',
            mainImage: 'Main Image',
            uploadInfo: 'Drag files or click here to upload',
            masterVisual: 'Master Visual',
            creative: {
              title: 'Choose your option',
              upload: 'Upload your creative',
              build: 'Build a creative',
            },
            advertiser: 'Advertiser display name',
            landingPage: 'Landing page',
            callToAction: 'Call to action text',
            impressionTracker: 'Impression Tracker',
          },
          navButtons: {
            previous: 'Previous',
            next: 'Next',
            submit: 'Submit',
          },
          errors: {
            required: 'Field is required',
            atLeastOne: 'At least one is required',
            name: {
              maxChars: 'Name contains more than 50 characters',
              invalidChars:
                'Name contains invalid characters, only letters, numbers and "_" are allowed',
            },
            startDate: {
              pastDate: 'Start date cannot occur in the past',
            },
            endDate: {
              beforeStartDate: 'End date cannot occurs before Start date',
              oneYearStartDate: 'End date cannot occur 1 year after Start date',
            },
            category: {
              maxChars: 'Category contains more than 30 characters',
              invalidChars: "Only letters, spaces, commas, & and ' are allowed",
            },
            landingPage: {
              invalidURLFormat: 'The URL has invalid format',
            },
          },
        },
        list: {
          create: 'CREATE CAMPAIGN',
          noData: 'No data available',
          noCampaigns: {
            title: 'No Campaigns Yet',
            description:
              "You don't have any campaigns yet. Create your first campaign now",
          },
          noResults: {
            title: 'No Results',
            description: 'Your search has not returned any results',
          },
          columnLabels: {
            id: 'ID',
            name: 'Name',
            package: 'Type',
            target: 'Country',
            status: 'Status',
            creationDate: 'Creation Date',
            startDate: 'Start Date',
            endDate: 'End Date',
            actions: 'Actions',
            download: 'Download',
          },
          actions: {
            view: 'View Campaign',
            edit: 'Edit Campaign',
            download: 'Download Report',
            noDownload: 'No documents available',
          },
          columns: {
            id: 'ID',
            name: 'Name',
            package: 'Type',
            target: 'Country',
            advertiser: 'Advertiser',
            requestorEmail: 'Requestor Email',
            status: 'Status',
            createdAt: 'Creation Date',
            startDate: 'Start Date',
            endDate: 'End Date',
            actions: 'Action',
          },
          statusHover: {
            pendingReview:
              'One of our campaign specialists is reviewing your campaign, this process usually takes between 1 and 3 business days.',
            review: 'Your campaign is ready to be launched on the start date.',
            actionRequired:
              'One of our campaign specialists has reached out to you over email, some information might be missing.',
            completed: 'Your campaign has ended.',
            delivering: 'Your campaign is now live.',
            ready: 'Your campaign is ready to be launched on the start date.',
          },
          quickFilter: {
            title: 'Filters',
            status: 'STATUS:',
            target: 'TARGET:',
          },
          search: 'Search campaigns by name or ID',
          filter: {
            title: 'Filters',
            reset: 'Reset',
            apply: 'Apply',
            items: {
              advertiser: 'Advertiser',
              target: 'Country',
            },
          },
        },
        view: {
          title: 'Campaign View',
          reports: 'Documents',
          statusBadge: {
            campaignCreated: 'Campaign Created',
            pendingReview: 'Pending Review',
            ready: 'Ready',
            delivering: 'Delivering',
            completed: 'Completed',
            actionRequired: 'Action Required',
          },
          columns: {
            channel: 'Channel',
            masterVisual: 'Master Visual',
            productImage: 'Product Image',
            brandLogo: 'Brand Logo',
            mainImage: 'Main Image',
            header: 'Header',
            body: 'Body',
            advertiserDisplayName: 'Advertiser Display Name',
            callToAction: 'Call to Action',
            landingPage: 'Landing Page',
            impressionTracker: 'Impression Tracker',
          },
          fields: {
            campaign: 'Campaign:',
            advertiser: 'Advertiser:',
            requestorEmail: 'Requestor email:',
            vertical: 'Vertical:',
            target: 'Country:',
            duration: 'Duration:',
            package: 'Package type:',
            platforms: 'Platforms:',
            categories: 'Categories:',
            status: 'Status',
            packageContents: 'Package Contents',
            document: {
              title: 'Document',
              name: 'Document name',
              save: 'Save',
              inputLabel: 'Upload Document',
              addReport: 'Upload Report',
              documents: 'Documents',
              errors: {
                fileSize: 'Max size is',
                required: 'File is required',
                requiredName: 'Name is required',
                uniqueName: 'Name is unique',
              },
            },
            updatedBy: 'Updated by:',
          },
          confirmation: {
            label: 'Delete',
            confirmationButtonLabel: 'Yes',
            confirmationDescription:
              'Are you sure you want to delete this document?',
            confirmationTitle: 'Delete document',
            declineButtonLabel: 'No',
          },
          buttons: {
            update: 'Update Campaign Status',
            open: 'Open',
          },
          notifications: {
            success: 'Campaign successfully updated.',
            error: 'Something went wrong.',
            upload: {
              success: 'Document successfully sent.',
              error: 'Document was not sent.',
            },
          },
        },
        fileInputs: {
          upload: {
            finished: 'Upload finished',
            failed: 'Upload failed',
          },
          retry: 'Retry?',
        },
        media: {
          info: {
            title: 'Creative best practices',
          },
          content: {
            title: 'Allowed file dimensions',
            width: 'width',
            height: 'height',
          },
          creativeDimensions: 'Creative dimensions',
        },
        notifications: {
          success:
            'A successful campaign was created. You can see the campaign below.',
          success_edit: 'Campaign edited successfully.',
          errors: {
            creation: 'Error creating Campaign',
            edit: 'Error editing Campaign',
            validation: 'Validation failed',
          },
        },
        reports: {
          details: {
            selectedItems: 'Items: ',
            exportButton: 'Download All',
          },
        },
        status: {
          all: 'All',
          campaignCreated: 'Campaign Created',
          pendingReview: 'Pending Review',
          ready: 'Ready',
          delivering: 'Delivering',
          completed: 'Completed',
          actionRequired: 'Action Required',
          processing: 'Processing',
          active: 'Active',
          paused: 'Paused',
          error: 'Error',
          accountOutOfBudget: 'Account out of budget',
          ended: 'Ended',
          pendingApproval: 'Pending Approval',
          inactive: 'inactive',
          targetAchieved: 'Target Achieved',
          rejected: 'Rejected',
          closed: 'Closed',
        },
      },
      sponsoredProducts: { ...sponsoredProduct },
      offlineAdvertising: {
        creation: {
          navButtons: {
            createCampaign: 'View Jumia offers',
            previous: 'PREVIOUS',
            bidNow: 'Bid Now!',
            CreateTier1Event: 'View Tier 1 Events',
          },
          bidNowModal: {
            title: 'Add your bid',
            labels: {
              bid: 'Bid',
              brandName: 'Brand Name',
              email: 'Email',
              phoneNumber: 'Phone Number',
            },
            placeholders: {
              bid: 'Enter your bid',
              brandName: 'Enter your brand name',
              email: 'Enter your email',
              phoneNumber: 'Enter your phone number',
            },
            submit: 'Submit',
            cancel: 'Cancel',
          },
          bidMoreModal: {
            title: 'Increase your chance by bidding more',
            label: 'Would you like to bid more on other campaigns?',
            bidMore: 'Bid More',
            cancel: 'Cancel',
          },
          successBidModal: {
            label: 'Bid submitted successfully!',
          },
          campaignName: 'Name',
          months: 'Months',
          eventType: 'Event Type',
          target: 'Country',
          selectDates: 'Select the offline advertising duration',
          startDate: 'Start date',
          endDate: 'End date',
          selectBudget: 'Select your budget',
          budget: 'Budget',
          budgetTypes: 'Budget Types',
          dailyLimit: 'Daily limit',
          bidding: 'Bidding',
          biddingStrategy: 'Bid strategy',
          maxCPM: 'Max CPM',
          bidType: 'Bid Type',
          selectProducts: 'Select offline advertising',
          productsAvailable: 'products available',
          package: {
            title: 'Offers available -',
            packageDetails: 'Package Details -',
            subtitle:
              ' (All bids required by the 15th of the month before execution. Example: November offers need to be submitted by 15th of October)',
            details: {
              benchmarkPrice: 'Benchmark Price: ',
              dimensions: 'Dimensions: ',
              id: 'ID: ',
              inventoryDetail: 'Inventory Detail/location: ',
              tier1EventsInventoryDetail: 'Package Details: ',
            },
            inputs: {
              title: 'Enter Your Bid:',
              offer: 'Your Bid',
              brand: 'Your Brand',
              email: 'Your E-mail',
              phone: 'Your Phone Number',
            },
            topBids: {
              title: 'Top Bids',
            },
          },
          notifications: {
            success: 'Offer submitted',
          },
          errors: {
            defaultPackage: 'Error No default Package',
            saving: 'The offer was not able to be saved',
            offers: 'No offers where found',
            minOffer: 'Bid offer must be greater than 0',
            creation: 'Error Saving the Data',
            number: 'Use only numbers please',
            phone: 'Use a valid phone number',
            phoneRequired: 'Phone number is required',
            email: 'Use a valid Email',
            required: 'Field is required',
            atLeastOne: 'At least one is required',
            name: {
              maxChars: 'Name contains more than 50 characters',
              invalidChars:
                'Name contains invalid characters, only letters, numbers and "_" are allowed',
            },
            startDate: {
              pastDate: 'Start date cannot occur in the past',
            },
            endDate: {
              beforeStartDate: 'End date cannot occurs before Start date',
              oneYearStartDate: 'End date cannot occur 1 year after Start date',
            },
            budget: {
              invalid: 'Invalid value',
              invalidTarget: 'Country does not exist',
              min: 'Minimum value is {{minBudget}}',
              dailyLimit: "Daily limit can't exceed budget",
            },
            bid: {
              invalid: 'Invalid value',
              invalidTarget: 'Country not exists',
              defaultBid: "Max CPM can't be lower than {{minBudget}}",
              min: "Max CPM can't exceed current budget",
              dailyLimit: "Max CPM can't exceed daily limit",
            },
          },
        },
        list: {
          title: 'My Current Bids',
          searchHint: 'Filter my bids',
          searchHintBO: 'Filter my bids or offer id',
          searchField: 'Type',
          searchFields: {
            id: 'ID',
            name: 'Name',
          },
          statusHover: {
            pendingReview:
              'One of our campaign specialists is reviewing your offer, this process usually takes between 1 and 3 business days.',
            review: 'Your offer is ready to be launched on the start date.',
            actionRequired:
              'One of our campaign specialists has reached out to you over email, some information might be missing.',
            completed: 'Your offer has ended.',
            delivering: 'Your offer is now live.',
            ready: 'Your offer is ready to be launched on the start date.',
          },
          columnLabels: {
            id: 'ID',
            offerId: 'Offer Id',
            reference: 'Reference',
            name: 'Name',
            target: 'Country',
            status: 'Status',
            creation_date: 'Creation Date',
            month: 'Month',
            oflnBrand: 'Brand',
            oflnEmail: 'Email',
            oflnBid: 'Bid',
            oflnPhoneNumber: 'Phone Number',
            eventType: 'Event Type',
          },
          filter: {
            title: 'Filters',
            reset: 'Reset',
            apply: 'Apply',
            items: {
              offerID: 'Offer ID',
              advertiser: 'Advertiser',
              target: 'Country',
              event_offline: 'Month',
              event_tier1: 'Event',
            },
          },
          quickFilter: {
            title: 'Filters',
            status: 'STATUS:',
            target: 'TARGET:',
          },
          noCampaigns: {
            title: 'No Advertisements Yet',
            description:
              "You don't have any advertisements yet. Create your first offline advertisement here",
            noCampaigns: 'No documents yet',
          },
          export: 'Export',
          campaignInfoPopup: {
            title: 'Campaign Settings',
            fields: {
              name: 'Campaign name:',
              target: 'Country:',
              location: 'Location:',
              dimensions: 'Dimensions:',
              benchmarkPrice: 'Benchmark price:',
              offer: 'Offer:',
              offerId: 'Offer Id:',
              brand: 'Brand:',
              email: 'Email:',
              phoneNumber: 'Phone number:',
              image: 'Image:',
              status: 'Status:',
            },
            buttons: {
              updateStatus: 'Update Campaign Status',
            },
            notifications: {
              success: 'Campaign successfully updated.',
              error: 'Something went wrong.',
              upload: {
                success: 'Document successfully sent.',
              },
            },
          },
        },
        offersList: {
          title: 'My Current Bids',
          noResults: {
            title: 'No Results',
            description: 'Your search has not returned any results',
          },
          searchBySku: 'Search a product by Sku',
          searchByTitle: 'Search a product by Title',
          columnLabels: {
            product: '',
            name: 'Name',
            image: '',
          },
        },
        noResult: {
          title: 'Welcome',
          addBtn: 'Start Advertising',
          boTitle: 'No campaigns yet',
          description: 'It seems there is no any campaigns yet',
        },
        view: {
          addProduct: {
            title: 'Add a Product',
            notifications: {
              addSuccess: 'Product added successfully',
              addError: 'Failed to add Product',
            },
            fileUpload: {
              title: 'File Upload',
              mainText: {
                firstSentence: 'Please chose the file you would like to upload',
                secondSentence: ' The supported extension is: CSV;',
                dinamicSecondSentence: ' The supported extensions are: ',
                thirdSentence: ` The file should have at least one column, named "SKU", containing the SKUs;`,
                forthSentence: ` Separate one SKU per row;`,
                downloadSentence: 'To download an example, click ',
                here: 'here',
              },
              fileUploader: {
                label: 'Upload File',
                validation: {
                  fileSize: `The max size you can upload is`,
                  required: 'File is required for upload',
                },
                notifications: {
                  downloadSample: {
                    error: 'The file was not downloaded',
                    success: 'Your download will start soon',
                    title: 'SKUListSample.csv',
                  },
                  upload: {
                    success: 'SKUs Added Successfully',
                    skusInfoEmpty:
                      'Some of the uploaded SKUs in the file are empty',
                    skusInfoInvalid:
                      "Some of the SKU's that you uploaded will not be included in the campaign, as they are not available:",
                    skusInfoRetry:
                      'You can verify if you typed the right SKU and try again.',
                    error: 'This time it was not possible to upload your file',
                  },
                },
              },
            },
          },
          reports: 'Documents',
          notifications: {
            success: 'Campaign successfully updated.',
            error: 'Something went wrong.',
            uploadReport: 'Report successfully uploaded ',
          },
          buttons: {
            update: 'Update Campaign Status',
            open: 'Open',
          },
          statusBadge: {
            campaignCreated: 'Campaign Created',
            pendingReview: 'Pending Review',
            ready: 'Ready',
            delivering: 'Delivering',
            completed: 'Completed',
            actionRequired: 'Action Required',
          },
        },
        fields: {
          document: {
            title: 'Document',
            name: 'Document name',
            save: 'Save',
            inputLabel: 'Upload Document',
            addReport: 'Upload Report',
            documents: 'Documents',
            errors: {
              fileSize: 'Max size is',
              required: 'File is required',
              requiredName: 'Name is required',
              uniqueName: 'Name is unique',
            },
          },
        },
      },
      documents: {
        details: {
          view: 'view',
          notAvailable: 'No documents available',
          selectedItems: 'Selected Items: ',
          exportButton: 'Export',
        },
        inputs: {
          file: {
            dragFiles: 'Drag files or click',
            upload: 'to upload',
            here: 'here',
            selectedFile: 'file selected',
            uploadFinished: 'Your file is being uploaded',
          },
        },
        noResults: {
          title: 'No Results Yet',
          description:
            'You do not hav any reports. Please check it again in a few days',
        },
        reportsPopup: {
          title: 'Download reports',
          subtitle:
            'This includes the proof of execution of your selected campaign',
        },
      },

      targets: {
        ci: 'Ivory Coast',
        dz: 'Algeria',
        eg: 'Egypt',
        gh: 'Ghana',
        ke: 'Kenya',
        ma: 'Morocco',
        ng: 'Nigeria',
        sn: 'Senegal',
        tn: 'Tunisia',
        ug: 'Uganda',
        za: 'South Africa',
        zz: 'Zando',
      },
      months: {
        jan: 'January',
        feb: 'February',
        mar: 'March',
        apr: 'April',
        may: 'May',
        jun: 'June',
        jul: 'July',
        aug: 'August',
        sep: 'September',
        oct: 'October',
        nov: 'November',
        dec: 'December',
      },
      errors: {
        notFound404: '404 NotFound',
        generic: 'Something went wrong.',
        defaultPackage: 'Error No default Package',
        validation: {
          fieldRequired: 'Field is required',
        },
      },
      advertisers: {
        list: {
          title: 'Advertisers list',
          search: 'Search advertisers',
          add: 'Add Advertiser',
          export: 'export',
          errors: {
            download: 'Failed to download the file',
          },
          actions: {
            delete: 'Delete User',
            confirm: 'Confirm',
            decline: 'Decline',
            confirmationTitle: 'Delete this user',
            confirmationDescription:
              'By accepting the user will be permanently deleted',
            view: 'View Advertiser',
            edit: 'Edit Advertiser',
            deleteSuccess: 'Advertiser deleted successfully',
            deleteFail: 'Can not delete an advertiser with created campaigns',
            deactivate: {
              tooltip: 'Deactivate User',
              confirmationTitle: 'Deactivate User',
              confirmationDescription:
                'Are you sure you want to deactivate this user?',
              successMessage: 'User successfully deactivated.',
              errorMessage: 'It was not possible to deactivate the user.',
            },
            confirmationButton: 'Yes',
            declineButton: 'No',
          },
          columns: {
            id: 'Advertiser ID',
            name: 'Advertiser Name',
            target: 'Country',
            totalCampaignCount: 'Total of campaigns created',
            entityType: 'Entity type',
            status: 'Status',
            actions: 'Action',
          },
          filter: {
            items: {
              type: 'Advertiser Type',
              target: 'Country',
            },
            search: {
              label: 'Search advertisers by name or id',
              name: 'Advertiser name',
              id: 'Advertiser id',
            },
          },
        },
        view: {
          title: 'Advertiser User Management',
          name: 'Advertiser Name:',
          id: 'Advertiser ID:',
          type: 'Advertiser Type:',
          targetCode: 'Target:',
          brands: 'Brands:',
          sellerAdvertiserId: 'Advertiser ID (BOB Seller ID):',
          vendorAdvertiserId: 'Advertiser ID (Vendor ID):',
          bobSellerIds: 'BOB Seller IDs:',
          add: 'Add User',
          list: {
            columns: {
              id: 'User ID',
              email: 'User email',
              createdBy: 'Created by',
              createdAt: 'Creation Date',
              actions: 'Actions',
            },
            actions: {
              deactivate: 'Deactivate User',
            },
          },
          userCreation: {
            title: 'New Advertiser User',
            add: 'Add User',
            save: 'Save',
            saving: 'Saving...',
            cancel: 'Cancel',
            fields: {
              email: 'User email',
              accessType: 'Access Type',
              errors: {
                required: 'Field is required',
                email: 'Invalid email',
              },
            },
            success: 'User created successfully.',
            error: 'It was not possible to create the user',
          },
        },
        creation: {
          title: 'New Advertiser',
          editTitle: 'Edit Advertiser',
          fields: {
            name: 'Advertiser Name',
            type: 'Advertiser Type',
            onlySellers: 'Only Sellers',
            brand: 'Brand',
            brandOptional: 'Brand (optional)',
            brandHelperText:
              "For adding new brands, make sure that the brand is associated with the selected BOB Seller ID or else it won't be possible to add the new brand",
            sellerAdvertiserId: 'Advertiser ID (BOB Seller ID)',
            vendorAdvertiserId: 'Advertiser ID (Vendor ID)',
            sellerIDOptional: 'BOB Seller IDs (optional)',
            sellerID: 'BOB Seller IDs ',
            save: 'Save',
            saving: 'Saving...',
          },
          errors: {
            generic: 'Error creating user',
            required: 'Field is required',
            maxChars: 'Field contains more than 50 characters',
            alphaNumeric: 'Must be alphanumeric',
            specialChars:
              'Field contains invalid characters, only letters, numbers, spaces, " \' ", "-", and "_" are allowed',
            adIdError: 'Advertiser ID must be a number',
            brandsSellersValidation:
              'Please select at least one brand or seller',
          },
          success: 'Advertiser created successfully.',
          editSuccess: 'Advertiser edited successfully.',
          types: {
            '3RD_PARTY': '3rd Party',
            SELLER: 'Seller',
            KEY_ACCOUNT_SELLER: 'Key Account Seller',
            ADVERTISING_AGENCY_FOR_SELLERS_BRANDS:
              'Advertising Agency for Sellers/Brands',
            ADVERTISING_AGENCY_FOR_3RD_PARTY:
              'Advertising Agency for 3rd Party',
            RETAIL_BRANDS: 'Retail Brands',
          },
        },
        noResults: {
          title: 'No Advertisers Yet',
          description:
            "You don't have any advertisers yet. Create your first one here",
        },
      },
      insights: {
        title: 'Insights',
        firstSentence: 'Select the insight type you would like to upload',
        form: {
          campaignId: 'Campaign ID',
          campaignName: 'Campaign Name',
          reportName: 'Report Name',
          type: 'Documentation Type',
          insightType: 'Insight type',
          stores: 'Stores',
          buttons: {
            upload: 'Upload',
          },
          typeSelect: {
            ecomData: 'Ecommerce Data',
            studiesPractice: 'Case Studies / Best Practices',
            other: 'Other',
            jumiaNews: 'Jumia News',
            jumiaInsights: 'General Jumia Data Insights',
            jumiaSearchDara: 'Jumia Search Data',
            channelDocumentation: 'Channel Documentation',
            generalInsights: 'General Insights',
            nativeDisplay: 'Native Display',
            paidOnlineMarketing: 'Paid Online Marketing',
            pushNotifications: 'Push Notifications',
            newsletterAdvertising: 'Newsletter Advertising',
            sponsoredProducts: 'Sponsored Products',
            sponsoredBrands: 'Sponsored Brands',
            organicSocialMedia: 'Organic Social Media',
            jumiaSearchData: 'Jumia Search Data',
          },
        },
        list: {
          tableHeader: {
            id: 'Campaign ID',
            campaignName: 'Campaign name',
            reportName: 'Report name',
            type: 'Type',
            insightType: 'Insight type',
            uploadDate: 'Upload date',
            actions: 'Actions',
          },
          noResults: {
            title: 'No Results yet',
            descriptionBo:
              'Please upload an insight, so it can be presented to the advertiser',
            descriptionFo:
              'Your store has not returned any Insights. Please check it again in a few days',
          },
          actions: {
            downloadLabel: 'Download',
            deleteLabel: 'Delete',
            confirmationTitle: 'Delete Insight',
            confirmationDescription:
              'Are you sure you want to delete this insight?',
            confirm: 'Yes',
            decline: 'No',
            bulkActions: {
              delete: 'Delete Selected',
              download: 'Download Selected',
              selectedItems: 'Selected insights:',
              confirmationTitle: 'Delete Insights',
              confirmationDescription:
                'Are you sure you want to delete this insights?',
              confirm: 'Yes',
              decline: 'No',
            },
          },
          quickFilter: {
            title: 'Filters',
            type: 'Type: ',
            channel: 'Insight Type: ',
            types: {
              all: 'All Types',
              channelDocumentation: 'Channel Documentation',
              generalInsights: 'General Insights',
            },
            channels: {
              all: 'All Types',
              ecomData: 'Ecommerce Data',
              studiesPractice: 'Case Studies / Best Practices',
              other: 'Other',
              jumiaNews: 'Jumia News',
              jumiaInsights: 'General Jumia Data Insights',
              nativeDisplay: 'Native Display',
              paidOnlineMarketing: 'Paid Online Marketing',
              pushNotifications: 'Push Notifications',
              newsletterAdvertising: 'Newsletter Advertising',
              sponsoredProducts: 'Sponsored Products',
              sponsoredBrands: 'Sponsored Brands',
              organicSocialMedia: 'Organic Social Media',
              jumiaSearchData: 'Jumia Search Data',
            },
          },
          columns: {
            channel: {
              NATIVE_DISPLAY: 'Native Display',
              PAID_ONLINE_MARKETING: 'Paid Online Marketing',
              PUSH_NOTIFICATIONS: 'Push Notifications',
              NEWSLETTER_ADVERTISING: 'Newsletter Advertising',
              SPONSORED_PRODUCTS: 'Sponsored Products',
              SPONSORED_BRANDS: 'Sponsored Brands',
              ORGANIC_SOCIAL_MEDIA: 'Organic Social Media',
              CASE_STUDIES_BEST_PRACTICES: 'Case studies/ best practices',
              GENERAL_JUMIA_DATA_INSIGHTS: 'General Jumia Data Insights',
              JUMIA_SEARCH_DATA: 'Jumia search Data',
              ECOMMERCE_DATA: 'Ecommerce Data',
              JUMIA_NEWS: 'Jumia news',
              OTHER: 'Other',
            },
            type: {
              GENERAL_INSIGHTS: 'General Insights',
              CHANNEL_DOCUMENTATION: 'Channel Documentation',
            },
          },
          notifications: {
            success: 'Insight successfully deleted.',
            error:
              'It was not possible to delete the insight, somthing went wrong.',
          },
        },
        notifications: {
          success: 'Document Uploaded Successfully',
          fail: 'The Documentation failed to be added',
        },
      },
      finance: {
        header: {
          tabs: {
            labels: {
              sponsoredProducts: 'Sponsored Products',
            },
            sponsoredProductsTab: {
              lastPayment: 'Your last payment:',
              accountBalance: 'Your account balance:',
              topUp: 'Top Up',
              nextPayment: {
                nextPayments: 'Your next payment:',
                automaticPayment: 'Start Automatic Payment',
                title: 'Your next payment:',
                startButton: 'Start automatic payment',
                stopButton: 'Stop automatic payment',
                amounts: {
                  failed:
                    'It was not possible to fetch the amounts. Try again later.',
                },
                popup: {
                  title: 'Start Automatic Payment',
                  descriptionMsg1:
                    'By starting automatic payment your products will always be promoted.',
                  descriptionMsg2:
                    'Every time that your accounts balance runs out of budget, it will be automatically renewed.',
                  selectInputLabel: 'Amount',
                  submitButtonLabel: 'Submit',
                  notifications: {
                    success: 'Automatic payment successfully started',
                    error:
                      'It was not possible to start automatic payment. Somthing went wrong.',
                  },
                  stopAutomaticPayment: {
                    title: 'Stop Automatic Payment',
                    description:
                      'If you stop the Automatic Payment, we will stop promoting your products when your budget runs out.',
                    confirmationMsg: 'Are you sure you want to stop ?',
                    confirm: 'Yes, I want to stop',
                    decline: 'No, Keep Automatic Payment',
                    notifications: {
                      success: 'Automatic payment successfully stopped',
                      error:
                        'It was not possible to stop automatic payment, somthing went wrong.',
                    },
                  },
                },
              },
              list: {
                columns: {
                  date: 'Date',
                  description: 'Description',
                  paymentType: 'Payment Type',
                  debit: 'Debit',
                  credit: 'Credit',
                  MANUAL: 'Manual',
                  AUTOMATIC: 'Automatic',
                  DAILY_COST: 'Daily Cost',
                  BONUS: 'Bonus',
                },
                description: {
                  'Payment - manual': 'Payment - manual',
                  'billingDescription.impressions_on_sponsored_products':
                    'Impressions on Sponsored Products',
                },
                noResults: {
                  title: 'No Results yet',
                  description: 'You have not made any payments yet',
                },
                notifications: {
                  success: 'Payment successfully made',
                  error:
                    'It was not possible to get the payments, Somthing went wrong.',
                },
              },
            },
          },
        },
        topUp: {
          fields: {
            title: 'Top up account',
            submitButton: 'Submit',
            amount: 'Amount',
          },
          messages: {
            success: 'Top up was applied successfully',
          },
          errors: {
            amounts: 'Error fetching amounts',
            topUp: 'Error applying top up to acccount',
          },
        },
        filter: {
          month: 'Month',
          paymentTypes: {
            title: 'Payment Types ---',
            types: {
              TOPUP: 'Top Up',
              BONUS: 'Bonus',
              AUTOMATIC_PAYMENT: 'Automatic Payment',
              MANUAL_PAYMENT_BY_SELLER: 'Manual Payment',
              FREE_CREDIT: 'Free Credit',
              DAILY_COST: 'Daily Cost',
              REFUND: 'Refund',
            },
          },
        },
      },
      filters: {
        noResults: {
          title: 'No Results',
          description: 'No results match your filters',
        },
      },
      sponsoredBrands: { ...sponsoredBrands },
      notifications: {
        uploadSuccess: 'Successfully uploaded',
      },
      campaignAudit: {
        title: 'Campaign Audit Info',
        list: {
          columnLabels: {
            id: 'Id',
            name: 'Name',
            userAction: 'User action',
            status: 'Status',
            updatedBy: 'Updated by',
            updatedAt: 'Updated at',
          },
        },
      },
      settings: {
        list: {
          actions: {
            activate: {
              title: 'Activate',
              tooltip: 'Activate Feature',
              confirmationTitle: 'Activate Feature',
              confirmationDescription:
                'Are you sure you want to activate this feature?',
              successMessage: 'Feature successfully activated',
              errorMessage: 'It was not possible to activate the feature',
            },
            disable: {
              title: 'Disable',
              tooltip: 'Disable Feature',
              confirmationTitle: 'Disable Feature',
              confirmationDescription:
                'Are you sure you want to disable this feature?',
              successMessage: 'Feature successfully disabled',
              errorMessage: 'It was not possible to disable the feature',
            },
            confirmationButton: 'Yes',
            declineButton: 'No',
          },
        },
      },
      displayCampaigns: {
        settings: {
          cpmDollar: 'Current CPM',
          cpmCurrency: 'Current CPM',
          labels: {
            cpmDollar: 'Change CPM',
            cpmCurrency: 'Change CPM',
          },
          errors: {
            failFetchTarget: 'Something went wrong',
            cpm: {
              invalid: 'Use only Numbers that are positive',
            },
          },
        },
        view: {
          headline: 'Display Campaign',
          estimatedCost: 'Estimated Cost',
          addProduct: {
            fileUpload: {
              fileUploader: {
                validation: {
                  fileSize: 'The file size must be less than:',
                  required: 'A file is required',
                },
              },
            },
          },
          reportsTable: {
            columns: {
              fileName: 'File Name',
              uploadDate: 'Date Uploaded',
              actions: 'Actions',
              download: 'Download',
            },
          },
          reports: {
            title: 'Documents',
            upload: 'Upload Report',
            download: 'Download Report',
            delete: 'Delete Report',
            downloadSuccess: 'Report downloaded successfully',
            deleteSuccess: 'Report deleted successfully',
            deleteFail:
              'Can not delete. Something went wrong. Please try again later',
            tableColumns: {
              name: 'Name',
              actions: 'Actions',
            },
            reportUpload: {
              form: {
                title: 'Upload Report',
                documentName: 'Document Name',
                submit: 'Upload',
              },
              validations: {
                documentName: 'Please enter a document name',
                file: 'file is required',
                failedDownload:
                  'Can not able to download. Something went wrong. Please try again later',
                success: 'Report uploaded successfully',
              },
            },
          },
        },
        edit: {
          success: 'Successfully updated campaign',
          errors: {
            generic: 'Error while updating campaign',
          },
        },
        create: {
          steps: {
            targeting: {
              formFields: {
                targetingType: {
                  label: 'Targeting Type',
                  automaticHelperText:
                    'The best targeting options will be selected for your campaign',
                  values: {
                    automatic: 'Automatic',
                    custom: 'Custom',
                  },
                },
                jumiaPlatforms: {
                  label: 'Jumia Platforms',
                  values: {
                    food: 'Food',
                    pay: 'Pay',
                    eCommerce: 'E-Commerce',
                  },
                },
                brands: {
                  label: 'Brands',
                  values: {
                    relevant: 'Relevant Brands',
                    competitors: 'Competitors',
                  },
                },
                additionalDetails: {
                  label: 'Additional Details',
                  placeholder:
                    'Let us know any helpful information to make the targeting relevant',
                },
              },
            },
            actions: {
              next: 'Next',
              back: 'Back',
              submit: 'Submit',
              edit: 'Edit',
            },
            titles: {
              configuration: 'Configuration',
              targeting: 'Targeting',
              creatives: 'Creatives',
              summary: 'Summary',
            },
            summary: {
              headline: "Here's your campaign summary",
              fields: {
                campaignName: 'Campaign Name',
                campaignStatus: 'Campaign Status',
                startDate: 'Start Date',
                endDate: 'End Date',
                budget: 'Budget',
                targetImpressions: 'Target Impressions',
                jumiaPlatforms: 'Jumia Platforms',
                categories: 'Categories',
                additionalDetails: 'Additional Details',
                landingPageUrl: 'Landing Page URL',
                status: 'Status',
                confirm:
                  'I confirm the accuracy of the information provided and the intentions of the legal entity associated with this account to proceed and settle the service request.',
              },
            },
            configuration: {
              formFields: {
                campaignName: {
                  label: 'Campaign Name',
                  placeholder: 'Enter campaign name',
                  validations: {
                    required: 'Campaign name is required',
                  },
                },
                campaignDuration: {
                  label: 'Campaign Duration',
                  startDate: {
                    label: 'Start Date',
                    validations: {
                      required: 'Start date is required',
                      min: 'start date can not be in the past.',
                    },
                  },
                  endDate: {
                    label: 'End Date',
                    validations: {
                      required: 'End date is required',
                      afterEndDate: 'End date must be after start date',
                      oneYearStartDate:
                        'End date cannot occur 1 year after Start date',
                    },
                  },
                },
                budget: {
                  label: 'Budget',
                  placeholder: 'Enter budget',
                  validations: {
                    required: 'Budget is required',
                    onlyNumbers: 'Budget must be a number',
                    min: 'The budget must match at least {{min}} impressions',
                  },
                },
                impressions: {
                  label: 'Impressions',
                  placeholder: 'Enter impressions',
                  validations: {
                    required: 'Impressions is required',
                    onlyNumbers: 'Budget must be a number',
                    min: 'Target impressions can not be less than {{min}}',
                  },
                },
                cpm: 'CPM',
              },
            },
            creatives: {
              title: 'Upload Campaign Creatives',
              validations: {
                actionRequired: 'Action required',
                maxBanners: 'You Reached the maximum number of banners',
                required: 'this field is required',
                dimensions: 'Banner dimensions must be {{width}}x{{height}} PX',
                urlLandingPage: 'This link must be directed on Jumia',
              },
              placements: {
                addDesktopBanner: 'Add Desktop Banner',
                addMobileBanner: 'Add Mobile Banner',
                desktop: {
                  title: 'Desktop Banner',
                  addBanner: 'Add Banner',
                  addOtherDesktopBanner: 'Add another desktop banner',
                  addOtherMobilBanner: 'Add another mobile banner',
                  inputs: {
                    textInputLabel: 'Landing Page Link',
                    textInputPlaceholder: 'Enter landing page link',
                    helperText: 'This link must be directed on jumia',
                  },
                },
              },
            },
          },
          success: 'Successfully created a campaign',
          errors: {
            generic: 'Error while creating a campaign',
          },
        },
        welcome: {
          banner: {
            title: 'Boost your visibility with Display Advertising',
            description:
              'Display ads help you to drive awareness for your brand, targeting people in relevant categories',
            callToAction: 'Create your first campaign now!',
          },
          features: {
            title: 'Place your brand in front of users in a shopping mindset',
            subtitle:
              'With Display Advertising, you will be able to book your own campaign in no time',
            list: [
              {
                title: 'Create Brand Awareness',
                description:
                  'Ensure that your brand stands out from the crowd and is top of mind with Jumia shoppers',
                icon: 'Campaign',
              },
              {
                title: 'Reach Target Audience',
                description:
                  'Reach users in relevant categories already likely to purchase a product similar to yours',
                icon: 'BoltOutlined',
              },
              {
                title: 'Drive Purchase',
                description:
                  'inspire shoppers throughout their purchase journey to make them purchase your products',
                icon: 'Cart',
              },
              {
                title: 'Maintain brand loyalty',
                description:
                  'By creating constant awareness, shoppers are regularly updated about your product range or promotion',
                icon: 'AccountCircleOutlined',
              },
            ],
          },
        },
        list: {
          createButtonLabel: 'Create Campaign',
          tableColumns: {
            campaignName: 'Campaign Name',
            status: 'Status',
            startDate: 'Start Date',
            endDate: 'End Date',
            creatives: 'Creatives',
            actions: 'Actions',
            edit: 'Edit',
            view: 'Review',
          },
          quickFilters: {
            title: 'Filters',
            status: 'Status',
            target: 'Target',
            searchFO: 'Search by name',
            searchBO: 'Search by name or id',
            allFilters: 'All',
          },
          noResults: {
            title: 'No Campaigns Yet',
            description:
              "You don't have any campaigns yet. Create your first campaign now",
            boDescription: 'There is no campaigns yet',
          },
        },
        filters: {
          uploadReports: 'Upload Reports',
        },
        review: {
          success: 'Successfully updated campaign status',
          error: 'Error while updating campaign status',
        },
      },
      products: {
        list: {
          search: {
            select: {
              sku: 'SKU',
              title: 'Title',
              brand: 'Brand',
            },
            placeholder: {
              sku: 'Search by SKU',
              title: 'Search by Title',
              brand: 'Search by Brand',
            },
          },
        },
      },
      other: {
        redirectText: 'Redirecting you to the ads platform..... ',
      },
    },
  },
}
