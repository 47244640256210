import axios from 'axios'
import { CAMPAIGN_TYPE, STATUS_TYPES } from '~/constants/campaigns'
import { PERMISSIONS } from '~/constants/permissions'
import { api } from '~/services/api'
import { mapMediaRequest } from '~/services/cosmos/mapper'
import {
  mapCampaignListRequestParams,
  mapFoCampaignListResponse,
  mapCampaignListResponseBO,
  mapCampaignDetailResponse,
  mapCampaignStatusRequestParams,
} from '~/services/selfservice/campaigns/mapper'
import {
  Campaign,
  CampaignCreateRequest,
  CampaignList,
  CampaignListRequestParams,
  CampaignStatus,
  CampaignStatusList,
  CampaignStatusUpdateParams,
  CampaignDocumentsUpdateParams,
  CampaignStatusRequestParams,
} from '~/services/selfservice/campaigns/types'
import hasPermissions from '~/utils/hasPermission'

export const endpoints = {
  getCampaigns: 'campaigns',
  getOfflineCampaigns: 'campaigns/offline-advertising',
  getCampaign: (id: number) => `campaigns/${id}`,
  getCampaignStatusList: 'campaigns/status',
  getCPM: 'campaigns/sponsored-brands/cpm',
  getCampaignStatusById: (id: string) => `/campaigns/status/${id}`,
  getActiveCampaignsCount: 'campaigns/count',
  create: () => `campaigns`,
  updateStatus: (id: string) => `campaigns/${id}/status`,
  updateDocument: (id: string) => `campaigns/${id}/document`,
  uploadCreative: 'campaigns/sponsored-brands/upload-creative',
}

// Check if the user has exact the permissions to return the exact mapper
const FO_PERMISSIONS = hasPermissions([PERMISSIONS.FO_MAIN_CAMPAIGN_ACCESS])
const checkResponsePermissions = (res: any, isReports: boolean) => {
  if (FO_PERMISSIONS) return mapFoCampaignListResponse(res, isReports)
  return mapCampaignListResponseBO(res)
}

export const getActiveCampaignsCount = async (
  ownerId: string
): Promise<number> => {
  const response = await api.selfservice.get(
    endpoints.getActiveCampaignsCount,
    {
      params: { owner_id: ownerId },
    }
  )
  return response.data.data
}

export const fetchCampaignList = async (
  params: CampaignListRequestParams,
  isReports = false,
  offlineType: string | undefined = undefined
): Promise<CampaignList> => {
  const parsedParams = mapCampaignListRequestParams(params, offlineType)
  const response = await api.selfservice.get(endpoints.getCampaigns, {
    params: parsedParams,
  })
  return checkResponsePermissions(response.data, isReports)
}

export const fetchCampaignStatusList = async (
  params?: CampaignStatusRequestParams
): Promise<CampaignStatusList> => {
  const parsedParams = mapCampaignStatusRequestParams(params)
  const response = await api.selfservice.get(endpoints.getCampaignStatusList, {
    params: parsedParams,
  })
  return response.data.data
}

export const fetchQuickFilterCampaignStatusList = async (
  campaignType?: CAMPAIGN_TYPE
): Promise<CampaignStatusList> => {
  const response = await fetchCampaignStatusList({ campaignType })

  return [{ id: 0, name: STATUS_TYPES.All }, ...response]
}

export const fetchQuickFilterSponsoredBrandStatusList = async (
  params: CampaignStatusRequestParams
): Promise<CampaignStatusList> => {
  const response = await fetchCampaignStatusList(params)
  const allOption = { id: 0, name: STATUS_TYPES.All }
  return [allOption, ...response]
}

export const fetchAllowedCampaignStatusList = async (
  campaignType?: CAMPAIGN_TYPE
): Promise<CampaignStatusList> => {
  const response = await api.selfservice.get(endpoints.getCampaignStatusList, {
    params: {
      campaign_type: campaignType,
    },
  })

  return response.data.data
}

export const fetchAllowedOfflineCampaignStatusList =
  async (): Promise<CampaignStatusList> => {
    const response = await fetchCampaignStatusList()

    const allowedStatus = [
      STATUS_TYPES.PendingReview,
      STATUS_TYPES.Delivering,
      STATUS_TYPES.Completed,
      STATUS_TYPES.ActionRequired,
    ]

    return response.filter((status: CampaignStatus) =>
      allowedStatus.includes(status.name)
    )
  }

export const fetchCampaignStatusById = async (
  id: string
): Promise<CampaignStatus> => {
  const response = await api.selfservice.get(
    endpoints.getCampaignStatusById(id)
  )

  return response.data
}

export const updateCampaignStatus = async ({
  campaignId,
  statusId,
  statusReason,
}: CampaignStatusUpdateParams): Promise<void> => {
  const payload = {
    status_id: statusId,
    status_reason: statusReason && statusReason === '' ? null : statusReason,
  }

  await api.selfservice.post(
    endpoints.updateStatus(String(campaignId)),
    payload
  )
}

export const updateCampaignDocuments = async ({
  campaignId,
  files,
}: CampaignDocumentsUpdateParams): Promise<void> => {
  const payload = {
    campaign_id: campaignId,
    files: files?.map((v) => ({
      file_id: v.fileID,
      file_url: v.fileURL,
      name: v.name,
    })),
  }

  await api.selfservice.post(
    endpoints.updateDocument(String(campaignId)),
    payload
  )
}

export const fetchCampaign = async (id: number): Promise<Campaign> => {
  const { data } = await api.selfservice.get(endpoints.getCampaign(id))
  return mapCampaignDetailResponse(data.data)
}

export const createCampaign = async (
  r: CampaignCreateRequest
): Promise<void> => {
  try {
    await api.selfservice.post(endpoints.create(), r)
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response?.data?.errors?.[0]
    } else {
      throw err
    }
  }
}

export const uploadCreative = async (creative: {
  name: string
  file: File
  setProgress: (progress: number | null) => void
}) => {
  try {
    return await api.selfservice.post(
      endpoints.uploadCreative,
      mapMediaRequest(creative.file),
      {
        headers: { 'content-type': 'multipart/form-data' },

        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percentCompleted = Math.round((loaded * 100) / total)
          creative.setProgress(percentCompleted)
        },
      }
    )
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response?.data?.errors?.[0]
    } else {
      throw err
    }
  }
}

export const fetchCPM = async (targetCode: string): Promise<number> => {
  const response = await api.selfservice.get(endpoints.getCPM, {
    params: { target_code: targetCode },
  })
  return response.data.data.value
}
