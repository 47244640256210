export const sponsoredBrands = {
  welcome: {
    banner: {
      title:
        'Create <strong>awareness and consideration </strong> for your brand while <strong>driving sales</strong> with Sponsored Brands',
      description:
        'Sponsored Brand is customizable. You can include your brand logo, headline and campaign creative along with your products to tell your brand story to all Jumia shoppers visiting the search and category pages.',
      callToAction: 'Create Your First Campaign Now!',
    },
    features: {
      title: 'Advantages of Using Sponsored Brands',
      list: [
        {
          title: 'Increase your Visibility',
          description:
            'Get an opportunity to showcase your brand on the top of the search and the category page',
          icon: 'IncreaseVisibility',
        },
        {
          title: 'Build your Brand',
          description:
            'Enhance your Brand Image with the Ability to add your Brand logo, banner and headline',
          icon: 'BuildBrand',
        },
        {
          title: 'Drive Traffic',
          description:
            'With the option to add different Landing pages, you can drive traffic to your official store, brand or product page',
          icon: 'DriveTraffic',
        },
        {
          title: 'Self Service',
          description:
            'With campaign creation on the Jumia Ads Platform, you have complete control on your campaign delivery',
          icon: 'SelfService',
        },
      ],
    },
  },
  list: {
    filter: {
      title: 'Filters',
      reset: 'Reset',
      apply: 'Apply',
      items: {
        offerID: ' OfferID',
        advertiser: 'Advertiser',
        keyword: 'Keyword',
        target: 'Country',
        event_offline: 'Month',
        category: 'Category',
        event_tier1: 'Event',
        paused: 'Paused',
        yes: 'Yes',
        no: 'No',
      },
    },
    create: 'Create Campaign',
    tableColumns: {
      id: 'ID',
      campaignName: 'Campaign name',
      status: 'Campaign (On/Off)',
      boStatus: 'Pause/Resume',
      statusReview: 'Status',
      targetImpressions: 'Target impressions',
      impressionsCompletion: 'Percentage Completion',
      country: 'Country',
      advName: 'Advertiser',
      targetingType: 'Targeting type',
      date_period: 'Start Date & End Date',
      actions: {
        label: 'Actions',
        edit: 'Edit',
        clone: 'Clone',
        delete: 'Delete',
        view: 'Review',
        auditInfo: 'Audit Info',
      },
      creationDate: 'Created Date',
      impressions: 'Impressions',
      clicks: 'Clicks',
      ctr: 'CTR',
      adSpend: 'Ad Spend',
    },
    actions: {
      pause: {
        title: 'Pause',
        confirmationButtonLabel: 'Yes',
        declineButtonLabel: 'No',
        confirmationDescription:
          'Are you sure you want to Pause this campaign?',
        confirmationTitle: 'Pause campaign',
      },
      resume: {
        title: 'Resume',
        confirmationButtonLabel: 'Yes',
        declineButtonLabel: 'No',
        confirmationDescription:
          'Are you sure you want to Resume this campaign?',
        confirmationTitle: 'Resume campaign',
      },
      activate: {
        title: 'Activate',
        tooltip: 'Activate Campaign',
        confirmationTitle: 'Activate Campaign',
        confirmationDescription:
          'Are you sure you want to activate this campaign?',
        successMessage: 'Campaign successfully activated',
        errorMessage: 'It was not possible to activate the campaign',
        resumeCampaign: 'Resume Campaign',
        pauseCampaign: 'Pause Campaign',
      },
      disable: {
        title: 'Disable',
        tooltip: 'Disable Campaign',
        confirmationTitle: 'Disable Campaign',
        confirmationDescription:
          'Are you sure you want to disable this campaign?',
        successMessage: 'Campaign successfully disabled',
        errorMessage: 'It was not possible to disable the campaign',
      },
      confirmationButton: 'Yes',
      declineButton: 'No',
    },
    searchHint: 'Search by Campaign Name, ID or Advertiser Name ',
    noResults: {
      title: 'No Campaigns Yet',
      description:
        "You don't have any campaigns yet. Create your first campaign now",
      boDescription: 'There is no campaigns yet',
    },
    notifications: {
      bulkActions: {
        success: 'Successfully updated',
        error: 'It was not possible to update the campaign, try again later',
      },
      list: {
        errorLoadingCampaigns:
          'An error occurred while loading the campaigns, please try again later',
      },
    },
    bulkActions: {
      selectedItems: 'Selected items: ',
      actionsList: {
        pause: 'Pause',
        resume: 'Resume',
        label: 'Select actions ---',
        confirmationTitle: {
          pause: 'Pause Campaigns',
          resume: 'Resume Campaigns',
        },
        confirmationDescription: {
          pause: 'Are you sure you want to pause the selected campaigns?',
          resume: 'Are you sure you want to resume the selected campaigns?',
        },
        confirm: 'Yes',
        decline: 'No',
      },
    },
  },
  create: {
    steps: {
      campaignDetails: 'Campaign Settings',
      campaignCreatives: 'Campaign Target Keywords',
      campaignSummary: 'Campaign Summary',
      languages: {
        EN: 'Create your Ad (English)',
        FR: 'Create your Ad (French)',
        ES: 'Create your Ad (Spanish)',
        AR: 'Create your Ad (Arabic)',
      },
      next: 'Continue',
      back: 'Back',
      submit: 'Create campaign',
      edit: 'Edit campaign',
      clone: 'Clone campaign',
    },
    campaignDetails: {
      formFields: {
        campaignName: {
          label: 'Campaign Name',
          validation: 'Please enter a campaign name',
          placeholder: 'Enter campaign name',
          info: 'Add a name for your campaign',
        },
        campaignDuration: {
          label: 'Campaign Duration',
          startDatePlaceholder: 'Start Date',
          startDateName: 'startDate',
          endDatePlaceholder: 'End Date',
          validations: {
            startDateValidation: 'Please enter a start date',
            endDateValidation: 'Please enter an end date',
            beforeStartDate: 'End date must be after start date',
          },
          endDateName: 'endDate',
          info: 'Select the duration for your campaign’s delivery by adding a start date and an end date.',
        },
        targetImpressions: {
          label: 'Target Impressions',
          validations: {
            required: 'Please enter a target impressions',
            min: 'Target impressions can not be less than {{num}}',
            typeError: 'Target must be a number',
            positive: 'Target should not be 0',
          },
          placeholder: 'Enter target impressions',
          info: 'Select how many impressions you aim for your campaign',
        },
        cpm: {
          label: 'CPM',
          info: 'Cost Per Mile impressions',
        },
        estimatedCost: {
          label: 'Estimated cost',
          placeholder: 'Cost=(Impressions*CPM)/1000',
        },
        targetTypes: {
          label: 'Targeting Type',
          validation: 'At least one category must be selected',
          placeholder: 'Select target type',
          helper: 'Add at least 10 keywords to your campaign',
          info:
            '<b><u>Keyword Targeting:</u></b> Choose this targeting if you want your Sponsored Brand Ad displayed when shoppers use same/similar search terms provided by you in the Keywords section. \n\n' +
            '<b><u>Category Targeting:</u></b> Choose this targeting if you want your Sponsored Brand Ad displayed when shoppers browse or search for products related to the selected categories.',
        },

        skuSelector: {
          label: 'Select your products',
          validations: {
            required: 'Please select your products',
            min: 'Please add at least 6 Products',
            max: 'You reached the maximum number of products',
            duplicated: 'This Product is already selected',
            popupDuplicated: 'Some of the products are already selected',
            popupMax: 'You reached the maximum number of products',
          },
          placeholder: 'Search by SKU, or title',
          tooltip:
            'Select the products for your campaign. Please note that you can only select a maximum of 6 products for the Sponsored Brand Ad',
          iconTitle: 'Open Product Selector',
        },
        addKeywords: {
          label: 'Target Keywords',
          validations: {
            required: 'At least 10 keywords should be added',
            max: 'You reached limit of ({{maxCount}} max) keywords. Remove existing keywords to add new ones',
            specialCharacters:
              'Only "-" or "_" or " " "word in double quotes" " are allowed as special characters',
            oneLetter: 'Must contain at least one letter',
          },
          placeholder: 'Enter your keywords',
          selectedKeywords: 'Selected Keywords',
          info: 'We recommend adding at least 30 keywords to your campaign to increase the opportunity for your ads to show.',
        },
        campaignCategory: {
          label: 'Target Categories',
          validation: 'Please select at least one category',
          placeholder: 'Select target categories',
          title: 'Category Tree',
          subtitle:
            "Select the product's category and click on the 'select category' button",
          buttonLabel: 'Select category',
          dublicated: 'This category is already selected',
          selecttedCategory: 'Selected Category',
          emptyCategory: 'Please select category',
          info: 'Add the categories for which you want your Sponsored Brand Ad to be displayed',
        },
        pageType: {
          label: 'Landing Page Type',
          placeholder: 'Select campaign name',
        },
        pageUrl: {
          label: 'Landing Page URL',
          placeholder: 'Enter landing page url',
        },
        sku: {
          label: 'Select your products',
          placeholder: 'Enter page url',
          info: 'Select the products for your campaign. Please note that you can only select a maximum of 6 products for the Sponsored Brand Ad',
        },
      },
      formTitle: 'Please fill all details for your campaign creation',
      skuListTableColumns: {
        product: 'Product',
        sku: 'SKU',
        price: 'Price',
        salePrice: 'Sale Price',
        image: 'Image',
      },
      searchFields: {
        sku: 'SKU',
        title: 'Title',
        searchBySku: 'Search a product by Sku',
        searchByTitle: 'Search a product by Title',
      },
    },
    campaignCreatives: {
      formFields: {
        campaignLogo: {
          label: 'Upload Brand Logo',
          validations: {
            required: 'Please add your Brand Logo',
            dimensions: 'Minimum 300*300 pixels',
            square: 'Must be a square ratio.',
          },
          info: 'Brand Logo size 300 x 300 pixels',
        },
        title: {
          label: 'Ad Headline',
          placeholder: 'Enter your ad headline',
          validations: {
            required: 'Please add your ad headline',
            max: 'Headline can not exceed 30 characters',
          },
          info:
            'Please add the headline for your creative. It should be short and punchy and describe your brand or offer.\n' +
            'Max Character limitation: 30 characters',
        },
        pageType: {
          label: 'Landing Page Type',
          placeholder: 'Enter your landing page Type',
          validation: 'Please add your landing page type',
          info: 'Depending on your goal (awareness or sales), you can decide the type of landing page you would like your Shoppers to be redirected after they click on your Sponsored Brand Ad',
        },
        pageURL: {
          label: 'Landing Page URL',
          labelSKU: 'Product SKU',
          placeholder: 'Enter your landing page URL',
          placeholderSKU: 'Enter your product SKU',
          popup:
            '<strong> Brand Page </strong>: must contain jumia domain then "/" then your Brand<br /><spanstyle="margin-left: 20px"><i>example : https://jumia.com/{your-brand-here}</i></span><br /><strong> Official Store Page </strong>Official Store Page: must containjumia domain then "/mlp-" official store<br /><span style="margin-left: 20px"><i>example : https://jumia.com/mlp-{your-official-store-here}</i></span><br /><strong> Product list </strong>Must be product SKU<br /><spanstyle="margin-left: 20px"><i>example : NA450AC0JWPH6NAFAMZ</i></span>',
          validations: {
            required: 'Please add valid landing page URL',
            invalidBrandPage: 'Invalid brand page URL',
            invalidOfficialStoreUrl: 'Invalid official store URL',
            invalidSku: 'Invalid SKU',
          },
          info: 'This is the page users will be directed to when they click on your ad',
        },
        campaignBannerDesktop: {
          label: {
            EN: 'Upload Campaign Desktop (English) Banner',
            FR: 'Upload Campaign Desktop (French) Banner',
            ES: 'Upload Campaign Desktop (Spanish) Banner',
            AR: 'Upload Campaign Desktop (Arabic) Banner',
          },
          validations: {
            required: 'Please add your desktop campaign banner',
            dimensions: 'Dimension must be 1200 * 240 pixels',
          },
          info: {
            dimensions: 'Desktop banner size 1200 * 240',
            logo: 'Add a custom brand image that you can use to tell about your brand to the shoppers and enhance your Sponsored Brand Ad.',
          },
        },
        campaignBannerMobile: {
          label: {
            EN: 'Upload Campaign Mobile (English) Banner',
            FR: 'Upload Campaign Mobile (French) Banner',
            ES: 'Upload Campaign Mobile (Spanish) Banner',
            AR: 'Upload Campaign Mobile (Arabic) Banner',
          },
          validations: {
            required: 'Please add your mobile campaign banner',
            dimensions: 'Dimension must be 720 * 262 pixels',
          },
          info: {
            dimensions: 'Mobile banner size 720 * 262',
            logo: 'Add a custom brand image that you can use to tell about your brand to the shoppers and enhance your Sponsored Brand Ad.',
          },
        },
        errors: {
          upload: 'Error while uploading image',
        },
      },
      previewTabs: {
        sponsoredPlaceholder: 'Ad',
        headlinePlaceholder: 'Ad Headline',
        deskTop: {
          previewTitle: 'Desktop Preview',
          label: 'Desktop',
        },
        mobile: {
          previewTitle: 'Mobile Preview',
          label: 'Mobile',
        },
      },
      formTitle: 'Upload your ads',
      formSubTitle:
        'Please create your Sponsored Brand Ad by adding the logo, Ad Brand image and the headline',
      skuListTableColumns: {},
      searchFields: {},
    },
    campaignSummary: {
      title: "Here's your campaign summary",
      subTitle:
        'Review all your campaign settings and ensure that all of them are aligned with your campaign goals.',
      previewLandingPage: 'Preview landing page',
      startDate: 'Start date',
      endDate: 'End date',
      creatives: 'Creatives',
      categories: 'Categories',
      keywords: 'Keywords',
      targetImpressions: 'Target Impressions',
      skus: 'Products',
      products: 'Products',
      adTitle: 'Ad Headline',
      targetType: 'Targeting Type',
      desktopCreatives: 'Desktop Creatives',
      mobileCreatives: 'Mobile Creatives',
      campaignPreview: {
        EN: 'Preview Campaign (English)',
        FR: 'Preview Campaign (French)',
        ES: 'Preview Campaign (Spanish)',
        AR: 'Preview Campaign (Arabic)',
      },
      seeMore: 'See more',
    },
    error: {
      generic: 'Error creating the campaign',
      keywordsMin: 'Add at least 10 keywords to the campaign',
      keywordExists: 'Keyword already exists',
    },
    success: 'Campaign created successfully',
  },
  edit: {
    campaignRejected:
      'Your campaign was rejected. Please resolve the below error to resubmit your campaign:',
    success: 'Successfully updated campaign',
    errors: {
      generic: 'Error editing the campaign',
      targetImpressions: {
        min: 'Target impressions can not be less than {{num}}',
        max: 'Target impressions can not be greater than {{num}}',
      },
    },
  },
  view: {
    updateStatusButton: 'Update Campaign Status',
    formFields: {
      rejectReason: {
        label: 'Rejection reason',
      },
    },
    notifications: {
      success: 'Campaign successfully updated.',
      error: 'Something went wrong.',
    },
    reports: {
      title: 'Documents',
      upload: 'Upload Report',
      download: 'Download Report',
      downloadSuccess: 'Report downloaded successfully',
      tableColumns: {
        name: 'Name',
        actions: 'Actions',
      },
      reportUpload: {
        form: {
          title: 'Upload Report',
          documentName: 'Document Name',
          submit: 'Upload',
        },
        validations: {
          documentName: 'Please enter a document name',
          file: 'file is required',
          failedDownload:
            'Can not able to download. Something went wrong. Please try again later',
          success: 'Report uploaded successfully',
        },
      },
    },
  },
}
