import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styles from './index.module.css'
import LoadingWithLogo from '~/components/LoadingWithLogo'
import LoginButton from '~/components/LoginButton'
import { ReactComponent as Banner } from '~/images/banner.svg'
import { ReactComponent as IconGoogle } from '~/images/icon_google.svg'
import { ReactComponent as IconJumia } from '~/images/icon_jumia.svg'
import { ReactComponent as Logo } from '~/images/logo.svg'
import { State } from '~/store'
import { login } from '~/store/user/middlewares'
import { UserState } from '~/store/user/types'
import { i18n } from '~/translations/i18n'

const Auth: React.FunctionComponent = () => {
  const { isAuth, isFetching } = useSelector<State, UserState>(
    (state) => state.user
  )
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const responseGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      if (tokenResponse.code !== undefined) {
        dispatch(login(tokenResponse.code, false))
      }
    },
    flow: 'auth-code',
  })

  useEffect(() => {
    if (isAuth) {
      navigate('/home')
    }
  }, [isAuth, navigate])

  return (
    <>
      {isFetching ? (
        <LoadingWithLogo />
      ) : (
        <Box className={styles.container}>
          <Box className={styles.bannerContainer}>
            <Banner />
          </Box>
          <Box className={styles.formContainer}>
            <Box className={styles.logo}>
              <Logo />
            </Box>
            <Box className={styles.loginHint}>{i18n.t('auth.title')}</Box>

            <Box className={styles.loginOptions}>
              <LoginButton
                text={i18n.t('auth.signIn.google')}
                onClick={() => responseGoogle()}
                disabled={isFetching}
                icon={<IconGoogle />}
              />
              <Box />
              <LoginButton
                text={i18n.t('auth.signIn.email')}
                icon={<IconJumia />}
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  )
}

export default Auth
