import { PaginationTypes, TablePagination } from '~/store/app/types'

export const paginationKeys = {
  sponsoredProducts: 'sponsoredProducts',
  sponsoredBrands: 'sponsoredBrands',
  campaigns: 'campaigns',
  offlineAdvertising: 'offlineAdvertising',
  spCampaignAudits: 'spCampaignAudits',
  sbCampaignAudits: 'sbCampaignAudits',
  insights: 'insights',
  finance: 'finance',
  addProducts: 'addProducts',
  advertisers: 'advertisers',
  packages: 'packages',
  tier1Events: 'tier1Events',
  displayCampaigns: 'displayCampaigns',
}

export const defaultPagination: TablePagination = {
  campaigns: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.CAMPAIGNS,
  },
  sponsoredProducts: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    quickFilters: {
      status: undefined,
      target: undefined,
    },
    searchQuery: undefined,
    searchBy: undefined,
    startDate: undefined,
    endDate: undefined,
    filters: undefined,
    type: PaginationTypes.SPONSORED_PRODUCTS,
  },
  offlineAdvertising: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.OFFLINE_ADVERTISING,
  },
  products: {
    page: 1,
    perPage: 10,
    sortBy: 'title',
    orderBy: 'desc',
    type: PaginationTypes.PRODUCTS,
  },
  addProducts: {
    page: 1,
    perPage: 10,
    sortBy: 'title',
    orderBy: 'desc',
    type: PaginationTypes.ADD_PRODUCTS,
  },
  keywords: {
    page: 1,
    perPage: 10,
    sortBy: 'text',
    orderBy: 'asc',
    type: PaginationTypes.KEYWORDS,
  },
  negativeKeywords: {
    page: 1,
    perPage: 10,
    sortBy: 'text',
    orderBy: 'asc',
    type: PaginationTypes.NEGATIVE_KEYWORDS,
  },
  packages: {
    page: 1,
    perPage: 10,
    sortBy: 'updatedAt',
    orderBy: 'desc',
    type: PaginationTypes.PACKAGES,
  },
  insights: {
    page: 1,
    perPage: 10,
    sortBy: 'uploadDate',
    orderBy: 'desc',
    type: PaginationTypes.INSIGHTS,
  },
  finance: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.FINANCE,
  },

  tier1Events: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.TIER1_EVENTS,
  },
  sponsoredBrands: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.SPONSORED_BRANDS,
  },
  spCampaignAudits: {
    page: 1,
    perPage: 5,
    sortBy: 'updatedAt',
    orderBy: 'desc',
    type: PaginationTypes.SP_CAMPAIGN_AUDITS,
  },
  sbCampaignAudits: {
    page: 1,
    perPage: 5,
    sortBy: 'updatedAt',
    orderBy: 'desc',
    type: PaginationTypes.SB_CAMPAIGN_AUDITS,
  },
  advertisers: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.ADVERTISERS,
  },
  advertisersUserManagement: {
    page: 1,
    perPage: 10,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.ADVERTISERS_USER_MANAGEMENT,
  },
  displayCampaigns: {
    page: 1,
    perPage: 50,
    sortBy: 'createdAt',
    orderBy: 'desc',
    type: PaginationTypes.DISPLAY_CAMPAIGNS,
  },
  displayCampaignsReports: {
    page: 1,
    perPage: 10,
    sortBy: 'name',
    orderBy: 'desc',
    type: PaginationTypes.DISPLAY_CAMPAIGNS_REPORTS,
  },
  sponsoredBrandsReports: {
    page: 1,
    perPage: 10,
    sortBy: 'name',
    orderBy: 'desc',
    type: PaginationTypes.SPONSORED_BRANDS_REPORTS,
  },
}
