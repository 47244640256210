import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { State } from './types'
import idMaker from '~/utils/idMaker'

const INITIAL_STATE: State = {
  selectedPlacements: [] || undefined,
}

const slice = createSlice({
  name: 'displayCampaigns',
  initialState: INITIAL_STATE,
  reducers: {
    setSelectedPlacements(state: State, action: PayloadAction<any[]>) {
      state.selectedPlacements = action.payload.map((v) => ({
        fid: `${idMaker()}`,
        ...v,
      }))
    },
  },
})

export const { setSelectedPlacements } = slice.actions

export default slice.reducer
