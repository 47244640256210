import { ListAction, mapPagination } from '../types'
import {
  Package,
  PackageResponse,
  PackagesList,
  PackagesListRequestParams,
  PackagesListRequestParsedParams,
  PackagesListResponse,
} from './types'
import { PERMISSIONS } from '~/constants/permissions'
import { i18n } from '~/translations/i18n'
import { camelToSnakeCase } from '~/utils/camelToSnakeCase'
import { formatDate } from '~/utils/formatDate'
import formatPrice from '~/utils/formatPrice'
import { getTargetName } from '~/utils/getTargetName'
import hasPermissions from '~/utils/hasPermission'
import { removeUndefinedPropertiesFromObject } from '~/utils/objectManagement'

const CAN_ACCESS_EDIT = hasPermissions([
  PERMISSIONS.BO_PACKAGES_EDIT,
  PERMISSIONS.BO_PACKAGES_ACCESS, // TODO: remove this permission after validating that it is not used
])

export const mapPackagesListRequestParams = ({
  page,
  perPage,
  filters,
  orderBy,
  sortBy,
  query,
  isDefault,
  feature,
}: PackagesListRequestParams): PackagesListRequestParsedParams => {
  const parsedParams = {
    page,
    per_page: perPage,
    default: isDefault,
    sort_by: camelToSnakeCase(sortBy),
    order_by: orderBy,
    name: query || undefined,
    target_code: filters?.targetCode,
    updated_at: filters?.updatedAt,
    updated_by: filters?.updatedBy,
    active: filters?.status && filters.status === 'active',
    feature,
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return removeUndefinedPropertiesFromObject(parsedParams)
}

const getPackageStatusAction = (status: boolean): ListAction => {
  const actionValue = status ? 'disable' : 'activate'

  return {
    buttonType: 'toggle',
    event: actionValue,
    label: i18n.t(`packages.list.actions.${actionValue}.tooltip`),
    confirmationRequired: true,
    confirmationDescription: i18n.t(
      `packages.list.actions.${actionValue}.confirmationDescription`
    ),
    confirmationTitle: i18n.t(
      `packages.list.actions.${actionValue}.confirmationTitle`
    ),
    disabled: !status,
    confirmationButtonLabel: i18n.t(`packages.list.actions.confirmationButton`),
    declineButtonLabel: i18n.t(`packages.list.actions.declineButton`),
  }
}

export const mapPackageResponse = (response: PackageResponse): Package => {
  const {
    id,
    name,
    display_name,
    description,
    updated_by,
    updated_at,
    target_code,
    created_at,
    created_by,
    active,
    is_default,
    package_type,
    features,
    price,
    min_budget,
    min_bid,
  } = response

  return {
    id,
    name,
    displayName: display_name === null ? '' : display_name,
    description,
    packageType: package_type.name,
    price,
    minBudget: min_budget,
    minBid: min_bid,
    createdAt: formatDate(created_at),
    createdBy: created_by,
    updatedAt: formatDate(updated_at),
    updatedBy: updated_by,
    target: target_code,
    features,
    status: getPackageStatusAction(active),
    isDefault: is_default,
    actions: [
      ...(CAN_ACCESS_EDIT
        ? [
            {
              icon: 'edit',
              event: 'edit',
              label: i18n.t('packages.list.actions.edit'),
            },
            {
              icon: 'file_copy',
              event: 'clone',
              label: i18n.t('packages.list.actions.clone'),
            },
          ]
        : []),
    ],
  }
}

export const mapPackageToList = (data: PackageResponse): Package => {
  const mappedResponse = mapPackageResponse(data)

  return {
    ...mappedResponse,
    price: formatPrice(mappedResponse.target, mappedResponse.price),
    target: getTargetName(mappedResponse.target),
  }
}

export const mapPackagesListResponse = (
  response: PackagesListResponse
): PackagesList => ({
  list: response.data.map((i) => mapPackageToList(i)),
  pagination: mapPagination(response.meta),
})
