import { SETTINGS_STATUS } from './constants'
import {
  SettingsListItemResponse,
  SettingsListItemResponseMaped,
  SettingsListResponse,
  SettingsListResponseMapped,
} from './types'
import { i18n } from '~/translations/i18n'

const getSettingsStatusAction = (status: boolean): any => {
  const actionValue =
    status !== SETTINGS_STATUS.ENABLED ? 'activate' : 'disable'
  return {
    // eslint-disable-next-line no-nested-ternary
    buttonType: status,
    event: actionValue,
    label: Object.values(SETTINGS_STATUS).includes(status)
      ? i18n.t(`settings.list.actions.${actionValue}.tooltip`)
      : '',
    confirmationRequired: true,
    confirmationDescription: i18n.t(
      `settings.list.actions.${actionValue}.confirmationDescription`
    ),
    confirmationTitle: i18n.t(
      `settings.list.actions.${actionValue}.confirmationTitle`
    ),
    disabled: status !== SETTINGS_STATUS.ENABLED,
    confirmationButtonLabel: i18n.t(`settings.list.actions.confirmationButton`),
    declineButtonLabel: i18n.t(`settings.list.actions.declineButton`),
  }
}

export const mapResponse = (
  response: SettingsListItemResponse
): SettingsListItemResponseMaped => {
  const { is_enabled, name } = response

  return {
    id: name,
    name,
    isEnabled: is_enabled,
    status: getSettingsStatusAction(is_enabled),
  }
}

export const mapedResponse = (
  response: SettingsListResponse
): SettingsListResponseMapped => ({
  data: response.data.map((p) => mapResponse(p)),
})
