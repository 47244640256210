import { Representation } from '~/components/UserSelection'
import { UserRepresentationsResponse } from '~/services/selfservice'

export const mapUserRepResToModel = (
  response: UserRepresentationsResponse
): Representation[] => {
  const res: Representation[] = []
  response.representations?.forEach((rep) => {
    const description = rep.entity?.name ? rep.entity.name : response.email
    const advertiserId = rep.entity !== null ? rep.entity.advertiser_id : null
    const targetCode = rep.entity?.target_code ? rep.entity.target_code : ''
    const brands = rep.entity?.brand_names ? rep.entity.brand_names : []
    const sellersIds = rep.entity?.seller_ids ? rep.entity.seller_ids : []
    const publicId = rep.entity?.public_id || ''

    res.push({
      id: rep.id,
      description,
      advertiserId,
      targetCode,
      brands,
      sellersIds,
      publicId,
    })
  })
  return res
}
