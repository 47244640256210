export const SETTINGS_STATUS = {
  ENABLED: true,
  PAUSED: false,
}

export const SETTINGS_LIST = {
  FINANCE: 'FINANCE',
  CAMPAIGN_REPORTS: 'CAMPAIGN_REPORTS',
  INSIGHTS: 'INSIGHTS',
  SPONSORED_BRANDS: 'SPONSORED_BRANDS',
  SPONSORED_PRODUCTS: 'SPONSOR_PRODUCTS',
  OFFLINE_ADVERTISING: 'OFFLINE_ADVERTISING',
  TIER_1: 'TIER_1_EVENTS',
  DISPLAY_CAMPAIGNS: 'DISPLAY_CAMPAIGNS',
  SPONSORED_PRODUCTS_REPORTS: 'SPONSORED_PRODUCTS_REPORTS',
}
