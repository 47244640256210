import { targets } from '~/constants/targets'

const formatPrice = (targetCode: string, price: number | string): string => {
  const currency = targets.find(
    (target) => target.code === targetCode
  )?.currency

  return `${currency} ${price}`
}

export default formatPrice
