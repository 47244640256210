import { mapUserRepResToModel } from '../mapper'
import { UserRepresentationsResponse } from '~/services/selfservice/users'
import { updateRepresentations, updateUserRepresentation } from '~/store/user/slice'
import { ls } from '~/utils/localStorage'

interface onSuccessType {
  data: UserRepresentationsResponse
  dispatch: (action: any) => void
  setUserRepresentations: (data: any) => void
  setRepresentations: (data: any) => void
}

export const onSuccess = ({
  data,
  setUserRepresentations,
  setRepresentations,
  dispatch,
}: onSuccessType) => {
  ls.set('userRepresentations', data.representations)
  setUserRepresentations(data.representations)

  if (data.representations.length === 1) {
    const userRepresentations = data.representations
    const rep = userRepresentations[0]
    const repId = rep.id
    const advertiserId = rep.entity?.advertiser_id ?? null
    const targetCode = rep.entity?.target_code ?? ''
    const brands = rep.entity?.brand_names ?? []
    const sellersIds = rep.entity?.seller_ids ?? []
    const publicId = rep.entity?.public_id ?? ''

    dispatch(
      updateUserRepresentation({
        repId,
        canChangeRep: false,
        advertiserId,
        targetCode,
        brands,
        sellersIds,
        publicId,
      })
    )

    dispatch(
      updateRepresentations({
        userRepresentations
      })
    )
  } else if (data.representations.length > 1) {
    setRepresentations(mapUserRepResToModel(data))
  } else {
    dispatch(
      updateUserRepresentation({
        repId: -1,
        canChangeRep: false,
        advertiserId: null,
        targetCode: '',
        brands: [],
        sellersIds: [],
        publicId: '',
      })
    )
  }
}
