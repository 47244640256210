import { PowerSettingsNew } from '@mui/icons-material'
import { Box } from '@mui/material'
import { useDispatch } from 'react-redux'
import * as S from './styles'
import { ROUTE_PATHS } from '~/constants/routes'
import { setDefaultRouteOnAccountChange } from '~/store/app/slice'
import { logout } from '~/store/user/middlewares'
import { i18n } from '~/translations/i18n'

interface LogoutProps {
  drawerOpen: boolean
}

export const Logout = ({ drawerOpen }: LogoutProps) => {
  const dispatch = useDispatch()

  const onSignOut = () => {
    dispatch(logout())
    dispatch(
      setDefaultRouteOnAccountChange(ROUTE_PATHS.SPONSORED_PRODUCTS_LIST)
    )
  }

  return (
    <Box mt="auto">
      <Box border="1px solid #EBEBEF" />
      <S.LogoutButton onClick={onSignOut}>
        <PowerSettingsNew />

        {drawerOpen && (
          <Box fontWeight="400" ml="11px">
            {i18n.t('navbar.logout')}
          </Box>
        )}
      </S.LogoutButton>
    </Box>
  )
}
