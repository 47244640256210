import useSession from './useSession'
import validateUserPermissions from '~/utils/validateUserPermissions'

type UsePermissionParams = {
  permissions?: string[]
}

const usePermission = ({ permissions = [] }: UsePermissionParams) => {
  const { isValidSession, userData } = useSession()

  if (!isValidSession) return false

  const userHasValidPermissions = validateUserPermissions({
    user: userData,
    permissions,
  })

  return userHasValidPermissions
}

export default usePermission
