import React, { useEffect, useState } from 'react'
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  responsiveFontSizes,
} from '@mui/material'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Provider, useDispatch } from 'react-redux'
import AuthConstants from '~/constants/auth'
import { envs } from '~/envs'
import AppRoutes from '~/routes'
import { store } from '~/store'
import { login } from '~/store/user/middlewares'
import { theme } from '~/theme'

const queryClient = new QueryClient()

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch()
  const params = new URLSearchParams(window.location.search)
  const [tokenFound, setTokenFound] = useState(
    !params.has(AuthConstants.SSO_TOKEN)
  )

  const handleParams = async () => {
    if (params.has(AuthConstants.SSO_TOKEN)) {
      // if a single sign-on token is available as param, try to silent login
      const ssoToken = params.get(AuthConstants.SSO_TOKEN) || ''
      dispatch(login(ssoToken, true))
      const url = window.location.href.split('?')
      window.history.replaceState(null, document.title, url[0])
      setTokenFound(true)
    }
  }

  useEffect(() => {
    handleParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={responsiveFontSizes(theme)}>
        <CssBaseline />
        {tokenFound && <AppRoutes />}
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

const clientId = String(envs.GOOGLE_CLIENT_ID)

const AppWrapper: React.FunctionComponent = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <GoogleOAuthProvider clientId={clientId}>
        <App />
      </GoogleOAuthProvider>
    </Provider>
    <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
  </QueryClientProvider>
)

export default AppWrapper
