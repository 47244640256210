import { ReactElement, ReactNode } from 'react'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box } from '@mui/material'
import { useMatch } from 'react-router-dom'
import * as S from './styles'
import Link from '~/components/Link'
import usePermission from '~/hooks/usePermission'

interface NavLinkProps {
  children: ReactNode
  href: string
  drawerOpen: boolean
  icon?: ReactElement
  permissions: string[]
  enabled?: boolean
  onClick?: (link: string) => void
  handleClick?: any
  openSubMenu?: boolean
  hasSubMenu?: boolean
  subMenuKey?: number
}

export const NavLink = ({
  children,
  href,
  drawerOpen,
  icon,
  permissions,
  enabled,
  onClick,
  handleClick,
  openSubMenu,
  hasSubMenu,
  subMenuKey,
}: NavLinkProps) => {
  const hasPermission = usePermission({ permissions })
  const isEnabled = enabled
  const match = useMatch(`${href}/*`)
  const isActive = match?.pathnameBase === href

  return hasPermission && isEnabled ? (
    <>
      {hasSubMenu ? (
        <S.ListItemButton onClick={handleClick}>
          <S.Container
            isActive={!!isActive}
            drawerOpen={drawerOpen}
            onClick={() => {
              if (onClick) onClick(href)
            }}
          >
            <S.IconContainer>{icon}</S.IconContainer>

            {drawerOpen && <S.Link isActive={!!isActive}>{children}</S.Link>}
          </S.Container>
          {drawerOpen &&
            (openSubMenu === subMenuKey ? (
              <Box mt={2}>
                <ExpandLess />
              </Box>
            ) : (
              <Box mt={2}>
                <ExpandMore />
              </Box>
            ))}
        </S.ListItemButton>
      ) : (
        <Link
          to={href}
          className={(data) => (data.isActive ? 'active' : '')}
          end
        >
          <S.ListItemButton
            sx={{
              padding: '0px',
              ':hover': {
                backgroundColor: '#FFF4E9',
              },
            }}
          >
            <S.Container
              isActive={!!isActive}
              drawerOpen={drawerOpen}
              onClick={() => {
                if (onClick) onClick(href)
              }}
            >
              <S.IconContainer>{icon}</S.IconContainer>

              {drawerOpen && <S.Link isActive={!!isActive}>{children}</S.Link>}
            </S.Container>
          </S.ListItemButton>
        </Link>
      )}
    </>
  ) : null
}
