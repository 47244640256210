import { Fragment, MouseEvent, ReactNode } from 'react'
import { Menu, Close } from '@mui/icons-material'
import { Box, IconButton, Collapse, List } from '@mui/material'
import { Logo } from '../Logo'
import { Logout } from '../Logout'
import { NavLink } from '../NavLink'
import { SectionType } from '../types'
import { UserInfo } from '../UserInfo'
import * as S from './styles'
import { Representation } from '~/components/UserSelection'

interface MobileSideBarProps {
  sections: SectionType[]
  handleMenuOpen: (event: MouseEvent<HTMLElement>) => void
  title: ReactNode
  picture: string
  representation?: Representation
  openSubMenu: any
  handleClick: any
  resetState: (link: string) => void
  open: boolean
  toggleDrawer: () => void
}

export const MobileSideBar = ({
  sections,
  handleMenuOpen,
  title,
  picture,
  representation,
  openSubMenu,
  handleClick,
  resetState,
  open,
  toggleDrawer,
}: MobileSideBarProps) => (
  <S.Container>
    <Box display="flex" alignItems="center">
      <IconButton
        sx={{
          padding: '5px',
        }}
        onClick={toggleDrawer}
      >
        <Menu />
      </IconButton>
      <S.LogoContainer>
        <Logo />
      </S.LogoContainer>
    </Box>
    <UserInfo
      drawerOpen
      handleMenuOpen={handleMenuOpen}
      isMobile
      isFO={representation !== undefined}
      title={title}
      picture={picture}
    />

    <S.Drawer anchor="left" open={open} onClose={toggleDrawer}>
      <Box flexDirection="column" display="flex">
        <Box>
          <Box display="flex" p="5px 16px" alignItems="center">
            <S.LogoContainer>
              <Logo />
            </S.LogoContainer>
            <IconButton
              sx={{
                color: '#333',
                padding: '5px',
              }}
              onClick={toggleDrawer}
            >
              <Close />
            </IconButton>
          </Box>
          <List
            component="nav"
            sx={{ bgcolor: 'background.paper' }}
            aria-labelledby="nested-list-subheader"
          >
            {sections.map((section: any, index: number) => (
              <Fragment key={index}>
                {section.links.map((link: any, key: number) => (
                  <Fragment key={`${key}-link`}>
                    {!link.subLinks ? (
                      <NavLink
                        drawerOpen={open}
                        icon={link.icon}
                        key={link?.href}
                        href={link?.href || '#'}
                        enabled={link.enabled}
                        permissions={link?.permissions || []}
                        onClick={() => resetState(link.href || '#')}
                        hasSubMenu={false}
                      >
                        {link.label}
                      </NavLink>
                    ) : (
                      <>
                        <NavLink
                          key={`${key}-subLink`}
                          hasSubMenu
                          drawerOpen={open}
                          icon={link.icon}
                          enabled={link.enabled}
                          permissions={link?.permissions || []}
                          href="#"
                          openSubMenu={openSubMenu}
                          handleClick={() => handleClick(key)}
                          subMenuKey={key}
                        >
                          {link.label}
                        </NavLink>
                        <Collapse
                          in={openSubMenu === key}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {link.subLinks?.map(
                              (subLink: any, keyIndex: number) => (
                                <Box
                                  sx={{ marginLeft: '1.5rem' }}
                                  key={`${keyIndex}-subLinkNav`}
                                >
                                  <NavLink
                                    drawerOpen={open}
                                    icon={subLink.icon}
                                    key={subLink.href}
                                    href={subLink.href}
                                    enabled={subLink.enabled}
                                    permissions={subLink.permissions}
                                    onClick={() => resetState(subLink.href)}
                                    hasSubMenu={false}
                                  >
                                    {subLink.label}
                                  </NavLink>
                                </Box>
                              )
                            )}
                          </List>
                        </Collapse>
                      </>
                    )}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </List>
        </Box>
      </Box>
      <Logout drawerOpen />
    </S.Drawer>
  </S.Container>
)
