// this file is to be used by 'react-query' to hold the query key
// Path: src/constants/queryKeys/index.ts

type QueryKeys = {
  [key: string]: string
}

export const SPONSORED_BRANDS_KEYS: QueryKeys = Object.freeze({
  GET_STATUS_LIST: 'sponsored-brands-status-list',
  GET_CAMPAIGN_DETAILS: 'get-campaign_details',
  GET_AVAILABLE_STATUS_LIST: 'get-available-status-list',
  GET_CAMPAIGN_AUDIT_LIST: 'sponsored-brands-campaign-audit-list',
  GET_CAMPAIGN_LIST: 'sponsored-brand-list',
  DOWNLOAD_REPORTS_BY_CAMPAIGNS: 'download-reports-by-campaigns',
  DOWNLOAD_REPORTS_BY_CATEGORIES: 'download-reports-by-categories',
  DOWNLOAD_REPORTS_BY_BRANDS: 'download-reports-by-brands',
})

export const SPONSORED_PRODUCTS_KEYS: QueryKeys = Object.freeze({
  GET_PERFORMANCE_REPORTS_LIST: 'get-performance-reports-list',
})

export const USER_REPRESENTATIONS: QueryKeys = Object.freeze({
  GET_USER_REPRESENTATIONS: 'user-representations',
})
