import { api } from '~/services/api'

export interface Entity {
  id: number
  public_id: string
  name: string
  advertiser_id: number | null
  target_code: string
  brand_names: string[]
  seller_ids: string[]
}

export interface UserRepresentation {
  id: number
  userId: number
  entity: Entity | null
}

export interface UserRepresentationsResponse {
  userId: number
  aclId: number
  email: string
  representations: UserRepresentation[]
}

const endpoints = {
  getUserRepresentations: () => `users/profile`,
}

const fetchUserRepresentations = async (
  isVenderCenter: boolean
): Promise<UserRepresentationsResponse> => {
  const response = await api.selfservice.get(
    endpoints.getUserRepresentations(),
    { params: { is_vendor_center: isVenderCenter } }
  )
  return response.data.data
}

export { fetchUserRepresentations }
