import React, { ReactComponentElement } from 'react'
import { ButtonProps as MaterialButtonProps } from '@mui/material'
import styles from './index.module.css'

interface ButtonProps extends MaterialButtonProps {
  text: string
  disabled?: boolean
  onClick?: () => void
  icon?: ReactComponentElement<any>
}

const LoginButton: React.FunctionComponent<ButtonProps> = ({
  text,
  onClick,
  disabled,
  icon,
}) => (
  <button
    className={styles.loginButton}
    onClick={onClick}
    disabled={disabled ?? true}
    type="button"
  >
    {icon ? <span className={styles.loginButton__icon}>{icon}</span> : null}
    {text}
  </button>
)

export default LoginButton
