import axios from 'axios'
import { api } from '~/services/api'
import {
  mapPackagesListRequestParams,
  mapPackagesListResponse,
  mapPackageResponse,
} from '~/services/selfservice/packages/mapper'
import {
  Package,
  Feature,
  PackageType,
  PackageUpdateRequestParams,
  PackageRequestParams,
  PackageUpdateStatusRequestParams,
  PackageList,
  PackagesListRequestParams,
} from '~/services/selfservice/packages/types'

export const endpoints = {
  getPackages: (target: string) => `packages?target_code=${target}&active=true`,

  getList: 'packages',
  getById: (id: number) => `/packages/${id}`,
  getFeatures: '/features',
  getTypes: '/packages/types',
  create: '/packages',
  update: (id: number) => `/packages/${id}`,
  updateStatus: (id: number) => `/packages/${id}/status`,
  delete: (id: number) => `/packages/${id}`,
}

const fetchPackageList = async (email: string): Promise<PackageList> => {
  const response = await api.selfservice.get(endpoints.getPackages(email))
  return mapPackagesListResponse(response.data)
}
export const fetchPackages = async (
  params: PackagesListRequestParams
): Promise<PackageList> => {
  const parsedParams = mapPackagesListRequestParams(params)

  const response = await api.selfservice.get(endpoints.getList, {
    params: parsedParams,
  })

  return mapPackagesListResponse(response.data)
}

export const fetchPackage = async (id: number): Promise<Package> => {
  const response = await api.selfservice.get(endpoints.getById(id))

  return mapPackageResponse(response.data.data)
}

export const fetchFeatures = async (): Promise<Feature[]> => {
  const response = await api.selfservice.get(endpoints.getFeatures)

  return response.data.data
}

export const fetchPackageTypes = async (): Promise<PackageType[]> => {
  const response = await api.selfservice.get(endpoints.getTypes)

  return response.data.data
}

// TODO: Delete feature will be temporarerly removed. New development will be done for this feature.
// Do not delete this code or any other related
export const deletePackage = async (id: number): Promise<void> => {
  await api.selfservice.delete(endpoints.delete(id))
}

export const updatePackage = async ({
  id,
  displayName,
  description,
  packageTypeId,
  price,
  minBudget,
  minBid,
  target,
  features,
  status,
  isDefault,
}: PackageUpdateRequestParams): Promise<void> => {
  const payload = {
    id,
    display_name: displayName.trim() === '' ? null : displayName.trim(),
    description,
    price,
    min_budget: minBudget || 0,
    min_bid: minBid || 0,
    features,
    package_type_id: packageTypeId,
    target_code: target,
    active: status,
    is_default: isDefault,
  }

  try {
    await api.selfservice.put(endpoints.update(id), payload)
    console.log('yest')
  } catch (err) {
    console.log(err)
    if (axios.isAxiosError(err)) {
      throw err.response?.data?.errors?.[0]
    } else {
      throw err
    }
  }
}

export const createPackage = async ({
  displayName,
  description,
  packageTypeId,
  price,
  minBudget,
  minBid,
  target,
  features,
  status,
  isDefault,
}: PackageRequestParams): Promise<void> => {
  const display = displayName === undefined ? null : displayName.trim()

  const payload = {
    display_name: display === '' ? null : display,
    description,
    price,
    min_budget: minBudget || 0,
    min_bid: minBid || 0,
    features,
    package_type_id: packageTypeId,
    target_code: target,
    active: status,
    is_default: isDefault,
  }

  try {
    await api.selfservice.post(endpoints.create, payload)
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response?.data?.errors?.[0]
    } else {
      throw err
    }
  }
}

export const updatePackageStatus = async ({
  id,
  status,
}: PackageUpdateStatusRequestParams) => {
  const payload = {
    package_id: id,
    active: status,
  }

  try {
    await api.selfservice.post(endpoints.updateStatus(id), payload)
  } catch (err) {
    if (axios.isAxiosError(err)) {
      throw err.response?.data?.errors?.[0]
    } else {
      throw err
    }
  }
}

export { fetchPackageList }
