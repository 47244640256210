import { Box, styled } from '@mui/material'

interface ContainerProps {
  isMobile: boolean
  isFO: boolean
}

export const Container = styled(Box)<ContainerProps>(
  ({ theme, isMobile, isFO }) => ({
    display: 'flex',
    alignItems: 'center',
    width: isMobile ? 'auto' : '100%',
    flexDirection: isMobile ? 'row-reverse' : 'row',
    borderRadius: '0',
    backgroundColor: isMobile ? 'white' : theme.palette.primary.main,
    padding: theme.spacing(1, 2),
    cursor: isFO ? 'pointer' : 'default',
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
    ':hover': isFO
      ? {
          boxShadow: 'none',
        }
      : 'none',
  })
)

export const Name = styled(Box)<ContainerProps>(
  ({ theme, isMobile, isFO }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    color: isMobile ? theme.palette.primary.dark : 'white',
    fontWeight: 'bold',
    marginLeft: isMobile ? '0' : '8px',
    marginRight: isMobile ? '8px' : '0',
    fontSize: '12px',
    ':hover': isFO
      ? {
          opacity: '0.9',
          backgroundColor: 'primary.main',
          boxShadow: 'none',
        }
      : 'none',
  })
)
