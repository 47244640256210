import { ls } from '~/utils/localStorage'

export default function checkPermissions(
  currentPermissions: string[],
  checkAll = false
): boolean {
  const { permissions } = ls.get('user')

  const requiredPermissions = permissions || []

  /** Check every or some, dead simple. */
  if (checkAll) {
    return currentPermissions.every((p: string) =>
      requiredPermissions.includes(p)
    )
  }
  return currentPermissions.some((p: string) => requiredPermissions.includes(p))
}
